const setMobilePlatform = (isMobile) => ({
  type: 'SET_PLATFORM',
  isMobile,
});

const changeHelpVisibility = (isVisible) => ({
  type: 'SET_HELP_VISIBILITY',
  helpTextVisible: isVisible,
});

const updateVersionCheckTime = {
  type: 'UPDATE_VERSION_CHECK_TIME',
};

const updateRecentSearch = (newSearch) => ({
  type: 'UPDATE_RECENT_SEARCH',
  newSearch,
});

export { setMobilePlatform, changeHelpVisibility, updateVersionCheckTime, updateRecentSearch };
