import PropTypes from 'prop-types';

const arrayOfStringAndNumber = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
);

const path = PropTypes.oneOfType([PropTypes.string, PropTypes.number, arrayOfStringAndNumber]);

const rules = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func]));

const options = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.arrayOf(PropTypes.number),
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ),
]);

const stringOrElement = PropTypes.oneOfType([PropTypes.string, PropTypes.element]);

const tooltip = stringOrElement;

const help = stringOrElement;

const showSelectAll = PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element]);

const filtersMap = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      defaultVal: PropTypes.any,
      options,
      extra: PropTypes.any,
      apiParams: PropTypes.any,
      apiDynamicData: PropTypes.any,
    }),
  ]),
);

export { path, rules, options, tooltip, help, showSelectAll, filtersMap };

export default {
  arrayOfStringAndNumber,
  path,
  rules,
  options,
  stringOrElement,
  tooltip,
  help,
  showSelectAll,
  filtersMap,
};
