import React from 'react';
import GitInfo from 'react-git-info/macro';
import { connect } from 'react-redux';
import { notification, Typography } from 'antd';
import { logger } from 'utils';
import { updateVersionCheckTime } from 'store/actions/globalActions';
import { version as currentVersion } from '../../../package.json';

const gitinfo = GitInfo();
const env = process.env.REACT_APP_ENV;
const CHECK_DELAY = (env === 'staging' ? 5 : 20) * 60 * 1000;

function versionLessThan(ver1, ver2) {
  ver1 = ver1.split(/(\.|-)/);
  ver2 = ver2.split(/(\.|-)/);

  while (ver1.length || ver2.length) {
    if (Number(ver1.shift()) < Number(ver2.shift())) return true;
  }
  return false;
}

function CacheBuster(props) {
  // Don't fetch before 2 minutes
  if (env === 'development' || props.versionCheckLastTime + CHECK_DELAY > Date.now()) return null;

  fetch('/meta.json')
    .then((response) => response.json())
    .then((meta) => {
      props.updateVersionCheckLastTime();
      const latestVersion = meta.version;
      if (
        (env === 'production' && versionLessThan(currentVersion, latestVersion)) ||
        (env === 'staging' && meta.lastCommitHash !== gitinfo.commit.hash)
      ) {
        logger.warn('***** Newer version of Sapphyre available *****');
        notification.warning({
          message: 'New Update Available',
          duration: null,
          description: (
            <>
              Newer version of Sapphyre is available.
              <br />
              <Typography.Link onClick={() => window.location.reload()}>
                Reload this page
              </Typography.Link>{' '}
              to use the latest features.
            </>
          ),
        });
      }
    })
    .catch((err) => {
      logger.error({ err }, 'Error in fetching meta.json');
    });

  return null;
}

const mapStateToProps = (state) => ({
  versionCheckLastTime: state.globals.versionCheckLastTime,
});

const mapDispatchToProps = (dispatch) => ({
  updateVersionCheckLastTime: () => dispatch(updateVersionCheckTime),
});

export default connect(mapStateToProps, mapDispatchToProps)(CacheBuster);
