import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import subscribe from './subscribers';
import rootReducer from './reducers/rootReducer';

const store = createStore(rootReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
subscribe(store);
export default { store, persistor };

export { store, persistor };
