import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown, Menu, Switch, Badge } from 'antd';
import {
  faUser,
  faPowerOff,
  faChevronDown,
  faBackward,
  faFireAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Links } from 'links';
import { virtualLogout, logout } from 'store/actions/authActions';
import { changeHelpVisibility } from 'store/actions/globalActions';
import Button from './Button';
import FaIcon from './FaIcon';
import Notification from './Notification';
import GlobalSearch from './GlobalSearch';

const HEADWAYAPP_PLATFORM_ID = process.env.REACT_APP_HEADWAYAPP_ID || '7wBdZx';
const HEADWAYAPP_PARTNER_ID = process.env.REACT_APP_PARTNER_HEADWAYAPP_ID || 'xWDXA7';
const HEADWAYAPP_ELEMENT_ID = 'headway-badge';

/**
 * @typedef {{userName: string, partnerId?: number, advertiserId?: string}} Props
 * @typedef {import('react-router-dom').RouteComponentProps &
 *  ReturnType<typeof mapStateToProps> &
 *  ReturnType<typeof mapDispatchToProps>
 * } ExtraProps
 *
 * @extends {PureComponent<Props & ExtraProps>}
 */
class Navbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      unseenChangesCount: 0,
    };
  }

  componentDidMount() {
    this.loadHeadwayScript();
  }

  get userMenu() {
    const { partnerId, advertiserId, helpTextVisible, userName, isMobile } = this.props;
    return (
      <Menu>
        {isMobile ? (
          <Menu.Item disabled style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
            <i>{userName}</i>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <Link
            style={{ width: '100%' }}
            to={() => {
              if (partnerId) return Links.Affiliate.profile({ partnerId });
              if (advertiserId) return Links.Programmatic.profile({ advertiserId });
              return Links.User.profile();
            }}
          >
            <FaIcon icon={faUser} size="lg" />
            Profile
          </Link>
        </Menu.Item>
        <Menu.Item onClick={this.props.logout}>
          <FaIcon icon={faPowerOff} size="lg" />
          <span>Logout</span>
        </Menu.Item>
        <Menu.Item onClick={this.showChangelogs}>
          <FaIcon icon={faFireAlt} size="lg" />
          <span>What&apos;s New</span>
          <Badge className="unread-count" count={this.state.unseenChangesCount} />
        </Menu.Item>
        <Menu.Item>
          <FaIcon icon={faQuestionCircle} size="lg" />
          <span>
            {helpTextVisible ? 'Hide' : 'Show'}
            &nbsp;Help Text
          </span>
          <Switch
            size="small"
            defaultChecked={helpTextVisible}
            onChange={this.props.changeHelpVisibility}
            style={{ position: 'absolute', right: 12 }}
          />
        </Menu.Item>
      </Menu>
    );
  }

  /**
   * @see https://docs.headwayapp.co/widget#initializing-widget-programmatically-spa
   * @see https://medium.com/@willybbb/adding-headway-change-log-widget-to-react-app-e70e47977baf
   */
  loadHeadwayScript = () => {
    if (this.props.partnerId && !HEADWAYAPP_PLATFORM_ID) return;
    if (!HEADWAYAPP_PLATFORM_ID) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);

    const config = {
      selector: `#${HEADWAYAPP_ELEMENT_ID}`,
      account: this.props.partnerId ? HEADWAYAPP_PARTNER_ID : HEADWAYAPP_PLATFORM_ID,
      callbacks: {
        onWidgetReady: (widget) => {
          const unseenCount = widget.getUnseenCount();
          if (unseenCount) this.setState({ unseenChangesCount: unseenCount });
        },
      },
    };
    script.onload = () => {
      // @ts-ignore
      window.Headway?.init(config);
    };
  };

  /**
   * @type {import('antd/lib/menu').MenuItemGroupProps['onClick']}
   */
  showChangelogs = () => {
    // @ts-ignore
    if (window.Headway?.widgetIsReady) {
      // Settimeout delays show, without it widget is shown and hidden back immediately
      setTimeout(() => {
        // @ts-ignore
        window.Headway.show();
        this.setState({ unseenChangesCount: 0 });
      }, 1);
    }
  };

  handleVirtualLogout = () => {
    this.props.virtualLogout().then(() => {
      this.props.history.push(Links.dashboard());
    });
  };

  render() {
    const { userName, isVirtualLogin, partnerId, advertiserId, isMobile } = this.props;

    return (
      <>
        {!partnerId && !advertiserId ? <GlobalSearch /> : null}
        {isVirtualLogin && (
          <Button onClick={this.handleVirtualLogout} icon={<FaIcon icon={faBackward} />}>
            Back to Main
          </Button>
        )}
        {!partnerId && !advertiserId ? <Notification /> : null}
        <Dropdown
          overlay={this.userMenu}
          placement="bottomRight"
          overlayClassName="navbar-overlay"
          trigger={['click']}
          getPopupContainer={() => document.querySelector('.navbar')}
        >
          <Badge count={this.state.unseenChangesCount} dot offset={[-7, 10]}>
            <FaIcon icon={faUserCircle} size="lg" />
            {!isMobile ? <span>{userName}</span> : null}
            <FaIcon icon={faChevronDown} />
            <span
              id={HEADWAYAPP_ELEMENT_ID}
              style={{ width: 1, height: 1, overflow: 'hidden', display: 'inline-block' }}
            />
          </Badge>
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userName: state.auth.userName,
  partnerId: state.auth.pid,
  advertiserId: state.auth.aid,
  isVirtualLogin: state.auth.virtualLogin,
  helpTextVisible: state.globals.helpTextVisible,
  isMobile: state.globals.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout),
  virtualLogout: () => dispatch(virtualLogout),
  changeHelpVisibility: (isVisible) => dispatch(changeHelpVisibility(isVisible)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Navbar);
