import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Card, Popconfirm, Empty } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import FormMultiCheckbox from 'components/FormMultiCheckbox';
import './styles.scoped.less';

/**
 * @typedef {import('prop-types').InferProps<typeof CheckboxPopover.propTypes>} Props
 *
 * @param {Props} props
 */
function CheckboxPopover({ items, selectedItems, onChange }) {
  const [form] = Form.useForm();
  const notEmpty = Boolean(items.length);

  const handleConfirm = useCallback(() => {
    onChange(form.getFieldValue('selected'));
  }, [form, onChange]);

  const handleOpen = useCallback(
    (isVisible) => {
      if (isVisible) form.resetFields();
    },
    [form],
  );

  return (
    <Popconfirm
      placement="leftTop"
      icon={null}
      getPopupContainer={(node) => node.closest('.ant-card')}
      overlayStyle={{ minWidth: 300, maxWidth: 600 }}
      onConfirm={handleConfirm}
      okButtonProps={{ disabled: !notEmpty }}
      onVisibleChange={notEmpty ? handleOpen : undefined}
      title={
        <Card title="Select Columns" size="small" bordered={false}>
          {notEmpty ? (
            <Form form={form} initialValues={{ selected: selectedItems }}>
              <FormMultiCheckbox
                name="selected"
                options={items}
                initialValues={selectedItems}
                colSpan={8}
                showSelectAll
              />
            </Form>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Submit To Get Columns" />
          )}
        </Card>
      }
    >
      <Button shape="circle" icon={<SettingOutlined />} />
    </Popconfirm>
  );
}

CheckboxPopover.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
};

export default CheckboxPopover;
