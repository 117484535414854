import React, { useState } from 'react';
import { Resizable } from 'react-resizable';

/**
 * @param {{
 *  onResize: import('react-resizable').ResizableProps['onResize'],
 *  width: import('react-resizable').ResizableProps['width'],
 * } & React.ThHTMLAttributes} props
 */
function ResizeableTitle(props) {
  const { onResize, width, onClick, ...restProps } = props;

  /**
   * Sorting with resize
   * @see https://github.com/ant-design/ant-design/issues/14647#issuecomment-467865540
   */
  const [resizing, setResizing] = useState(false);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => setTimeout(() => setResizing(false))}
      draggableOpts={{ enableUserSelectHack: false }}
      handle={
        // eslint-disable-next-line
        <span
          className="column-resize-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
    >
      <th {...restProps} onClick={(e) => !resizing && onClick?.(e)} />
    </Resizable>
  );
}

export default ResizeableTitle;
