import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { PropTypePresets } from 'utils';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';

/**
 * @typedef {import('prop-types').InferProps<typeof FormCheckbox.propTypes>} Props
 *
 * @param {Props} props
 */
function FormCheckbox(props) {
  const {
    name,
    label,
    disabled,
    checkboxProps,
    formItemProps,
    onCheckChange,
    tooltip,
    help,
    initialChecked,
    rules,
  } = props;

  const handleChange = useCallback((e) => onCheckChange(e.target.checked), [onCheckChange]);

  return (
    <Form.Item
      name={name}
      rules={rules}
      extra={<Help text={help} />}
      valuePropName="checked"
      initialValue={initialChecked}
      validateFirst
      {...formItemProps}
    >
      <Checkbox disabled={disabled} onChange={handleChange} {...checkboxProps}>
        <InfoTooltip.Label {...{ label, tooltip }} />
      </Checkbox>
    </Form.Item>
  );
}

FormCheckbox.defaultProps = {
  disabled: false,
  rules: [],
  checkboxProps: {},
  formItemProps: {},
  onCheckChange: () => {},
};

FormCheckbox.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string.isRequired,
  help: PropTypePresets.help,
  tooltip: PropTypePresets.tooltip,
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  checkboxProps: PropTypes.object,
  formItemProps: PropTypes.object,
  onCheckChange: PropTypes.func,
  initialChecked: PropTypes.bool,
};

export default FormCheckbox;
