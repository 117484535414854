import React from 'react';
import DataTableOrig, { queryParamsToProps, DataTableProps } from './DataTable';

// Type hack
// eslint-disable-next-line react/prefer-stateless-function
class Tmp extends React.Component<DataTableProps> {}

// @ts-ignore
const DataTable: typeof Tmp = DataTableOrig;

export default DataTable;
export { queryParamsToProps };
