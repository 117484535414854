/* eslint-disable no-console,  no-underscore-dangle */
import GitInfo from 'react-git-info/macro';
import pino from 'pino';
import Analytics from './analytics';

const gitInfo = GitInfo();
const version = process.env.REACT_APP_VERSION;
const isEnvDevelopment = process.env.NODE_ENV === 'development';

// @ts-ignore
window._APP_VERSION = version;
// @ts-ignore
window._APP_SHA = gitInfo.commit.shortHash;

const base = isEnvDevelopment
  ? {}
  : {
    version,
    environment: process.env.NODE_ENV,
    hostname: window.location.hostname,
  };

const levelMap = {
  10: console.log,
  20: console.debug,
  30: console.info,
  40: console.warn,
  50: console.error,
};

const logger = pino({
  level: 'trace',
  browser: {
    asObject: true,
    write: (obj) => {
      const { err, level, time, label = 'default', msg = '', ...rest } = obj;
      const log = levelMap[level] || console.error;
      const msgStr = `[${label}] ${msg}`;

      if (level >= 50) {
        Analytics.exception({
          description: msgStr,
          fatal: level > 50,
        });
      }

      const messageArr = [`${new Date(time).toLocaleString()} ${msgStr}`];
      const doesRestHaveFields = Object.keys(rest).length;
      if (err || doesRestHaveFields) {
        messageArr.push(':\n');

        if (err) messageArr.push(err);
        if (doesRestHaveFields) messageArr.push(rest);
      }

      log(...messageArr);
    },
  },
  base,
});

if (isEnvDevelopment) {
  window.onunhandledrejection = (err) => {
    logger.error({ err, label: 'Unhandled' }, 'UnhandledRejection');
  };

  window.onerror = (err) => {
    logger.error({ err, label: 'Unhandled' }, 'UnhandledError');
  };
}

logger.info(`Version: ${version}_${gitInfo.commit.shortHash} (${gitInfo.commit.date})`);

export default logger;
