import React from 'react';
import CopyItem from './CopyItem';

/**
 *
 * @param {{
 *  title: any,
 *  id?: string | number,
 *  subTitle?: any,
 *  brackets?: boolean,
 * }} param0
 */
function Cell({ title = null, id, subTitle = id, brackets = true }) {
  if (!subTitle) return title;

  subTitle = (
    <>
      {brackets ? '(' : null}
      {subTitle}
      {brackets ? ')' : null}
    </>
  );

  return (
    <>
      <span className="row-title">{title}</span>
      <br />
      {id ? (
        <CopyItem text={id} className="row-sub-title">
          {subTitle}
        </CopyItem>
      ) : (
        <div className="row-sub-title">{subTitle}</div>
      )}
    </>
  );
}

export default Cell;
