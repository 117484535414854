import { isPlainObject, castArray, escapeRegExp } from 'lodash';
import compareVersions from 'compare-versions';
import { isIP, isIPRange, isMongoId, isEmail, isAlphanumeric } from 'validator';
import dayjs from 'dayjs';

// NOTE: `validator` functions are async because it expects a Promise return value

/**
 * Add extra data to rules
 * @param {(object | function)[]} rules
 * @param {object} extra
 */
function transformRules(rules, extra) {
  return rules.map((rule) => {
    if (!isPlainObject(rule)) return rule;
    return { ...extra, ...rule };
  });
}

/**
 * NOTE: It is important to have a default value of empty array if using an array rule
 * @param {any} Rule
 * @see https://www.npmjs.com/package/async-validator#defaultfield
 */
function arrayOfRule(Rule) {
  return {
    type: 'array',
    options: { first: true },
    defaultField: Rule,
  };
}

const alphaNumeric = {
  validator: async (rule, val) => {
    if (isAlphanumeric(val, 'en-US')) return true;
    throw new Error('Special characters and Spaces are not allowed');
  },
};

const email = { type: 'email' };

const equal = (field) => ({ getFieldValue }) => ({
  validator: async (rule, val) => {
    if (getFieldValue(field) === val) {
      return true;
    }
    throw new Error(`${rule.field} must be equal to ${field}`);
  },
});

const ip = {
  validator: async (rule, val) => {
    if (isIP(val)) return true;
    throw new Error(`Invalid IP: "${val}"`);
  },
};

const ipOrIpRange = {
  validator: async (rule, val) => {
    if (isIP(val) || isIPRange(val)) return true;
    // IPv6 CIDR
    const ipSplit = val.split('/');
    if (ipSplit.length === 2) {
      // eslint-disable-next-line prefer-const
      let [ipVal, length] = ipSplit;
      length = Number(length);
      if (isIP(ipVal, 6) && Number.isInteger(length) && length >= 0 && length <= 128) return true;
    }
    throw new Error(`Invalid IP or IP Range: "${val}"`);
  },
};

const dateRange = (allowedRange) => ({
  validator: async (rule, val) => {
    if (val && val.length === 2) {
      if (val[0] && val[1]) {
        if (dayjs(val[1]).diff(dayjs(val[0]), 'day') > allowedRange)
          throw new Error(`Allowed date range is ${allowedRange} days`);
      }
    }
    return true;
  },
});

const ips = arrayOfRule(ip);

const ipOrIpRanges = arrayOfRule(ipOrIpRange);

/**
 * @param {number} val
 */
const max = (val) => ({ max: val });

/**
 * @param {number} val
 */
const min = (val) => ({ min: val });

/**
 * @param {import('dayjs').Dayjs} val1
 * @param {import('dayjs').Dayjs | 'now'} val2
 * @param {import('dayjs').OpUnitType} [unit]
 * @param {string} [format]
 */
const timeRange = (val1, val2, unit, format = 'HH:mm:ss') => ({
  validator: async (rule, [from, to]) => {
    if (!from.isBefore(to)) throw new Error('From time must be less than To time');
    if (from.isBefore(val1, unit)) throw new Error(`Min value can be ${val1.format(format)}`);
    const maxVal = val2 === 'now' ? dayjs() : val2;
    if (to.isAfter(maxVal, unit)) throw new Error(`Max value can be ${maxVal.format(format)}`);
    return true;
  },
});

const decimalLength = (allowedDecimalLength = 5) => ({
  validator: async (rule, val) => {
    if (!val) return;
    const valString = val.toString();
    const [, decimalPart] = valString.split('.');
    if (decimalPart && decimalPart.length > allowedDecimalLength) {
      throw new Error(`${allowedDecimalLength} decimal places are allowed at most.`);
    }
  },
});

/** Checks for only whitespace as value */
const noWhitespace = { whitespace: true };

const noWhitespaces = arrayOfRule(noWhitespace);

const password = {
  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/,
  message: 'Password must contain: numbers, uppercase and lowercase letters',
};

/**
 * @param {number} minimum
 * @param {number} maximum
 */
const range = (minimum, maximum) => ({ min: minimum, max: maximum });

const required = { required: true };

const requiredArray = { required: true, type: 'array', min: 1 };

/**
 * @param {() => any[] | Promise<any[]>} getOptions
 */
const getSearchRule = (getOptions) => () => ({
  validator: async (rule, value) => {
    if (value === undefined || (Array.isArray(value) && !value.length)) return true;

    const validOptions = (await getOptions()).map((o) => o.value);
    const currValues = castArray(value);

    const errors = currValues
      .map((val) => {
        if (validOptions.includes(val)) return false;
        return true;
      })
      .filter(Boolean);

    if (errors.length) throw new Error('Value not in options');
    return true;
  },
});

const clickParam = {
  pattern: new RegExp(`^[^${escapeRegExp(":/?#[]@{}!$&'()*+;= \n")}]+$`),
  message: 'Given value not allowed, check for symbols, spaces or newline',
};

const goalPattern = {
  validator: async (rule, value) => {
    if (!value.match(/^(?!\s).*?(?<!\s)$/))
      throw new Error('Goal should not contain leading or trailing space');
    if (!value.match(new RegExp(`^[\\w\\d\\s\\-'"${escapeRegExp('_@!:%/\\;`~><+*|[]{}()^')}]+$`)))
      throw new Error(
        'Goal should only contain Letters, Digits or following Special Characters <Space>_@!:%/\\;`"\'~><+*|[]{}()^',
      );
    return true;
  },
};

const url = {
  pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  message: 'Not a valid url',
};

const templatedUrl = {
  pattern: new RegExp(
    '^(?:(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost|{[\\d\\w]+})(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?)$|^(?:{[\\d\\w]+})$',
    'i',
  ),
  message: 'Not a valid url',
};

const domain = {
  pattern: /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63}$/,
  message: 'Given value is not a valid domain.',
};

const mongoId = {
  validator: async (rule, value) => {
    if (isMongoId(value)) return true;
    throw new Error('Invalid ID');
  },
};

const mongoIds = arrayOfRule(mongoId);

const mongoIdOrEmail = {
  validator: async (rule, value) => {
    if (isMongoId(value)) return true;
    if (isEmail(value)) return true;
    throw new Error('Invalid ID or Email');
  },
};

const version = {
  validator: async (rule, value) => {
    if (value === undefined || value === '') return true;
    if (compareVersions.validate(value)) return true;
    throw new Error('Invalid version');
  },
};

export {
  alphaNumeric,
  dateRange,
  email,
  equal,
  ip,
  ips,
  ipOrIpRange,
  ipOrIpRanges,
  max,
  min,
  timeRange,
  noWhitespace,
  noWhitespaces,
  password,
  range,
  required,
  requiredArray,
  getSearchRule,
  clickParam,
  goalPattern,
  url,
  templatedUrl,
  domain,
  mongoId,
  mongoIds,
  mongoIdOrEmail,
  arrayOfRule,
  transformRules,
  decimalLength,
  isMongoId,
  isEmail,
  version,
};
