import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

/**
 * @param {string | React.ReactElement} title
 * @returns {import('antd/lib/tooltip').TooltipProps & {icon: JSX.Element}}
 */
function getTooltipConfig(title) {
  if (!title) return null;
  return {
    icon: <InfoCircleOutlined className="info-tooltip-icon" style={{ marginLeft: 5 }} />,
    placement: 'right',
    title,
  };
}

/**
 * @param {string | React.ReactElement} tooltip
 */
function InfoTooltip(tooltip) {
  if (!tooltip) return null;

  const { icon, ...rest } = getTooltipConfig(tooltip);
  return <Tooltip {...rest}>{icon}</Tooltip>;
}

/**
 * @param {{
 *  label: string,
 *  tooltip?: string | React.ReactElement
 * }} props
 */
InfoTooltip.Label = ({ label, tooltip }) => {
  if (!tooltip) return label ? <>{label}</> : null;
  return (
    <>
      <span>{label}</span>
      {InfoTooltip(tooltip)}
    </>
  );
};

InfoTooltip.Config = getTooltipConfig;

export default InfoTooltip;
