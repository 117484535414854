import React from 'react';
import { castArray } from 'lodash';
import { Link, generatePath } from 'react-router-dom';
import { Menu } from 'antd';
import { IterateRoutes, MapRoutes } from 'routes/utils';

/**
 * @param {{
 *  routes: import('../routes').RouteItem[],
 *  partnerId?: number,
 *  advertiserId?: string,
 * }} props
 */
function Sidebar(props) {
  const { routes } = props;
  /** @type {(opts: {icon?: any, name: string}) => JSX.Element} */
  const getMenuLabel = ({ icon, name }) => (
    <>
      {icon ? <span className="menu-icon">{icon}</span> : null}
      <span>{name}</span>
    </>
  );

  const items = IterateRoutes({
    routes,
    renderParent: ({ route, key, children }) => {
      if (route.invisible) return null;
      const filtered = children.filter(Boolean);

      return (
        <Menu.SubMenu key={key} title={getMenuLabel(route)}>
          {filtered}
        </Menu.SubMenu>
      );
    },
    render: ({ route, key, parent }) => {
      if (route.invisible || parent?.invisible) return null;

      return (
        <Menu.Item key={key}>
          <Link
            to={generatePath(castArray(route.path)[0], {
              pid: props.partnerId || '',
              aid: props.advertiserId || '',
            })}
          >
            {getMenuLabel(route)}
          </Link>
        </Menu.Item>
      );
    },
  });

  return (
    <MapRoutes
      routes={routes}
      default={() => (
        <Menu mode="inline" theme="dark" style={{ paddingBottom: 70 }}>
          {items}
        </Menu>
      )}
      render={({ parent, key }) => (
        <Menu
          mode="inline"
          theme="dark"
          style={{ paddingBottom: 70 }}
          defaultOpenKeys={
            // All Sub-Paths till children
            // @ts-ignore
            parent?.key
              ?.split('.')
              ?.map((pathKey, index, fullPath) => fullPath.slice(0, index + 1).join('.'))
              .filter(Boolean)
          }
          defaultSelectedKeys={[key]}
        >
          {items}
        </Menu>
      )}
    />
  );
}

export default Sidebar;
