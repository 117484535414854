import { rawTimeZones } from '@vvo/tzdb';

const offsetRegex = new RegExp('[+-][0-1][0-9]:(00|30)');

// extracting offset value from timezone rawformat
function extractOffset(timezone) {
  return timezone.match(offsetRegex)?.[0];
}

// filtering out only *:30 and *:00 offset
const validTimeZones = rawTimeZones.filter((tz) => offsetRegex.test(extractOffset(tz.rawFormat)));

// expand grouped timezones
const expandedTimeZones = [];
for (let tz = 0; tz < validTimeZones.length; tz += 1) {
  for (let gname = 0; gname < validTimeZones[tz].group.length; gname += 1) {
    expandedTimeZones.push({ ...validTimeZones[tz], name: validTimeZones[tz].group[gname] });
  }
}

/** @type {Record<string, {label: string; utc: string}>} */
const timeZonesMap = expandedTimeZones.reduce(
  (obj, tz) => ({
    ...obj,
    [tz.name]: {
      label: `${tz.name} (${extractOffset(tz.rawFormat)})`,
      utc: extractOffset(tz.rawFormat),
    },
  }),
  {},
);
timeZonesMap.UTC = { label: 'UTC', utc: '+00:00' };

const timeZonesOptions = expandedTimeZones.map((tz) => ({
  label: `${tz.name} (${extractOffset(tz.rawFormat)})`,
  value: tz.name,
}));

const deprecatedTimezone = {
  'Asia/Calcutta': 'Asia/Kolkata',
};

function getLocalTimezone() {
  let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  localTimezone =
    localTimezone in deprecatedTimezone ? deprecatedTimezone[localTimezone] : localTimezone;
  return localTimezone;
}

export { timeZonesOptions, timeZonesMap, offsetRegex, getLocalTimezone };
