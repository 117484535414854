import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Spin, Space } from 'antd';
import { EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';

/**
 * @typedef {{
 *  buttonSize: import('antd/lib/button').ButtonSize
 * } & Omit<import('prop-types').InferProps<typeof FormActions.propTypes>, 'buttonSize'>} Props
 *
 * @extends {PureComponent<Props>}
 */
class FormActions extends PureComponent {
  handleReset = () => {
    const { onReset, stepForm, getFormRef } = this.props;
    if (stepForm) {
      const stepsRef = getFormRef().current;
      if (onReset) onReset();
      return stepsRef.stepFormRefs.map((ref) => ref.current?.resetFields());
    }
    if (onReset) return onReset();
    return getFormRef().current.resetFields();
  };

  handleStepReset = () => {
    const { getFormRef } = this.props;
    const stepsRef = getFormRef().current;
    const { currentStep } = stepsRef.state;
    const currentReset = stepsRef.props.steps[currentStep].reset;
    if (currentReset) currentReset();
    stepsRef.stepFormRefs[currentStep].current?.resetFields();
  };

  handleSubmit = async () => {
    const { onSubmit, stepForm, getFormRef: getStepRef } = this.props;
    if (stepForm) {
      if (!onSubmit) throw new Error('onSubmit is required in FormActions for step form');

      const res = await getStepRef().current.validateForms();
      const data = {};
      let errors = [];

      res.forEach((stepResult) => {
        if (stepResult.status === 'fulfilled') {
          Object.assign(data, stepResult.value);
        }
        else {
          Object.assign(data, stepResult.reason.values);
          errors = errors.concat(stepResult.reason.errorFields);
        }
      });
      return onSubmit({ data, errors });
    }

    if (onSubmit) return onSubmit();
    return null;
  };

  render() {
    const {
      editMode,
      submitting,
      submitText,
      resetBtnText,
      submitIcon,
      disabled,
      stepForm,
      buttonSize,
    } = this.props;

    return (
      <Space size="middle">
        {stepForm ? (
          <Button
            type="primary"
            htmlType="reset"
            size={buttonSize}
            danger
            onClick={this.handleStepReset}
            icon={<ReloadOutlined />}
            disabled={disabled || submitting}
          >
            {resetBtnText}
          </Button>
        ) : null}

        <Button
          type="primary"
          htmlType="reset"
          size={buttonSize}
          danger
          onClick={this.handleReset}
          icon={<ReloadOutlined />}
          disabled={disabled || submitting}
        >
          {resetBtnText} {stepForm && 'All'}
        </Button>

        {this.props.children}

        <Button
          type="primary"
          htmlType="submit"
          size={buttonSize}
          onClick={this.handleSubmit}
          icon={submitIcon || (editMode ? <EditOutlined /> : <PlusOutlined />)}
          disabled={disabled || submitting}
        >
          {submitText || (editMode ? 'Update' : 'Create')}
        </Button>

        {submitting && <Spin />}
      </Space>
    );
  }
}

FormActions.defaultProps = {
  editMode: false,
  submitting: false,
  disabled: false,
  buttonSize: 'large',
  resetBtnText: 'Reset',
};

FormActions.propTypes = {
  editMode: PropTypes.bool,
  submitting: PropTypes.bool,
  getFormRef: PropTypes.func,
  submitIcon: PropTypes.element,
  submitText: PropTypes.string,
  resetBtnText: PropTypes.string,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  stepForm: PropTypes.bool,
  buttonSize: PropTypes.string,
};

export default FormActions;
