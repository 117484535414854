import { logger } from 'utils';
import API from '../../api';
import { logoutAction } from './simpleAuthActions';

const login = (credentials) => (dispatch) =>
  API.Auth.login({ data: credentials }).then(async ({ data }) => {
    let valid;
    if (data.userType === 'base') {
      ({ data: valid } = await API.Group.valid({
        headers: { Authorization: `Bearer ${data.access_token}` },
      }).then((res) => res.data));
    }
    dispatch({ type: 'CLEAR_ALL_NOTIFICATIONS' });
    dispatch({ type: 'SET_DEFAULT_DASHBOARD', dashboardId: data.dashboardId });
    dispatch({
      type: 'LOGIN_SUCCESS',
      accessToken: data.access_token,
      permissions: data.permissions,
      userId: data.id,
      userType: data.userType,
      userName: data.name,
      pid: data.pid,
      aid: data.aid,
      teamFlags: data.teamFlags,
      valid,
    });
  });

const logout = (dispatch) => {
  API.Auth.logout()
    .catch((err) => logger.error({ err }, '[LOGOUT ERROR]'))
    .finally(() => {
      dispatch(logoutAction);
    });
  dispatch({ type: 'CLEAR_ALL_NOTIFICATIONS' });
};

/**
 * @param {{
 *  id?: string,
 *  email?: string,
 * }} param0
 * @param {{
 *  thenCb: (data: any) => any,
 *  catchCb: () => void
 * }} param1
 */
const virtualLogin = ({ id, email }, { thenCb, catchCb }) => (dispatch) =>
  API.Auth.virtualLogin(
    { data: { virtualUserId: id, virtualUserEmail: email } },
    { errorNotification: true },
  )
    .then(({ data }) => {
      dispatch({ type: 'SET_DEFAULT_DASHBOARD', dashboardId: data.dashboardId });
      dispatch({
        type: 'LOGIN_SUCCESS',
        accessToken: data.access_token,
        permissions: data.permissions,
        userId: data.id,
        userType: data.userType,
        userName: data.name,
        pid: data.pid,
        aid: data.aid,
        virtualLogin: true,
        teamFlags: data.teamFlags,
      });
      thenCb?.(data);
    })
    .catch(() => {
      catchCb?.();
    });

const virtualLogout = (dispatch) =>
  API.Auth.virtualLogout().then(({ data }) => {
    dispatch({ type: 'SET_DEFAULT_DASHBOARD', dashboardId: data.dashboardId });
    dispatch({
      type: 'LOGIN_SUCCESS',
      accessToken: data.access_token,
      permissions: data.permissions,
      userId: data.id,
      userType: data.userType,
      userName: data.name,
      pid: data.pid,
      virtualLogin: false,
      teamFlags: data.teamFlags,
    });
  });

export { login, logout, virtualLogin, virtualLogout };
