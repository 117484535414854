import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Avatar, List, Skeleton, Popover, Badge, Button } from 'antd';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { fetchNotifications, fetchUnreadCount, markRead } from 'store/actions/notificationActions';
import FaIcon from 'components/FaIcon';
import Loading from 'components/Loading';
import { formatNotification } from './utils';
import './styles.less';

const LIMIT = 15;

/**
 * @typedef {{userName: string, partnerId?: number}} Props
 * @typedef {import('react-router-dom').RouteComponentProps &
 *  ReturnType<typeof mapStateToProps> &
 *  ReturnType<typeof mapDispatchToProps>
 * } ExtraProps
 *
 * @extends {Component<Props & ExtraProps>}
 */
class Notification extends Component {
  constructor(props) {
    super(props);
    this.popoverRef = React.createRef();
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.fetchNotifications({ initOnly: true });
    this.props.fetchUnreadCount(true);
    this.fetchUnreadInterval = setInterval(this.props.fetchUnreadCount, 1 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchUnreadInterval);
  }

  get loadMore() {
    const { allowLoadMore } = this.props;
    const { loading } = this.state;
    return !loading && allowLoadMore ? (
      <div className="loadmore-container">
        <Button className="loadmore-button" onClick={this.onLoadMore} type="link" block>
          load more..
        </Button>
      </div>
    ) : (
      loading && <Loading />
    );
  }

  get notificationList() {
    const { notifications } = this.props;

    return (
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        loadMore={this.loadMore}
        dataSource={notifications}
        style={{
          maxHeight: '500px',
          overflow: 'auto',
        }}
        renderItem={(notification) => {
          const { title, description, avatarIcon } = formatNotification(notification);
          return (
            <List.Item
              className={classNames('notification-box', {
                'unread-notification': notification.status === 'UNREAD',
              })}
              style={{ padding: 12 }}
            >
              <Skeleton avatar title={false} loading={notification.loading} active>
                <List.Item.Meta
                  style={{ marginTop: 0 }}
                  avatar={<Avatar icon={avatarIcon} />}
                  title={title}
                  description={description}
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />
    );
  }

  onLoadMore = () => {
    this.props.fetchNotifications();
  };

  handleVisibleChange = (flag) => {
    const { unreadCount } = this.props;
    if (flag) {
      this.props.fetchNotifications({ onlyFetchIfNew: true });
    }
    else if (!flag && unreadCount) this.props.markRead();
  };

  render() {
    return (
      <span>
        <Popover
          content={this.notificationList}
          trigger="click"
          placement="bottomRight"
          title="Notifications"
          onVisibleChange={this.handleVisibleChange}
          overlayClassName="notification-popover"
          getPopupContainer={() => document.querySelector('.navbar')}
        >
          <Badge count={this.props.unreadCount} size="small">
            <FaIcon icon={faBell} size="lg" />
          </Badge>
        </Popover>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  unreadCount: state.notification.unreadCount,
  notifications: state.notification.notifications,
  allowLoadMore: state.notification.allowLoadMore,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnreadCount: (override) => dispatch(fetchUnreadCount(override)),
  fetchNotifications:
    /** @type {(opts?: {initOnly?: boolean, onlyFetchIfNew?: boolean}) => void} */
    ({ initOnly, onlyFetchIfNew } = {}) =>
      dispatch(fetchNotifications({ limit: LIMIT, onlyFetchIfNew, initOnly })),
  markRead: () => dispatch(markRead),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
