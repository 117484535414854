import React from 'react';
import logger from 'utils/logger';

/**
 * @type {{
 *  cancelToken: import('axios').CancelToken;
 *  onError: (err: Error) => void;
 * }}
 */
const defaultContextVal = {
  cancelToken: null,
  onError: (err) => {
    logger.error({ err }, 'No Provider Context Error');
  },
};

const PageContext = React.createContext(defaultContextVal);

const usePage = () => React.useContext(PageContext);

export { PageContext, usePage };
