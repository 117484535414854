import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil, isPlainObject, isEqual } from 'lodash';
import { Form } from 'antd';
import { PropTypePresets, Formatter } from 'utils';
import { transformRules } from 'utils/rules';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';
import MultiCheckbox from 'components/MultiCheckbox';

/**
 * @typedef {import('prop-types').InferProps<typeof FormMultiCheckbox.propTypes>} Props
 *
 * @extends {Component<Props>}
 */
class FormMultiCheckbox extends Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);

    this.rules = transformRules(props.rules, { type: 'array' });
    this.state = {
      options: [],
    };
  }

  static getDerivedStateFromProps({ options }, state) {
    if (!isNil(options[0]) && !isPlainObject(options[0])) {
      options = Formatter.makeOptions(options, true);
    }

    if (isEqual(state.options, options)) return null;

    return { options };
  }

  render() {
    const {
      name,
      label,
      disabled,
      groupProps,
      onChange,
      initialValues,
      help,
      formItemProps,
      multiCheckboxProps,
      showSelectAll,
      tooltip,
      colSpan,
    } = this.props;
    const { options } = this.state;

    return (
      <Form.Item
        name={name}
        label={label}
        rules={this.rules}
        tooltip={InfoTooltip.Config(tooltip)}
        extra={<Help text={help} />}
        validateFirst
        {...formItemProps}
      >
        <MultiCheckbox
          options={options}
          disabled={disabled}
          initialValues={initialValues}
          showSelectAll={showSelectAll}
          groupProps={groupProps}
          colSpan={colSpan}
          onChange={onChange}
          {...multiCheckboxProps}
        />
      </Form.Item>
    );
  }
}

FormMultiCheckbox.defaultProps = {
  rules: [],
  formItemProps: {},
  onChange: (val) => val,
  colSpan: 6,
};

FormMultiCheckbox.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string,
  initialValues: PropTypePresets.arrayOfStringAndNumber,
  tooltip: PropTypePresets.tooltip,
  help: PropTypePresets.help,
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  ).isRequired,
  groupProps: PropTypes.object,
  formItemProps: PropTypes.object,
  multiCheckboxProps: PropTypes.object,
  showSelectAll: PropTypePresets.showSelectAll,
  onChange: PropTypes.func,
  colSpan: PropTypes.number,
};

export default FormMultiCheckbox;
