/* eslint-disable max-len */
import React, { lazy } from 'react';
import {
  faTags,
  faChartBar,
  faFileExport,
  faTable,
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FaIcon, ErrorPage } from 'components';

const UserProfile = lazy(() =>
  import(/* webpackChunkName: "partner-profile" */ 'views/partner/User/Profile'),
);

const LiveOffers = lazy(() =>
  import(/* webpackChunkName: "partner-LiveOffers" */ 'views/partner/Offer/LiveOffers'),
);
const PreviewOffers = lazy(() =>
  import(/* webpackChunkName: "partner-PreviewOffers" */ 'views/partner/Offer/PreviewOffers'),
);
const ViewOffer = lazy(() =>
  import(/* webpackChunkName: "partner-PartnerViewOffer" */ 'views/partner/Offer/ViewOffer'),
);
const AllOffers = lazy(() =>
  import(/* webpackChunkName: "partner-AllOffers" */ 'views/partner/Offer/AllOffers'),
);

const AffiliateCustomStats = lazy(() =>
  import(/* webpackChunkName: "partner-CustomStats" */ 'views/partner/Stats/CustomStats'),
);

const AffiliatePostbackStats = lazy(() =>
  import(/* webpackChunkName: "partner-PostbackStats" */ 'views/partner/Stats/PostbackStats'),
);

const AffiliatePostback = lazy(() =>
  import(/* webpackChunkName: "partner-ViewPostback" */ 'views/partner/Stats/ViewPostbackStats'),
);

const AffiliateExport = lazy(() =>
  import(/* webpackChunkName: "partner-Export" */ 'views/partner/Export/Export'),
);
const AffiliateDashboard = lazy(() =>
  import(/* webpackChunkName: "partner-Dashboard" */ 'views/partner/Dashboard/ViewDashboard'),
);

const CreateTicket = lazy(() =>
  import(/* webpackChunkName: "partner-CreateTicket" */ 'views/partner/Ticket/CreateTicket'),
);
const ListTicket = lazy(() =>
  import(/* webpackChunkName: "partner-ListTicket" */ 'views/partner/Ticket/ListTicket'),
);
const ViewTicket = lazy(() =>
  import(/* webpackChunkName: "partner-ViewTicket" */ 'views/partner/Ticket/ViewTicket'),
);

/**
 * @type {import('./index').RouteItem[]}
 */
const routes = [
  {
    name: 'Dashboard',
    component: AffiliateDashboard,
    base: '/affiliate/:pid',
    path: '/dashboard',
    icon: <FaIcon icon={faChartBar} />,
  },
  {
    name: 'Profile',
    component: UserProfile,
    base: '/affiliate/:pid',
    path: '/profile',
    invisible: true,
  },
  {
    name: 'Offers',
    icon: <FaIcon icon={faTags} />,
    base: '/affiliate/:pid/offer',
    views: [
      {
        name: 'Live Offers',
        component: LiveOffers,
        path: 's/live',
      },
      {
        name: 'All Offers',
        component: AllOffers,
        path: 's/all',
      },
      {
        name: 'Preview Offers',
        component: PreviewOffers,
        path: 's/preview',
      },
      {
        name: 'Offer Details',
        component: ViewOffer,
        path: '/view/:id',
        invisible: true,
      },
    ],
  },
  {
    name: 'Ticketing',
    icon: <FaIcon icon={faTicketAlt} />,
    base: '/affiliate/:pid/ticket',
    views: [
      {
        name: 'Create Ticket',
        component: CreateTicket,
        path: '/create',
      },
      {
        name: 'List Ticket',
        component: ListTicket,
        path: 's',
        exact: true,
      },
      {
        name: 'View Ticket',
        component: ViewTicket,
        path: '/view/:id',
        invisible: true,
      },
    ],
  },
  {
    name: 'Stats',
    base: '/affiliate/:pid/stats',
    icon: <FaIcon icon={faTable} />,
    views: [
      {
        name: 'Conversion Stats',
        component: AffiliatePostbackStats,
        path: '/postbacks',
      },
      {
        name: 'Custom Stats',
        component: AffiliateCustomStats,
        path: '/custom',
      },
      {
        name: 'View Postback',
        component: AffiliatePostback,
        path: '/postback/:id',
        invisible: true,
      },
    ],
  },
  {
    name: 'Export',
    component: AffiliateExport,
    base: '/affiliate/:pid',
    path: '/export',
    icon: <FaIcon icon={faFileExport} />,
  },
  {
    name: 'Page Not Found',
    component: ErrorPage.NotFound,
    base: '/affiliate/:pid',
    path: '/404',
    invisible: true,
  },
];

export default routes;
