import React from 'react';
import { Switch, Route } from 'react-router-dom';
import hasPermission from 'utils/permissions';

/**
 * @typedef {{
 *  name: string,
 *  base?: string,
 *  icon?: JSX.Element,
 *  invisible?: boolean,
 *  permissions?: string[],
 *  help?: {link: string, text: string},
 *  showTitle?: boolean,
 *  views?: RouteItem[],
 * }} RouteItemCommon
 */

/**
 * @typedef {{
 *  component: import('react').ComponentType,
 *  path: string | string[],
 *  exact?: boolean,
 *  buttons?: import('react').ComponentType<import('react-router-dom').RouteChildrenProps>[]
 * }} RouteItemChildExtra
 */

/**
 * The primary path needs to come first
 * @typedef {RouteItemCommon & RouteItemChildExtra} RouteItemChild
 */

/**
 * @typedef {RouteItemCommon} RouteItemParent
 */

/**
 * @typedef {RouteItemCommon & Partial<RouteItemChildExtra>} RouteItem
 */

/**
 * @param {RouteItemChild} route
 * @param {RouteItemParent} [parent]
 */
function getFullPath(route, parent) {
  const basePath = parent?.base || route.base || '';
  if (!Array.isArray(route.path)) {
    return `${basePath}${route.path}`;
  }
  return route.path.map((path) => `${basePath}${path}`);
}

function forceInvisible(route) {
  let permissions;
  if (route.views) {
    const withoutPermission = route.views.reduce((prev, child) => {
      if (child.invisible) return prev;
      if (child.permissions?.length) return prev;
      prev.push(child);
      return prev;
    }, []);

    if (withoutPermission.length) return false;

    permissions = route.views.reduce((prev, child) => {
      if (child.invisible) return prev;
      prev.push(...(child.permissions || []));
      return prev;
    }, []);
  }
  else permissions = route.permissions;
  return !hasPermission(permissions);
}

/**
 * @param {{
 *  routes: RouteItem[],
 *  default?: JSX.Element,
 *  render: (props: {key: string, route: RouteItemChild, parent?: RouteItemParent}) => JSX.Element,
 *  renderParent?: (props: { key: string, route: RouteItemParent, children: JSX.Element[] }) => JSX.Element,
 * }} props
 */
function IterateRoutes(props) {
  /** @return {JSX.Element[]} */
  const recurseFunction = (routes, parent = null) =>
    routes.map((route, index) => {
      const key = parent ? `${parent.key}.${index}` : `${index}`;
      if (forceInvisible(route)) {
        route = { ...route, invisible: true };
      }

      if (route.views) {
        const children = recurseFunction(route.views, { ...route, key }).filter(Boolean);
        if (props.renderParent) {
          return props.renderParent({ children, route, key });
        }
        return children;
      }

      return props.render({
        key,
        parent,
        route: {
          ...route,
          path: getFullPath(route, parent),
        },
      });
    });

  const items = recurseFunction(props.routes).filter(Boolean);

  if (props.default) {
    items.push(props.default);
  }
  return items;
}

/**
 * @typedef {(props: {
 *   parent?: RouteItem,
 *   route: RouteItem,
 *   key: string,
 *   routeProps: import('react-router-dom').RouteComponentProps,
 *  }) => JSX.Element} RenderFn
 *
 * @param {{
 *  routes: RouteItem[],
 *  render?: RenderFn,
 *  default?: (props: import('react-router-dom').RouteComponentProps) => JSX.Element,
 * }} props
 */
function MapRoutes(props) {
  const items = IterateRoutes({
    routes: props.routes,
    render: ({ route, key, parent }) => (
      <Route
        path={route.path}
        key={key}
        exact={route.exact}
        render={(routeProps) => props.render({ parent, key, route, routeProps })}
      />
    ),
    default: props.default && <Route path="*" key="-1" render={props.default} />,
  });
  return <Switch>{items}</Switch>;
}

MapRoutes.defaultProps = {
  render: ({ route, routeProps }) => {
    const { component: Component } = route;
    return <Component {...routeProps} />;
  },
};

export { getFullPath, IterateRoutes, MapRoutes };
