import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, DatePicker } from 'antd';
import { PropTypePresets } from 'utils';
import { transformRules, dateRange } from 'utils/rules';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';
import './styles.scoped.less';

const { RangePicker } = DatePicker;

/**
 * @typedef {import('prop-types').InferProps<typeof FormDate.propTypes>} Props
 *
 * @extends {Component<Props>}
 */
class FormDate extends Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);

    this.rules = transformRules(props.rules, {
      type: props.type === 'range' ? 'array' : 'object',
    });

    if (props.allowedRange) {
      this.rules.push(dateRange(props.allowedRange));
    }
  }

  getPopupContainer = (node) => node.closest('.ant-picker');

  render() {
    const {
      label,
      name,
      formItemProps,
      defaultValue: initialValue,
      tooltip,
      placeholder,
      disabled,
      type,
      datePickerProps,
      help,
    } = this.props;

    const forwardProps = {
      ...datePickerProps,
      // @ts-ignore
      ...(datePickerProps?.showTime && { className: 'with-time' }),
      disabled,
    };
    return (
      <Form.Item
        name={name}
        label={label}
        rules={this.rules}
        tooltip={InfoTooltip.Config(tooltip)}
        extra={<Help text={help} />}
        initialValue={initialValue}
        validateFirst
        {...formItemProps}
      >
        {type === 'range' ? (
          <RangePicker
            picker="date"
            placeholder={[placeholder, placeholder]}
            style={{ width: '100%' }}
            getPopupContainer={this.getPopupContainer}
            onChange={(...args) => {
              if (!args[0]?.length) return;
              args[0][0] = args[0][0].startOf('day');
              args[0][1] = args[0][1].endOf('day');
            }}
            {...forwardProps}
          />
        ) : (
          <DatePicker
            placeholder={placeholder}
            getPopupContainer={this.getPopupContainer}
            {...forwardProps}
          />
        )}
      </Form.Item>
    );
  }
}

FormDate.defaultProps = {
  placeholder: '',
  disabled: false,
  rules: [],
  formItemProps: {},
  datePickerProps: {},
  type: 'date',
  allowedRange: null,
};

FormDate.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypePresets.tooltip,
  help: PropTypePresets.help,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  formItemProps: PropTypes.object,
  datePickerProps: PropTypes.object,
  type: PropTypes.oneOf(['date', 'range']),
  defaultValue: PropTypes.oneOfType([
    // @ts-ignore
    PropTypes.instanceOf(dayjs),
    // @ts-ignore
    PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  ]),
  allowedRange: PropTypes.number,
};

export default FormDate;
