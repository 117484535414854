import Axios from 'axios';
import { toPath } from 'lodash';
import { notification } from 'antd';
import logger from 'utils/logger';

const { isCancel } = Axios;

const ERROR_MSG = {
  400: 'Validation Error',
  404: 'Page not found',
  403: 'Incorrect entry',
  418: 'Nothing to change',
  401: 'Unauthorised',
  500: 'Server Error',
};

const UNKOWN = 'Unknown Error (API)';

/**
 * Return appropriate error message for error thrown from api
 * @param {Object} error
 * @returns {string}
 */
function getErrorMessage(error) {
  if (!error) return UNKOWN;
  if (!error.response) return error.message || UNKOWN;

  const { response } = error;
  if (response.data) {
    if (response.data.error) {
      return response.data.error;
    }
    if (response.data.message) {
      return response.data.message;
    }
  }
  if (response.message) {
    return response.message;
  }
  return ERROR_MSG[response.status];
}

/**
 * @typedef {{notification?: string | boolean}} HandlerOptions
 *
 *
 * @param {import('axios').AxiosError} error
 * @param {HandlerOptions} [param1]
 */
function basicErrorHandler(error, { notification: notificationMsg = true } = {}) {
  // Request manually cancelled using CancelToken
  if (isCancel(error)) return;
  const description = getErrorMessage(error);
  if (notificationMsg) {
    notification.error({
      message: typeof notificationMsg === 'string' ? notificationMsg : 'Failure',
      description,
    });
  }
}

/**
 *
 * @param {import('axios').AxiosResponse} res
 * @param {HandlerOptions} [param1]
 */
function basicSuccessHandler(res, { notification: notificationMsg = false } = {}) {
  if (!notificationMsg) return res;
  const description = res.data.message;
  notification.success({
    message: typeof notificationMsg === 'string' ? notificationMsg : 'Success',
    description,
  });
  return res;
}

/**
 * For converting "key[0]" to ["key". 0]
 * @param {string} path
 */
function convertToIndex(path) {
  const index = Number(path);
  if (Number.isNaN(index)) return path;
  return index;
}

/**
 * @param {import('axios').AxiosError} error} error
 */
function getFormErrorsFromError(error) {
  const errors = error?.response?.data?.errors;
  if (!errors) {
    logger.error({ err: error }, 'NON_FORM_ERROR');
    return [];
  }
  return Object.keys(errors).map((path) => ({
    errors: [errors[path]],
    name: toPath(path).map(convertToIndex),
  }));
}

/**
 * @param {import('axios').AxiosError} error
 * @param {React.RefObject<import('antd/lib/form').FormInstance>} formRef
 */
function setFormErrors(error, formRef) {
  if (isCancel(error)) return;
  const fieldData = getFormErrorsFromError(error);

  if (fieldData.length) {
    formRef.current.setFields(fieldData);
    formRef.current.scrollToField(fieldData[0].name);
    // @ts-ignore
    error.fieldData = fieldData;
  }
}

/**
 *
 * @param {(err: Error | import('axios').AxiosError) => any} errHandler
 */
function wrapInIsCancel(errHandler) {
  return (err) => {
    if (isCancel(err)) return {};
    return errHandler(err);
  };
}

export {
  isCancel,
  wrapInIsCancel,
  getFormErrorsFromError,
  setFormErrors,
  getErrorMessage,
  basicErrorHandler,
  basicSuccessHandler,
};
