import React from 'react';
import FiltersOrig, { queryParamsToProps, FilterProps } from './Filters';
import { dateRangeMap, parseDateRangeString } from './types';

// Type hack
// eslint-disable-next-line react/prefer-stateless-function
class Tmp extends React.Component<FilterProps> {}

// @ts-ignore
const Filters: typeof Tmp = FiltersOrig;
export default Filters;

export { queryParamsToProps, dateRangeMap, parseDateRangeString };
