import Axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { PageContext, isCancel } from 'api';
import { hasPermission, logger } from 'utils';
import './styles.less';

/**
 * @typedef {{
 *  status?: any,
 *  title: string,
 *  description?: string,
 *  page?: boolean,
 * }} Props
 *
 * @param {Props} props
 */
function ErrorPage(props) {
  const { status = <FrownOutlined />, title, description, page = true } = props;

  return (
    <div className={page ? 'error-page' : 'error-component'}>
      <h1 className="title">{status}</h1>
      <h2 className="sub-title">{title}</h2>
      {description ? <h4 className="description">{description}</h4> : null}
    </div>
  );
}

ErrorPage.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

ErrorPage.NotFound = () => (
  <ErrorPage status={404} title="Page not found :(" description="Oops! Looks like you got lost." />
);

ErrorPage.ServerError = () => (
  <ErrorPage
    status={500}
    title="Internal Server Error :/"
    description="Oops! Looks like we made a mistake."
  />
);

/**
 * @param {{permission: string | string[]}} props
 */
ErrorPage.PermissionError = ({ permission }) => (
  <ErrorPage
    status={<FrownOutlined />}
    title="You don't have permission to view this page"
    description={`Please contact the admin to give you the permission "${permission}" to access this page`}
  />
);

/**
 * @typedef {Omit<import('antd/lib/card').CardProps, 'loading'> & {
 *  status: 'LOADED' | 'LOADING' | 'ERROR',
 *  errorMessage?: string,
 * }} ErrorCardProps
 * @type {import('react').FunctionComponent<ErrorCardProps>}
 */
ErrorPage.Card = ({
  status,
  children,
  className = '',
  errorMessage = 'Could not load data',
  ...rest
}) => (
  <Card
    className={status === 'ERROR' ? `error-card ${className}` : className}
    {...rest}
    loading={status === 'LOADING'}
  >
    {status === 'ERROR' ? <ErrorPage page={false} title={errorMessage} /> : children}
  </Card>
);

ErrorPage.withErrorPages = (Component) =>
  class ComponentWithErrorPages extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        status: this.checkPermission(),
        err: null,
      };
      this.cancelToken = new Axios.CancelToken((c) => {
        this.cancel = c;
      });
    }

    componentWillUnmount = () => {
      if (this.cancel) {
        this.cancel('Request Cancelled: Component Unmounted');
      }
    };

    /**
     * @param {import('axios').AxiosError} err
     */
    static getDerivedStateFromError(err) {
      const status = err?.response?.status ?? (err.message || 'UNKNOWN_ERROR');
      return { status, err };
    }

    checkPermission = () => {
      const { route } = this.props;
      return hasPermission(route?.permissions) ? true : 403;
    };

    handleError = (err) => {
      if (isCancel(err)) return;
      // TODO: Move 401 handling here and add corresponding error page
      this.setState(ComponentWithErrorPages.getDerivedStateFromError(err));
    };

    render() {
      if (this.state.status === 403) {
        return (
          <ErrorPage.PermissionError permission={this.props.route?.permissions ?? 'unknown'} />
        );
      }
      if (this.state.status === 404) {
        return <ErrorPage.NotFound />;
      }
      if (this.state.status === 500) {
        logger.error({ err: this.state.err }, '500 API Error');
        return <ErrorPage.ServerError />;
      }
      if (this.state.status !== true) {
        logger.error({ err: this.state.err }, 'Unknown');
        return (
          <ErrorPage
            status={<FrownOutlined />}
            description={String(this.state.status)}
            title="Unexpected Error :/"
          />
        );
      }
      return (
        <PageContext.Provider
          value={{
            cancelToken: this.cancelToken,
            onError: this.handleError,
          }}
        >
          <Component {...this.props} onError={this.handleError} />
        </PageContext.Provider>
      );
    }
  };

export default ErrorPage;
