import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import FaIcon from 'components/FaIcon';

/**
 * @param {{
 *  text: string | import('react').ElementType
 *  style: import('react').CSSProperties
 *  block: boolean
 *  showHelpText: boolean
 *  alwaysShow: boolean
 * }} param0
 */
function Help({ text, style, block, showHelpText }) {
  if (!showHelpText || !text || (typeof text === 'string' && !text.length)) {
    return null;
  }

  if (block) {
    return (
      <Card
        className="help-text"
        type="inner"
        size="small"
        title={
          <>
            <FaIcon icon={faQuestionCircle} />
            &nbsp; Help
          </>
        }
        style={style}
      >
        {text}
      </Card>
    );
  }

  return (
    <div className="help-text" style={{ marginTop: 5, ...style }}>
      <FaIcon icon={faQuestionCircle} />
      &ensp;
      {text}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  // helpTextVisible or not logged in
  showHelpText: props.alwaysShow ?? (state.globals.helpTextVisible || !state.auth.accessToken),
});

export default connect(mapStateToProps)(Help);
