import { isPlainObject, isArray, isNil, isEqual, mapValues, entries, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { timeZonesMap } from 'constants/TimeZones';
import filterTypes from './types';

const defaultValues = mapValues(filterTypes, 'default');
const TIMEZONE_REGEX = /[+-]\d{2}:\d{2}$/;

/**
 * @typedef {{[key: string]: import('./types').FilterTypeProps}} FiltersMap
 */

function getValue(valObj) {
  if (!isPlainObject(valObj)) return valObj;
  const newObj = {};
  entries(valObj).forEach(([key, val]) => {
    if (!isNil(val)) newObj[key] = val;
  });
  return isEmpty(newObj) ? undefined : newObj;
}

/**
 * @param {Date} d1
 * @param {Date} d2
 */
function isSameDate(d1, d2) {
  return dayjs(d1).isSame(dayjs(d2));
}

/**
 * @param {import('./Filters').Props['setQuery']} setQuery
 * @param {{
 *  dateRange?: [dayjs.Dayjs, dayjs.Dayjs],
 *  phrase?: string,
 *  timezone?: string
 * } & {
 *  [key: string]: any
 * }} data
 * @param {import('./Filters').Props['query']} oldQuery
 * @param {FiltersMap} filtersMap
 * @param {{defaults?: boolean, updateQuery?: boolean}} [options]
 * @returns An API params object for list queries
 */
function convertToQP(setQuery, data, oldQuery, filtersMap, options = {}) {
  const { defaults = false, updateQuery = true } = options;
  const timeUnit = filtersMap.timeRange?.extra?.required ? 'hour' : 'day';

  /** @type {import('./Filters').Props['query']} */
  const query = {
    filters: {},
    fromDate: undefined,
    toDate: undefined,
    timezone: undefined,
    phrase: undefined,
  };
  const apiHeaders = {};
  const { dateRange, phrase, timezone = 'Asia/Kolkata', ...apiParams } = data;
  const offset = timezone ? timeZonesMap[timezone].utc : timezone;
  const [fromMoment, to] = dateRange ?? [];
  const toMoment = to && to.add(1, timeUnit).startOf(timeUnit);
  if (fromMoment && toMoment) {
    query.fromDate = fromMoment.toDate();
    query.toDate = toMoment.toDate();

    let fromDate = fromMoment.format();
    let toDate = toMoment.format();
    if (timezone) {
      fromDate = fromDate.replace(TIMEZONE_REGEX, offset);
      toDate = toDate.replace(TIMEZONE_REGEX, offset);
    }
    apiParams.fromDate = fromDate;
    apiParams.toDate = toDate;
  }

  if (phrase) {
    apiParams.phrase = phrase;
    query.phrase = phrase;
  }

  if (timezone) {
    apiHeaders['X-Timezone'] = offset;
    query.timezone = timezone;
  }

  const { filters: oldFilters = {} } = oldQuery;
  let updateFiltersQP = false;

  Object.entries(apiParams?.filters ?? {}).forEach(([key, val]) => {
    let value = getValue(val);
    if (isNil(value)) {
      if (oldFilters[key] !== undefined) {
        // undefined is needed to delete QP
        query.filters[key] = undefined;
        updateFiltersQP = true;
      }
      return;
    }
    if (isArray(value)) {
      value = value.map(getValue).filter(Boolean);

      if (!value.length) {
        if (oldFilters[key] !== undefined) {
          query.filters[key] = undefined;
          updateFiltersQP = true;
        }
        return;
      }
    }
    query.filters[key] = value;
    if (!isEqual(oldFilters[key], value)) updateFiltersQP = true;
  });

  if (Object.values(query.filters).filter((v) => !isNil(v)).length === 0) {
    query.filters = undefined;
  }
  if (
    updateQuery &&
    (updateFiltersQP ||
      !isSameDate(query.fromDate, oldQuery.fromDate) ||
      !isSameDate(query.toDate, oldQuery.toDate) ||
      query.phrase !== oldQuery.phrase ||
      query.timezone !== oldQuery.timezone)
  ) {
    // Using "In" variants as we don't want to reset page and other query params of other components
    setQuery(query, defaults ? 'replaceIn' : 'pushIn');
  }
  return { apiParams, apiHeaders };
}

/**
 * @param {import('./Filters').Props['query']} query
 * @param {FiltersMap} filtersMap
 * @returns Initial Values object for form
 */
function convertFromQP(query, filtersMap) {
  const { fromDate, toDate, phrase, timezone = defaultValues.timezone, filters = {} } = query;

  let fromMoment;
  if (filtersMap.date?.extra?.required) {
    if (filtersMap.date?.defaultVal)
      fromMoment = dayjs(filtersMap.date?.defaultVal[0]).startOf('day');
    else fromMoment = dayjs().startOf('day');
  }
  else if (filtersMap.timeRange?.extra?.required) fromMoment = dayjs().startOf('hour');
  if (fromDate) fromMoment = dayjs(fromDate).startOf('hour');
  else if (fromMoment && filtersMap.date?.extra?.oldFromDate) {
    fromMoment = fromMoment.subtract(1, 'year').startOf('day');
  }

  let toMoment;
  if (filtersMap.date?.extra?.required) {
    if (filtersMap.date?.defaultVal) toMoment = dayjs(filtersMap.date?.defaultVal[1]).endOf('day');
    else toMoment = dayjs().endOf('day');
  }
  else if (filtersMap.timeRange?.extra?.required) toMoment = dayjs().endOf('hour');
  if (toDate) {
    const timeUnit = filtersMap.timeRange?.extra?.required ? 'hour' : 'day';
    toMoment = dayjs(toDate).subtract(1, timeUnit).endOf(timeUnit);
  }

  const data = {
    filters: {},
    dateRange: [fromMoment, toMoment],
    ...(filtersMap.phrase && { phrase }),
    ...(filtersMap.timezone && { timezone }),
  };

  Object.entries(filtersMap).forEach(([key, val]) => {
    if (['date', 'phrase', 'timezone'].includes(key) || !val) return;

    const path = val?.name || key;
    if (path in filters) {
      data.filters[path] = filters[path];
    }
    else {
      data.filters[path] = val?.defaultVal || defaultValues[key];
    }
  });
  return data;
}

export { convertToQP, convertFromQP };
