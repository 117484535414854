import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { castArray, startCase } from 'lodash';
import { Breadcrumb, Dropdown, Menu } from 'antd';
import { HomeOutlined, DownOutlined } from '@ant-design/icons';
import { getFullPath } from 'routes/utils';
import { hasPermission, Team } from 'utils';

/**
 * @param {{
 *  route: import('../routes').RouteItemChild,
 *  parent?: import('../routes').RouteItemParent,
 *  partnerId?: number,
 *  advertiserId?: string,
 * }} props
 */
function Breadcrumbs(props) {
  const { route, parent } = props;
  document.title = `${route.name} | ${startCase(Team)} Sapphyre`;
  const selected = parent?.views.findIndex((c) => c.name === route.name) ?? -1;

  const menuItems = parent?.views?.length
    ? parent.views
      .map((child, i) => {
        // TODO: Make something common for both Sidebar and here
        if (parent.invisible || child.invisible || !hasPermission(child.permissions)) return null;
        const path = castArray(getFullPath(child, parent))[0];

        return (
          <Menu.Item key={String(i)}>
            {selected !== i ? (
              <Link
                to={generatePath(path, {
                  pid: props.partnerId || '',
                  aid: props.advertiserId || '',
                })}
              >
                {child.name}
              </Link>
            ) : (
              child.name
            )}
          </Menu.Item>
        );
      })
      .filter(Boolean)
    : [];

  const menu = menuItems.length ? (
    <Menu defaultSelectedKeys={[String(selected)]}>{menuItems}</Menu>
  ) : null;

  return (
    // Breadcrumbs overrides separator prop and adds an extra one on hover, manually adding instead
    <Breadcrumb style={{ position: 'relative' }} separator="">
      <Breadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Separator />
      {parent && (
        <Breadcrumb.Item>
          <span>
            {parent.icon || null} <span>{parent.name}</span>
          </span>
        </Breadcrumb.Item>
      )}
      {parent && <Breadcrumb.Separator />}
      <Breadcrumb.Item>
        {menu ? (
          <Dropdown overlay={menu} getPopupContainer={(node) => node.closest('.ant-breadcrumb')}>
            <span>
              <span>{route.name}</span> <DownOutlined />
            </span>
          </Dropdown>
        ) : (
          <span>{route.name}</span>
        )}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

const mapStateToProps = (state) => ({
  partnerId: state.auth.pid,
  advertiserId: state.auth.aid,
});

export default connect(mapStateToProps)(Breadcrumbs);
