import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flatten } from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { withQueryParams, StringParam } from 'use-query-params';
import { notification } from 'antd';
import Auth from 'views/auth';
import Main from 'views/main';
import { Links } from 'links';
import { auth, getBaseRoutes, partner, advertiser } from 'routes';
import { Analytics, Team } from 'utils';

/**
 * @typedef {UseQP<typeof queryParamsToProps> &
 *   ReturnType<mapStateToProps>
 * } Props
 *
 * @extends {Component<Props>}
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.bannerRef = React.createRef();
  }

  componentDidMount() {
    const bannerWidth = this.bannerRef.current?.offsetWidth;
    if (!bannerWidth) {
      notification.warning({
        message: 'Ad Block Warning',
        description: (
          <>
            It seems you are using adblock :(
            <br />
            Please disable it for proper working of this site.
          </>
        ),
        duration: null,
      });
    }
  }

  getMainRoutes() {
    const { advertiserId, partnerId, query } = this.props;

    const routing = [
      <Route
        key="login"
        path={flatten(auth.map((route) => route.path))}
        render={() => <Redirect to={query.next || '/'} />}
      />,
    ];

    if (partnerId) {
      routing.push(
        <Route
          key="partner-home"
          path="/"
          exact
          render={() => <Redirect to={Links.Affiliate.dashboard({ partnerId })} />}
        />,
        <Route
          key="partner-check"
          path={['/affiliate/:urlPartner/:subpath(.*)', '/affiliate/:urlPartner']}
          render={({ match, location }) => {
            const { urlPartner, subpath } = match.params;
            if (String(urlPartner) === String(partnerId)) {
              return null;
            }
            let path = 'dashboard';
            if (subpath) {
              path = `${subpath}${location.search}`;
            }
            return <Redirect to={`/affiliate/${partnerId}/${path}`} />;
          }}
        />,
        <Main key="partner" routes={partner} />,
      );
    }
    else if (advertiserId) {
      routing.push(
        <Route
          key="programmatic-home"
          path="/"
          exact
          render={() => <Redirect to={Links.Programmatic.listStats({ advertiserId })} />}
        />,
        <Main key="programmatic" routes={advertiser} />,
      );
    }
    else {
      routing.push(<Main key="base" routes={getBaseRoutes(this.props.teamFlags?.programmatic)} />);
    }

    return routing;
  }

  trackDimensions = () => {
    const { userId, userType, isLoggedIn } = this.props;

    Analytics.set({
      dimension1: Team,
      ...(isLoggedIn
        ? {
          dimension2: userType,
          dimension3: userId,
        }
        : {}),
    });
  };

  render() {
    const { isLoggedIn } = this.props;

    this.trackDimensions();

    return (
      <>
        {/* TODO: Add static routes here: Privacy Policy/About/Etc */}
        {isLoggedIn ? this.getMainRoutes() : <Auth />}
        {/* For Checking if ad block is enabled */}
        {/* https://stackoverflow.com/a/55635499/12003041 */}
        <div ref={this.bannerRef} id="ad-banner" />
      </>
    );
  }
}

/**
 * @param {any} state
 */
const mapStateToProps = (state) => ({
  isLoggedIn: !!state.auth.accessToken,
  partnerId: state.auth.pid,
  advertiserId: state.auth.aid,
  userId: state.auth.userId,
  userType: state.auth.userType,
  teamFlags: state.auth.teamFlags,
});

const queryParamsToProps = { next: StringParam };

export default withQueryParams(queryParamsToProps, connect(mapStateToProps)(App));
