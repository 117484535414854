import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import OverflowTooltip from './OverflowTooltip';

const env = process.env.REACT_APP_ENV;
const helpDocDomain = env === 'production' ? 'sapphyre.in' : 'sapphyre.co.in';

/**
 * @param {import('react-router-dom').LinkProps & {
 *  helpDoc?: boolean,
 * }} param0
 */
function Link({ to, helpDoc, children, ...rest }) {
  let link;
  if (helpDoc) {
    if (typeof to !== 'string' || !to.startsWith?.('/')) {
      throw new Error('Help Doc link should be a relative path');
    }
    to = `https://${helpDocDomain}${to}`;
  }

  if (typeof to === 'string' && !to.startsWith('/')) {
    link = (handleMouseEnter) => (
      <a href={to} rel="noopener noreferrer" target="_blank" onMouseEnter={handleMouseEnter}>
        {children}
      </a>
    );
  }
  else {
    link = (handleMouseEnter) => (
      <RouterLink to={to} {...rest} onMouseEnter={handleMouseEnter}>
        {children}
      </RouterLink>
    );
  }

  if (typeof children === 'string')
    return <OverflowTooltip title={children}>{link}</OverflowTooltip>;
  return link();
}

export default Link;
