import { isEqual } from 'lodash';
/**
 * @see https://github.com/rt2zz/redux-persist-crosstab/issues/7
 */
function subscribe(store) {
  if (!window.BroadcastChannel) return;
  const crossTabChannel = new window.BroadcastChannel('crossTabChannel');
  let messageDispatch = false;

  let currentValue;
  store.subscribe(() => {
    // store changes happened because of the message event too. No need to process this.
    if (messageDispatch) {
      messageDispatch = false;
      return;
    }

    const previousValue = currentValue;
    currentValue = store.getState().notification;
    if (isEqual(previousValue, currentValue)) return;
    crossTabChannel.postMessage({
      payload: currentValue,
      type: 'SYNC_NOTIFICATIONS',
      key: 'notification',
    });
  });

  crossTabChannel.addEventListener('message', ({ data }) => {
    messageDispatch = true;
    store.dispatch({
      key: data.key,
      payload: data.payload,
      type: data.type,
    });
  });
}

export default subscribe;
