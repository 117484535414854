import { castArray } from 'lodash';
import { store } from '../store';

/**
 * TODO: Cache this result
 * @param {string[] | string} permissionsToCheck
 */
function hasPermission(permissionsToCheck) {
  if (!permissionsToCheck || !permissionsToCheck.length) return true;
  permissionsToCheck = castArray(permissionsToCheck);

  const { auth: authStore } = store.getState();
  const { permissions, valid = {}, userType } = authStore;
  if (!permissions) return false;
  // FIXME: Currently partner can't access /valid route
  if (userType === 'partner') return true;

  /**
   *
   * @param {string[]} starPermissions
   * @param {string} app
   */
  const filterByValid = (starPermissions, app) => {
    const validPermissions = valid[app];

    if (!validPermissions) return starPermissions;
    if (starPermissions.includes('*')) return validPermissions;
    return starPermissions.filter((permission) => validPermissions.includes(permission));
  };

  for (let i = 0; i < permissionsToCheck.length; i += 1) {
    const permission = permissionsToCheck[i];
    const [app, action] = permission.split('.');
    const allowed = (permissions.allow?.[app] ?? []).concat(
      filterByValid(permissions.allow?.['*'] ?? [], app),
    );
    const denied = (permissions.deny?.[app] ?? []).concat(
      filterByValid(permissions.deny?.['*'] ?? [], app),
    );
    if (
      (allowed.includes('*') || allowed.includes(action)) &&
      !(denied.includes('*') || denied.includes(action))
    ) {
      return true;
    }
  }

  return false;
}

export default hasPermission;
