import React, { useState } from 'react';
import { Tooltip } from 'antd';

function handleMouseEnter(e, setShowTooltip) {
  const parent = e.target.closest('td') || e.target.closest('div');
  if (parent.scrollWidth > parent.offsetWidth) {
    const timout = setTimeout(() => setShowTooltip(true), 500);
    e.target.onmouseleave = () => {
      clearTimeout(timout);
      setShowTooltip(false);
    };
  }
  else {
    setShowTooltip(false);
  }
}

/**
 * NOTE: Can also work as function directly
 * @param {string | {title: string, children: (onMouseEnter: React.MouseEvent) => any}} props
 */
function OverflowTooltip(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  let title;
  let children;

  if (typeof props === 'string') {
    title = props;
    children = <span onMouseEnter={(e) => handleMouseEnter(e, setShowTooltip)}>{title}</span>;
  }
  else {
    ({ title, children } = props);
    children = children((e) => handleMouseEnter(e, setShowTooltip));
  }

  if (typeof title !== 'string' || !showTooltip) return children;

  return (
    <Tooltip
      title={title}
      placement="bottomLeft"
      defaultVisible
      getPopupContainer={(node) => node.closest('.ant-card')}
    >
      {children}
    </Tooltip>
  );
}

export default OverflowTooltip;
