const setCacheData = (hash, response) => ({
  type: 'SET_CACHE_DATA',
  hash,
  response,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  setCacheData,
};
