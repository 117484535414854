const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_UNREAD_COUNT':
      return {
        ...state,
        unreadFetchTime: new Date(),
        unreadCount: action.unreadCount,
      };
    case 'UPDATE_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications,
        allowLoadMore: action.allowLoadMore,
        lastUnreadCount: action.lastUnreadCount,
      };
    case 'SET_ALL_READ':
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          notification.status = 'READ';
          return notification;
        }),
        unreadCount: 0,
        lastUnreadCount: 0,
      };
    case 'SYNC_NOTIFICATIONS':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_ALL_NOTIFICATIONS':
      return {
        ...state,
        notifications: [],
        unreadCount: 0,
        lastUnreadCount: 0,
        allowLoadMore: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
