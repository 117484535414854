import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { debounce, get, uniqBy } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import API from 'api';
import { Links } from 'links';
import { logger } from 'utils';
import { updateRecentSearch } from 'store/actions/globalActions';

/**
 * @typedef {import('prop-types').InferProps<typeof CustomSelect.propTypes>} Props
 *
 * @typedef {import('react-router-dom').RouteComponentProps &
 *  ReturnType<typeof mapStateToProps> &
 *  ReturnType<typeof mapDispatchToProps>
 * } ExtraProps
 *
 * @extends {Component<Props & ExtraProps>}
 */
class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      fetching: false,
      searchValue: null,
      selectOpen: false,
    };
    this.lastFetchId = 0;
    this.addOptions = debounce(this._addOptions, 500, { leading: true });
  }

  componentDidUpdate(prevProps) {
    // search with new model if search value is present
    if (prevProps.model !== this.props.model && this.state.searchValue) {
      this.handleSearch(this.state.searchValue);
    }
  }

  /**
   * @param {string} [val]
   * @returns {Promise<{label: string, value: any}[]>}
   */
  fetchData = (val) => {
    const { model } = this.props;
    const searchApi = `${model}.search`;
    const api = get(API, searchApi);

    return new Promise((resolve, reject) => {
      this.setState({ fetching: true, searchValue: val }, () =>
        api({ params: { phrase: val, limit: 10 } })
          .then(({ data }) => {
            const result = data?.result ?? [];
            resolve(result);
          })
          .catch(reject)
          .finally(() => {
            this.setState({ fetching: false });
          }),
      );
    });
  };

  _addOptions = async (phrase) => {
    try {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      const res = await this.fetchData(phrase);
      if (fetchId !== this.lastFetchId) {
        // If older API call finishes later then no need to set result
        return;
      }
      this.setState({ options: res });
    }
    catch (err) {
      logger.error({ err }, 'Form Search Error');
    }
  };

  getOptions = () => {
    const options = uniqBy([...this.state.options].filter(Boolean), (option) => option.value);
    return options;
  };

  onSelect = (val, option) => {
    const { model, label } = option;
    const newSearch = {
      value: val,
      label,
      model,
    };

    // save non recent searches
    this.props.updateRecentSearch(newSearch);

    // setting searchValue to empty which open recent searches by default for next click on search input
    this.setState({ searchValue: null }, () => {
      // redirect to view profile of this item
      this.props.history.push(Links[model].view({ id: val }));
    });
  };

  handleSearch = (phrase) => {
    if (!phrase && this.state.searchValue) {
      this.setState({ searchValue: null });
      this.addOptions.cancel();
      return;
    }
    this.addOptions(phrase);
  };

  onInputKeyDown = async (event) => {
    const { value } = event.target;
    if (event.keyCode === 13) {
      const { model } = this.props;
      this.props.history.push(Links[model].list({ phrase: value }));
    }
  };

  handleDropdown = (open) => {
    this.setState({ selectOpen: open });
    if (!open) this.props.onClose?.();
  };

  render() {
    const { model, recentSearch, dropdownRender } = this.props;
    const { fetching, searchValue, selectOpen } = this.state;
    const options = this.getOptions();
    const optionsLoading = (
      <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <Spin />
      </div>
    );

    return (
      <Select
        value={searchValue}
        showSearch
        // Disable filter as results are already filtered from backend
        filterOption={false}
        allowClear
        suffixIcon={<SearchOutlined />}
        placeholder={selectOpen ? `Search ${model}s` : 'Search Sapphyre'}
        onSelect={this.onSelect}
        onSearch={this.handleSearch}
        dropdownRender={dropdownRender}
        notFoundContent={fetching ? optionsLoading : undefined}
        optionFilterProp="label"
        getPopupContainer={(node) => node.closest('.ant-select')}
        onInputKeyDown={this.onInputKeyDown}
        onDropdownVisibleChange={this.handleDropdown}
        defaultActiveFirstOption={false}
        autoClearSearchValue
      >
        {!searchValue && recentSearch?.length ? (
          <Select.OptGroup label="Recent Searches">
            {recentSearch?.map((op) => (
              <Select.Option
                key={`${op.label}-${op.value}-recent`}
                value={op.value}
                label={op.label}
                model={op.model}
                className="recent"
              >
                <strong>{op.model}</strong> -&nbsp;{op.label}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ) : null}

        {searchValue && options.length ? (
          <Select.OptGroup label={`${model}s`}>
            {options.map((op) => (
              <Select.Option
                key={`${op.label}-${op.value}`}
                value={op.value}
                label={op.label}
                model={model}
              >
                {op.label}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ) : null}
      </Select>
    );
  }
}

CustomSelect.propTypes = {
  model: PropTypes.string.isRequired,
  dropdownRender: PropTypes.func,
};

const mapStateToProps = (state) => ({
  recentSearch: state.globals.recentSearch,
});

const mapDispatchToProps = (dispatch) => ({
  updateRecentSearch: (newSearch) => dispatch(updateRecentSearch(newSearch)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CustomSelect);
