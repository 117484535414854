import API from '../../api';

const fetchUnreadCount = (override = false) => (dispatch, getState) => {
  if (
    !override &&
    Date.now() - new Date(getState().notification.unreadFetchTime || 0).getTime() < 1 * 59 * 1000
  ) {
    return;
  }
  API.Notification.unreadCount().then(({ data }) => {
    dispatch({
      type: 'FETCH_UNREAD_COUNT',
      unreadCount: data.unreadCount,
    });
  });
};

const fetchNotifications = ({ limit, onlyFetchIfNew, initOnly }) => (dispatch, getState) => {
  const stateNotifications = getState().notification.notifications || [];
  if (initOnly && stateNotifications.length) return;
  let notifications;
  if (onlyFetchIfNew) {
    if (getState().notification.lastUnreadCount === getState().notification.unreadCount) {
      return;
    }
    notifications = [];
  }
  else notifications = stateNotifications;
  API.Notification.list(
    { params: { limit: limit + 1, beforeId: notifications.slice(-1)[0]?._id } },
    { errorNotification: true },
  )
    .then(({ data }) => {
      dispatch({
        type: 'UPDATE_NOTIFICATIONS',
        notifications: [...notifications, ...data.items.slice(0, limit)],
        allowLoadMore: data.items.length === limit + 1,
        lastUnreadCount: getState().notification.unreadCount,
      });
    })
    .catch(() => {});
};

const markRead = (dispatch) =>
  API.Notification.markRead().then(() => {
    dispatch({
      type: 'SET_ALL_READ',
    });
  });

export { fetchUnreadCount, fetchNotifications, markRead };
