/* eslint-disable max-len */
import React, { lazy } from 'react';
import { faChartBar, faImages, faTable, faTag } from '@fortawesome/free-solid-svg-icons';
import { FaIcon, ErrorPage } from 'components';

const UserProfile = lazy(() =>
  import(/* webpackChunkName: "advertiser-profile" */ 'views/advertiser/User/Profile'),
);

const ProgrammaticStats = lazy(() =>
  import(/* webpackChunkName: "programmatic-stats" */ 'views/advertiser/Stats/ProgrammaticStats'),
);

const ListOfferStats = lazy(() =>
  import(/* webpackChunkName: "programmatic-ListOffers" */ 'views/advertiser/Offer/ListOfferStats'),
);

const ViewOfferStats = lazy(() =>
  import(/* webpackChunkName: "programmatic-ViewOffer" */ 'views/advertiser/Offer/ViewOfferStats'),
);

const ListCreativeStats = lazy(() =>
  import(
    /* webpackChunkName: "programmatic-ListCreatives" */ 'views/advertiser/Creative/ListCreativeStats'
  ),
);

const ViewCreativeStats = lazy(() =>
  import(
    /* webpackChunkName: "programmatic-ViewCreative" */ 'views/advertiser/Creative/ViewCreativeStats'
  ),
);

const AdvertiserDashboard = lazy(() =>
  import(/* webpackChunkName: "advertiser-Dashboard" */ 'views/advertiser/Dashboard/ViewDashboard'),
);

// const ProgrammaticExport = lazy(() =>
//   import(/* webpackChunkName: "programmatic-Export" */ 'views/programmatic/Export/Export'),
// );

/**
 * @type {import('./index').RouteItem[]}
 */
const routes = [
  {
    name: 'Dashboard',
    component: AdvertiserDashboard,
    base: '/advertiser/:aid/programmatic',
    path: '/dashboard',
    icon: <FaIcon icon={faChartBar} />,
    showTitle: false,
  },
  {
    name: 'Report',
    path: '/advertiser/:aid/programmatic/stats/custom',
    icon: <FaIcon icon={faTable} />,
    component: ProgrammaticStats,
  },
  {
    name: 'Campaigns',
    icon: <FaIcon icon={faTag} />,
    component: ListOfferStats,
    path: '/advertiser/:aid/programmatic/offers/all',
  },
  {
    name: 'Offer Stats',
    component: ViewOfferStats,
    path: '/advertiser/:aid/programmatic/offer/view/:id',
    invisible: true,
  },
  {
    name: 'Creatives',
    icon: <FaIcon icon={faImages} />,
    path: '/advertiser/:aid/programmatic/creatives',
    component: ListCreativeStats,
  },
  {
    name: 'Creative Stats',
    component: ViewCreativeStats,
    path: '/advertiser/:aid/programmatic/creative/view/:id',
    invisible: true,
  },
  {
    name: 'Profile',
    component: UserProfile,
    base: '/advertiser/:aid',
    path: '/profile',
    invisible: true,
  },
  // {
  //   name: 'Export',
  //   component: AffiliateExport,
  //   base: '/affiliate/:pid',
  //   path: '/export',
  //   icon: <FaIcon icon={faFileExport} />,
  // },
  {
    name: 'Page Not Found',
    component: ErrorPage.NotFound,
    base: '/programmatic/:aid',
    path: '/404',
    invisible: true,
  },
];

export default routes;
