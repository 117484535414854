import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject, isNil, isEqual } from 'lodash';
import { Form, Select } from 'antd';
import { PropTypePresets, Formatter } from 'utils';
import { transformRules, noWhitespaces } from 'utils/rules';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';

/**
 * @typedef {import('prop-types').InferProps<typeof FormSelect.propTypes> & {
 *  formItemProps: Partial<import('antd/lib/form').FormItemProps>,
 * }} Props
 *
 * @extends {Component<Props>}
 */
class FormSelect extends Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);

    let type = 'array';
    if (props.mode === 'default') {
      type = typeof (props.options[0] || '');
      if (type === 'object') {
        type = typeof (props.options[0].value || '');
      }
    }
    const rules = props.mode === 'tags' ? [...props.rules, noWhitespaces] : props.rules;
    this.rules = transformRules(rules, { type });
    this.isRequired = this.rules.some((rule) => rule.required);

    this.state = {
      options: [],
    };
  }

  static getDerivedStateFromProps({ options, capitalizeOptions }, state) {
    if (!options.length) return null;
    if (!isNil(options[0]) && !isPlainObject(options[0])) {
      options = Formatter.makeOptions(options, capitalizeOptions);
    }
    if (!isEqual(options, state.options)) return { options };
    return null;
  }

  handleInputKeyDown = (e) => {
    // Enter (Don't delete existing value on double enter)
    if (e.keyCode === 13 && !e.target.value) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  getPopupContainer = (node) => node.closest('.ant-card') || node.closest('.ant-form-item');

  render() {
    const {
      label,
      name,
      mode,
      formItemProps,
      placeholder,
      disabled,
      selectProps,
      tooltip,
      help,
      initialValue,
    } = this.props;

    const { options } = this.state;

    return (
      <Form.Item
        hasFeedback={!!this.rules.length}
        name={name}
        label={label}
        rules={this.rules}
        tooltip={InfoTooltip.Config(tooltip)}
        extra={<Help text={help} />}
        initialValue={initialValue}
        validateFirst
        {...formItemProps}
      >
        <Select
          allowClear={!this.isRequired}
          showSearch
          mode={mode}
          tokenSeparators={[',']}
          options={options}
          placeholder={placeholder || label}
          disabled={disabled}
          optionFilterProp="label"
          dropdownStyle={mode === 'tags' && !options.length ? { display: 'none' } : undefined}
          onInputKeyDown={mode === 'tags' ? this.handleInputKeyDown : undefined}
          getPopupContainer={this.getPopupContainer}
          {...selectProps}
        />
      </Form.Item>
    );
  }
}

FormSelect.defaultProps = {
  placeholder: '',
  disabled: false,
  rules: [],
  mode: 'default',
  options: [],
  capitalizeOptions: true,
  selectProps: {},
  formItemProps: {},
};

FormSelect.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypePresets.tooltip,
  help: PropTypePresets.help,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  mode: PropTypes.oneOf(['default', 'multiple', 'tags']),
  options: PropTypePresets.options,
  capitalizeOptions: PropTypes.bool,
  selectProps: PropTypes.object,
  formItemProps: PropTypes.object,
  initialValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default FormSelect;
