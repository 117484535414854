import React, { Suspense } from 'react';
import { castArray } from 'lodash';
import './styles.less';
import { Layout, Typography } from 'antd';
import { Breadcrumbs, ErrorPage, Loading, Navbar, Link } from 'components';
import { MapRoutes } from 'routes';
import { Analytics } from 'utils';
import Sider from './Sider';
import CacheBuster from './CacheBuster';

class Main extends React.PureComponent {
  /**
   * @param {Parameters<Parameters<typeof MapRoutes>[0]['render']>[0]} props
   */
  getRouteComponent = (props) => {
    const { route } = props;
    // eslint-disable-next-line no-underscore-dangle
    let Component = route._component;
    if (!Component) {
      Component = ErrorPage.withErrorPages(route.component);
      // eslint-disable-next-line no-underscore-dangle
      route._component = Component;
    }

    const { key, parent, routeProps } = props;
    const defaultPath = castArray(route.path)[0];
    Analytics.pageview(defaultPath, null, route.name);
    const help = route.help ? (
      <Typography.Text className="sub-title" type="secondary">
        Know more:&nbsp;
        <Link helpDoc to={route.help.link}>
          {route.help.text}
        </Link>
      </Typography.Text>
    ) : null;

    return (
      <>
        <div className="title-bar">
          <CacheBuster />
          <Breadcrumbs {...{ route, key, parent }} />
          {route.showTitle === false ? null : (
            <>
              <Typography.Title className="title" level={3}>
                {route.name}
              </Typography.Title>
              {route.buttons ? (
                <div className="title-btns">
                  {route.buttons.map((ButtonComponent, index) => (
                    <ButtonComponent key={`${defaultPath}-${index}`} {...routeProps} />
                  ))}
                </div>
              ) : null}
              <br />
              {help}
            </>
          )}
        </div>
        <Layout.Content>
          <Component route={route} {...routeProps} />
        </Layout.Content>
      </>
    );
  };

  render() {
    const { routes } = this.props;

    return (
      <>
        <Layout>
          <Sider routes={routes} />

          <Layout className="main">
            <Layout.Header className="navbar">
              <Navbar />
            </Layout.Header>
            <Suspense fallback={<Loading size="large" />}>
              <MapRoutes
                routes={routes}
                render={this.getRouteComponent}
                default={() => <ErrorPage.NotFound />}
              />
            </Suspense>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default Main;
