/* eslint-disable max-len */
import { lazy } from 'react';

const PartnerSignup = lazy(() =>
  import(/* webpackChunkName: "auth-PartnerSignup" */ 'views/base/PendingPartner/PartnerSignup'),
);
const LoginPage = lazy(() =>
  import(/* webpackChunkName: "auth-LoginPage" */ 'views/auth/LoginPage'),
);
const ForgotPasswordPage = lazy(() =>
  import(/* webpackChunkName: "auth-ForgotPasswordPage" */ 'views/auth/ForgotPasswordPage'),
);
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "auth-ResetPassword" */ 'views/auth/ResetPassword'),
);

/**
 * @type {import('./index').RouteItemChild[]}
 */
const routes = [
  {
    name: 'Login',
    component: LoginPage,
    path: '/login',
  },
  {
    name: 'Forgot Password',
    component: ForgotPasswordPage,
    path: '/forgot',
  },
  {
    name: 'Reset Password',
    component: ResetPassword,
    path: [
      '/reset-password/:token',
      // NOTE: For backwards compatible links, remove later
      '/resetPassword/:token',
    ],
  },
  {
    name: 'Partner Signup',
    component: PartnerSignup,
    path: [
      '/partner-signup',
      // NOTE: For backwards compatible links
      '/partner_signup',
    ],
  },
];

export default routes;
