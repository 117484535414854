import React from 'react';
import { Button as AntButton } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import Link from 'components/Link';

/**
 * @typedef {{
 *  href?: import('react-router-dom').LinkProps['to'];
 * } & Omit<import('antd/lib/button').ButtonProps, 'href'>} ButtonProps
 *
 * @param {ButtonProps} props
 */
function Button(props) {
  const { href, ...rest } = props;
  if (href) {
    return (
      <Link to={href} className="link-ant-btn">
        <AntButton {...rest} />
      </Link>
    );
  }
  return <AntButton {...rest} />;
}

Button.Edit = (props) => (
  <Button className="edit-button" type="primary" icon={<EditOutlined />} {...props} />
);

Button.View = (props) => (
  <Button className="view-button" type="primary" icon={<EyeOutlined />} {...props} />
);

export default Button;
