import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import localforage from 'localforage';
import globalReducer from './globalReducer';
import authReducer from './authReducer';
import apiReducer from './apiReducer';
import notificationReducer from './notificationReducer';

const storage = localforage.createInstance({
  name: 'sapphire_store', // Should be alphanumeric, with underscores.
  version: 1,
});

/**
 * @type {import('redux-persist').PersistConfig}
 */
const authPersistConfig = {
  key: 'auth',
  storage,
  version: 1,
  migrate: createMigrate({
    // @ts-ignore
    1: () => ({}),
  }),
};

/**
 * @type {import('redux-persist').PersistConfig}
 */
const globalPersistConfig = {
  key: 'globals',
  storage,
  version: 0,
  whitelist: ['helpTextVisible', 'recentSearch', 'dashboardId'],
};

/**
 * @type {import('redux-persist').PersistConfig}
 */
const notificationPersistConfig = {
  key: 'notification',
  storage,
  version: 1,
};

// key will be data property on state obj
const rootReducer = combineReducers({
  apiCache: apiReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  globals: persistReducer(globalPersistConfig, globalReducer),
  notification: persistReducer(notificationPersistConfig, notificationReducer),
});

export default rootReducer;
