import React from 'react';
import { withRouter } from 'react-router-dom';

/**
 * @see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-528871868
 * @param {typeof React.Component} Component
 */
const withRouterAndRef = (Component) => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Component ref={forwardRef} {...otherProps} />
  ));
  const WithRouterAndRef = React.forwardRef((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ));

  const name = Component.displayName || Component.name;
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`;
  return WithRouterAndRef;
};

export default withRouterAndRef;
