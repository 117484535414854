import { endsWith } from 'lodash';
import * as Formatter from './formatter';
import PropTypePresets from './propTypePresets';
import hasPermission from './permissions';
import logger from './logger';
import Analytics from './analytics';

const domain = window.location.host;
let team = process.env.REACT_APP_USE_DEFAULT_TEAM;
if (!team || endsWith(domain, '.sapphyre.app')) {
  [team] = domain.split('.');
}

const Team = team;

export { logger, Formatter, hasPermission, PropTypePresets, Team, Analytics };
