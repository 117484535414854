import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons';

/**
 * @param {import('@fortawesome/react-fontawesome').FontAwesomeIconProps & {
 *  iconStyle?: React.CSSProperties,
 * }} props
 */
export default ({ iconStyle, ...rest }) => (
  <Icon
    component={() => <FontAwesomeIcon {...rest} />}
    style={{ verticalAlign: 0, ...iconStyle }}
  />
);
