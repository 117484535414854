/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { startCase, isArray, isString, lowerCase } from 'lodash';
import dayjs from 'dayjs';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Space } from 'antd';
import FaIcon from 'components/FaIcon';
import Cell from 'components/Cell';

/**
 *
 * @param {string} name
 * splits the name on whitespaces, - or _, capitalizes the first character (remains same if its a special char),
 * rejoins them with whitespace again and returns the custom name
 */
export function startCaseWithSpecialChars(name) {
  return name
    ?.replaceAll(/[-_]/g, ' ')
    .split(' ')
    .map((word) => (word[0]?.toUpperCase() ?? '') + word.slice(1))
    .join(' ')
    .trim();
}

/**
 * @param {string[] | {[key: string]: string}} arr
 * @param {boolean} capitalize
 * @returns {{value: string, label: string}[]}
 */
export const makeOptions = (arr, capitalize = false) => {
  if (!arr) return [];
  if (isArray(arr)) {
    return arr.map((data) => ({
      value: data,
      label: capitalize && isString(data) ? startCase(lowerCase(data)) : data,
    }));
  }
  const keys = Object.keys(arr);
  return keys.map((key) => ({
    value: key,
    label: capitalize && isString(arr[key]) ? startCase(lowerCase(arr[key])) : arr[key],
  }));
};

export const makeMap = (arr) => {
  if (!arr) return {};

  const obj = {};
  arr.forEach((item) => {
    obj[item.value] = item.label;
  });
  return obj;
};

export const dateTime = (str) => str && dayjs(str).format('YYYY-MM-DD HH:mm:ss');

export const DateTimeCell = (str) => {
  if (!str) return null;
  const time = dayjs(str);
  return <Cell title={time.format('YYYY-MM-DD')} subTitle={time.format('HH:mm:ss')} />;
};

export function getUserLocale() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

/**
 * @param {number | '-'} countNum
 */
export function count(countNum) {
  if (!countNum) return 0;
  if (countNum === '-') return countNum;
  const locale = getUserLocale();
  if (Number.isInteger(countNum) || !countNum.toFixed) {
    return new Intl.NumberFormat(locale).format(countNum);
  }
  return new Intl.NumberFormat(locale).format(Number(countNum.toFixed(2)));
}

/**
 * @param {string[]} priorities
 */
export const getPriorityOptions = (priorities) => {
  /**
   * @see https://next.ant.design/components/tag/#components-tag-demo-colorful
   */
  const colorMap = {
    HIGHEST: { tagColor: 'red', icon: <FaIcon icon={faArrowUp} color="#ce0000" /> },
    HIGH: { tagColor: 'volcano', icon: <FaIcon icon={faArrowUp} color="#f5222d" /> },
    MEDIUM: { tagColor: 'orange', icon: <FaIcon icon={faArrowUp} color="#fa8c16" /> },
    LOW: { tagColor: 'green', icon: <FaIcon icon={faArrowDown} color="#52c41a" /> },
    LOWEST: { tagColor: 'lime', icon: <FaIcon icon={faArrowDown} color="#a0d911" /> },
  };

  return priorities.map((priority, i) => ({
    label: (
      <Space>
        {colorMap[priority].icon}
        <span>{startCase(priority)}</span>
      </Space>
    ),
    icon: colorMap[priority].icon,
    labelText: priority,
    value: priorities.length - i - 1,
    color: colorMap[priority].tagColor,
  }));
};

/**
 * @param {boolean} short
 */
export function getMonthString(short = false) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (short) return months.map((month) => month.slice(0, 3));
  return months;
}

export default {
  startCaseWithSpecialChars,
  DateTimeCell,
  makeOptions,
  makeMap,
  dateTime,
  count,
  getPriorityOptions,
  getMonthString,
  getUserLocale,
};
