/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import toObject from 'dayjs/plugin/toObject';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import './styles.less';
import App from 'App';
import { logger } from 'utils';
import { Loading } from 'components';
import ErrorMessages from 'constants/ErrorMessages';
import { store, persistor } from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Enable dayjs plugins
dayjs.extend(utc);
dayjs.extend(toObject);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

if (process.env.NODE_ENV === 'development') {
  // TODO: Remove this, use https://redux-toolkit.js.org and Redux DevTools
  store.subscribe(() => {
    logger.debug({ label: 'Store', state: store.getState() });
  });

  // @ts-ignore
  window.dayjs = dayjs;
  // @ts-ignore
  window.logger = logger;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading size="large" />} persistor={persistor}>
      <ConfigProvider form={{ validateMessages: ErrorMessages }}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
