import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';
import { DateTimeParam, JsonParam, StringParam } from 'use-query-params';
import { Form, Button, Row, Col, Card, Space } from 'antd';
import { CheckOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import FormActions from 'components/FormActions';
import withQueryParamsAndRef from 'components/withQueryParamsAndRef';
import withRouterAndRef from 'components/withRouterAndRef';
import { PropTypePresets } from 'utils';
import filterTypes from './types';
import { convertToQP, convertFromQP } from './utils';

/**
 * @typedef {import('prop-types').InferProps<typeof Filters.propTypes>} FilterProps
 * @typedef {UseQP<typeof queryParamsToProps>} QueryParamProps
 *
 * @typedef {FilterProps & QueryParamProps &
 *  import('react-router-dom').RouteComponentProps
 * } Props
 *
 * @extends {React.Component<Props>}
 */
class Filters extends React.Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    if (
      process.env.NODE_ENV === 'development' &&
      props.onValidExport &&
      !props.filtersMap?.export
    ) {
      throw new Error(
        '"onValidExport" isn\'t allowed without also setting export in "filterTypes"',
      );
    }

    this.state = {
      initialValues: this.getInitialValues(),
      expand: false,
    };

    /** @type {import('react').RefObject<import('antd/lib/form').FormInstance>} */
    this.formRef = React.createRef();
    this.count = 0;
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((obj, action) => {
      if (action === 'POP') {
        // Resets fields according to URL query params
        this.handleReset({ fromHistory: true });
      }
    });
    if (this.props.autoSubmit || this.props.location?.state?.autoSubmit) {
      this.autoSubmitted = true;
      this.formRef.current.submit();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      !isEqual(prevProps.query, this.props.query) &&
      // @ts-ignore
      !this.props.location.query
    ) {
      // Resets fields according to URL query params when query params are changed from outside component
      this.handleReset({ fromHistory: true });
    }
  };

  componentWillUnmount() {
    this.unlisten?.();
  }

  /**
   * Considers values from url and default values to generate initial values for form
   */
  // @ts-ignore
  getInitialValues = (query = this.props.query) => convertFromQP(query, this.props.filtersMap);

  handleReset = ({ fromHistory = false } = {}) => {
    this.setState(
      {
        // @ts-ignore
        initialValues: this.getInitialValues(fromHistory ? this.props.query : {}),
      },
      () => {
        if (fromHistory) this.autoSubmitted = true;
        this.formRef.current.resetFields();
        if (this.props.onReset && !fromHistory) this.props.onReset();
        this.formRef.current.submit();
      },
    );
  };

  handleSubmit = (data) => {
    const { query, setQuery, updateQuery, onValidSubmit } = this.props;
    const { apiParams, apiHeaders } = convertToQP(setQuery, data, query, this.props.filtersMap, {
      updateQuery,
      defaults: this.autoSubmitted,
    });
    onValidSubmit(apiParams, apiHeaders);
  };

  // Subbmit or Export failed
  handleFinishFailed = ({ errorFields }) => {
    if (!errorFields?.[0]?.name) return;
    this.formRef.current.scrollToField(errorFields[0].name);
  };

  handleExport = () =>
    this.formRef.current
      .validateFields()
      .then((data) => {
        const { apiParams, apiHeaders } = convertToQP(
          this.props.setQuery,
          data,
          this.props.query,
          this.props.filtersMap,
        );
        apiParams.export = true;
        this.props.onValidExport(apiParams, apiHeaders);
      })
      .catch(this.handleFinishFailed);

  /**
   * @param {string} type
   * @param {React.FunctionComponent} render Render function
   * @param {number} [key]
   */
  renderConditionally = (type, render, key = 0) => {
    let props = this.props.filtersMap[type];
    // date visibility is true by default
    if (props === true || (type === 'date' && props === undefined)) {
      props = {};
    }
    if (!props || (!this.state.expand && this.count > 5 && type !== 'export')) {
      return null;
    }

    if (type !== 'export') this.count += 1;

    const defaultValKey =
      type === 'date' || type === 'timeRange' ? 'dateRange' : ['filters', props.name || type];
    props.defaultVal = get(this.state.initialValues, defaultValKey);

    return render({ ...props, formRef: this.formRef, key });
  };

  render() {
    if (!this.state.initialValues) return null;
    this.count = 0;

    return (
      <Card className="filters" {...this.props.cardProps}>
        <Form
          className="filter-form"
          layout="vertical"
          onFinish={this.handleSubmit}
          onFinishFailed={this.handleFinishFailed}
          initialValues={this.state.initialValues}
          ref={this.formRef}
        >
          <Row gutter={20}>
            {Object.entries(filterTypes).map(([name, { component }], index) =>
              this.renderConditionally(name, component, index),
            )}
          </Row>

          <Row>
            <Col span={24}>
              <Space size="middle">
                <FormActions
                  submitText="View"
                  submitting={this.props.loading}
                  onReset={this.handleReset}
                  submitIcon={<CheckOutlined />}
                  {...this.props.actionProps}
                >
                  {this.renderConditionally('export', () => (
                    <Button
                      onClick={this.handleExport}
                      type="default"
                      size="large"
                      disabled={this.props.loading}
                    >
                      Export
                    </Button>
                  ))}
                </FormActions>
                {this.count > 5 ? (
                  <Button
                    size="large"
                    style={{ fontSize: 16 }}
                    icon={this.state.expand ? <UpOutlined /> : <DownOutlined />}
                    onClick={() => {
                      this.setState((prevVal) => ({
                        expand: !prevVal.expand,
                      }));
                    }}
                  >
                    {this.state.expand ? 'Less Filters' : 'More Filters'}
                  </Button>
                ) : null}
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

Filters.defaultProps = {
  loading: false,
  filtersMap: {},
  autoSubmit: true,
  cardProps: {},
  updateQuery: true,
};

Filters.propTypes = {
  loading: PropTypes.bool,
  autoSubmit: PropTypes.bool,
  filtersMap: PropTypePresets.filtersMap,
  onValidSubmit: PropTypes.func,
  onValidExport: PropTypes.func,
  onReset: PropTypes.func,
  cardProps: PropTypes.object,
  actionProps: PropTypes.object,
  updateQuery: PropTypes.bool,
};

export const queryParamsToProps = {
  fromDate: DateTimeParam,
  toDate: DateTimeParam,
  timezone: StringParam,
  phrase: StringParam,
  filters: JsonParam,
};

// @ts-ignore
export default withQueryParamsAndRef(queryParamsToProps, withRouterAndRef(Filters));
