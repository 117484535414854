import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { PropTypePresets } from 'utils';
import { noWhitespace } from 'utils/rules';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';

/**
 * @typedef {import('prop-types').InferProps<typeof FormInput.propTypes>} Props
 *
 * @extends {Component<Props>}
 */
class FormInput extends Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    this.rules = [...props.rules, noWhitespace];
  }

  renderType(type, placeholder) {
    switch (type) {
      case 'textarea':
        return (
          <Input.TextArea
            placeholder={placeholder}
            disabled={this.props.disabled}
            autoSize={{ maxRows: 4 }}
            {...this.props.inputProps}
          />
        );

      case 'url':
        return (
          <Input
            placeholder={placeholder}
            disabled={this.props.disabled}
            type="url"
            {...this.props.inputProps}
          />
        );

      case 'email':
        return (
          <Input
            placeholder={placeholder}
            disabled={this.props.disabled}
            prefix={<MailOutlined />}
            autoComplete="email"
            {...this.props.inputProps}
          />
        );

      case 'password':
        return (
          <Input.Password
            placeholder={placeholder}
            prefix={<LockOutlined />}
            type="password"
            autoComplete="current-password"
            visibilityToggle
            {...this.props.inputProps}
          />
        );

      default:
        return (
          <Input
            placeholder={placeholder}
            disabled={this.props.disabled}
            {...this.props.inputProps}
          />
        );
    }
  }

  render() {
    const { name, label, placeholder, type, formItemProps, tooltip, help } = this.props;

    const placeholderText = placeholder || label;

    return (
      <Form.Item
        hasFeedback={!!this.rules.length}
        name={name}
        label={label}
        rules={this.rules}
        tooltip={InfoTooltip.Config(tooltip)}
        extra={<Help text={help} />}
        validateFirst
        {...formItemProps}
      >
        {this.renderType(type, placeholderText)}
      </Form.Item>
    );
  }
}

FormInput.defaultProps = {
  placeholder: '',
  type: 'text',
  disabled: false,
  rules: [],
  inputProps: {},
  formItemProps: {},
};

FormInput.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypePresets.tooltip,
  help: PropTypePresets.help,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'textarea', 'url', 'email', 'password']),
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  inputProps: PropTypes.object,
  formItemProps: PropTypes.object,
};

export default FormInput;
