/**
 * This file contains actions that don't require to perform async tasks
 * Just created to avoid circular dependancy.
 */

const logoutAction = { type: 'LOGOUT' };

const updateAccessToken = (token) => (dispatch) => {
  if (!token) return;

  dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: token });
};

export { logoutAction, updateAccessToken };
