import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import { Checkbox, Divider, Row, Col, Tooltip } from 'antd';
import { PropTypePresets } from 'utils';

import './styles.less';

/**
 * @typedef {import('prop-types').InferProps<typeof MultiCheckbox.propTypes>} Props
 *
 * @extends {Component<Props>}
 */
class MultiCheckbox extends Component {
  constructor(props) {
    super(props);
    const { initialValues, options } = this.props;
    this.state = {
      selectAll: initialValues.length === options.length,
      checkedList: initialValues,
      selectAllDisabled: false,
    };
  }

  static getDerivedStateFromProps(props) {
    if (some(props.options, (option) => option.disabled)) {
      return { selectAllDisabled: true };
    }
    return { selectAllDisabled: false };
  }

  getColumns = ({ label, value, ...rest }) => {
    const { colSpan } = this.props;

    if (rest.tooltipMsg) {
      return (
        <Col key={value} span={colSpan} md={colSpan} xs={24}>
          <Tooltip title={rest.tooltipMsg} placement="top">
            <Checkbox value={value} style={{ margin: '5px 0' }} {...rest}>
              {label}
            </Checkbox>
          </Tooltip>
        </Col>
      );
    }
    return (
      <Col key={value} span={colSpan} md={colSpan} xs={24}>
        <Checkbox value={value} style={{ margin: '5px 0' }} {...rest}>
          {label}
        </Checkbox>
      </Col>
    );
  };

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleChange = (checkedList) => {
    this.triggerChange(checkedList);
    this.setState({
      checkedList,
      selectAll: checkedList.length === this.props.options.length,
    });
  };

  handleSelectAll = (e) => {
    const { checked } = e.target;
    const { options } = this.props;

    const checkedList = checked ? options.map((op) => op.value) : [];
    this.triggerChange(checkedList);
    this.setState({
      checkedList,
      selectAll: checked,
    });
  };

  render() {
    const {
      showSelectAll,
      divider,
      disabled,
      options,
      groupProps,
      // Here just so they don't overwrite props through rest
      onChange,
      colSpan,
      initialValues,
      // Needs this to forward formItem children props to group
      ...rest
    } = this.props;
    const { selectAll, checkedList, selectAllDisabled } = this.state;

    return (
      <>
        {showSelectAll && (
          <>
            <Checkbox
              disabled={disabled || selectAllDisabled}
              onChange={this.handleSelectAll}
              checked={selectAll}
              className="multi-select-all"
            >
              {typeof showSelectAll === 'boolean' ? 'Select All' : showSelectAll}
            </Checkbox>
            {divider ? <Divider style={{ margin: '15px 0' }} /> : null}
          </>
        )}

        <Checkbox.Group
          disabled={disabled}
          onChange={this.handleChange}
          value={checkedList}
          style={{ width: '100%' }}
          {...rest}
          {...groupProps}
        >
          <Row>{options.map((option) => this.getColumns(option))}</Row>
        </Checkbox.Group>
      </>
    );
  }
}

MultiCheckbox.defaultProps = {
  showSelectAll: false,
  disabled: false,
  initialValues: [],
  divider: true,
  colSpan: 6,
  groupProps: {},
};

MultiCheckbox.propTypes = {
  showSelectAll: PropTypePresets.showSelectAll,
  disabled: PropTypes.bool,
  initialValues: PropTypePresets.arrayOfStringAndNumber,
  divider: PropTypes.bool,
  onChange: PropTypes.func,
  colSpan: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired, // [{label, value}]
  groupProps: PropTypes.object,
};

export default MultiCheckbox;
