import React from 'react';
import { groupBy, mapValues, startCase } from 'lodash';
import dayjs from 'dayjs';
import { UserOutlined } from '@ant-design/icons';
import { faQuestionCircle, faIdCard, faHandPaper } from '@fortawesome/free-regular-svg-icons';
import { getLinkedItemLink, LinkTo } from 'links';
import FaIcon from 'components/FaIcon';

const formattedTitle = (title, createdAt) => (
  <div>
    <span>{title}</span>
    <span className="notifications date">{dayjs(createdAt).format('MMM DD, YYYY hh:mm A')}</span>
  </div>
);

const formattedDescription = (description) => (
  <span style={{ paddingTop: '8px' }}>{description}</span>
);

/**
 *
 * @param {{
 *  type: string,
 *  linkedItems: {id: string|number, model: string, item: any}[],
 *  createdAt: string,
 *  status: 'READ' | 'UNREAD'
 * }} notification
 */
function formatNotification(notification) {
  let avatarIcon = <FaIcon icon={faHandPaper} />;
  let title;
  let description;
  const groupedLinkedItems = groupBy(notification.linkedItems, (linkedItem) => linkedItem.model);
  const linkedItemLinks = mapValues(groupedLinkedItems, (linkedItems) =>
    linkedItems?.map(getLinkedItemLink),
  );
  switch (notification.type) {
    case 'NEW_USER':
      title = 'New user';
      description = (
        <span>
          {linkedItemLinks.User[0]}
          &nbsp;has joined the team!
        </span>
      );
      avatarIcon = <UserOutlined />;
      break;

    case 'NEW_PENDING_PARTNER':
      title = 'New pending partner';
      // description = // TODO: add this when the functionality is implemented in backend
      avatarIcon = <FaIcon icon={faIdCard} />;
      break;

    case 'CAP_OFFER_DAILY':
      title = 'Offer daily cap';
      description = (
        <span>
          {linkedItemLinks.Offer[0]}
          &nbsp;is almost capped
        </span>
      );
      break;

    case 'CAP_OFFER_MONTHLY':
      title = 'Offer monthly cap';
      description = (
        <span>
          {linkedItemLinks.Offer[0]}
          &nbsp;is almost capped
        </span>
      );
      break;

    case 'CAP_PARTNER_DAILY':
      title = 'Partner daily cap';
      description = (
        <span>
          {linkedItemLinks.Offer[0]}
          &nbsp;is almost capped for partner&nbsp;
          {linkedItemLinks.Partner[0]}
        </span>
      );
      break;

    case 'CAP_PARTNER_MONTHLY':
      title = 'Partner monthly cap';
      description = (
        <span>
          {linkedItemLinks.Offer[0]}
          &nbsp;is almost capped for partner&nbsp;
          {linkedItemLinks.Partner[0]}
        </span>
      );
      break;

    case 'CR_AUTOMATION_BLOCK_PARTNER':
      title = 'Partner Disabled';
      description = (
        <span>
          Partner&nbsp;
          {linkedItemLinks.Partner[0]}
          &nbsp;has been disabled for offer&nbsp;
          {linkedItemLinks.Offer[0]}
          &nbsp;by Automation rule:&nbsp;
          {LinkTo.CrAutomation.list({}, linkedItemLinks.CrAutomation[0])}
        </span>
      );
      break;

    default:
      avatarIcon = <FaIcon icon={faQuestionCircle} />;
      title = startCase(notification.type);
      description = Object.entries(linkedItemLinks)
        .map(([model, links]) => `${model}: ${links.join(',')}`)
        .join('\n');
  }

  return {
    avatarIcon,
    title: formattedTitle(title, notification.createdAt),
    description: formattedDescription(description),
  };
}

export {
  // Remove prefer-default if multiple exports are added
  // eslint-disable-next-line import/prefer-default-export
  formatNotification,
};
