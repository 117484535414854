import { defaultsDeep } from 'lodash';
import logger from 'utils/logger';
import { store } from '../store';
import { setCacheData } from '../store/actions/apiActions';
import axios from './axios';
import { basicErrorHandler, basicSuccessHandler, setFormErrors } from './handlers';

function getHashedObject(options) {
  return btoa(JSON.stringify(options));
}

/**
 * @template {any | void} T
 * @typedef {(
 *  axiosOpts?: import('axios').AxiosRequestConfig & {input?: T},
 *  opts?: {
 *     cancelToken?: import('axios').CancelToken,
 *     notification?: boolean | string,
 *     successNotification?: boolean | string,
 *     errorNotification?: boolean | string,
 *     formRef?: React.RefObject<import('antd/lib/form').FormInstance>
 *     onError?: (err: import('axios').AxiosError) => void
 * }) => ReturnType<axios>} ApiFunction
 * */

/**
 * @template {any | void} T
 * @param {{
 *  method: import('axios').Method,
 *  url: (input: T) => string,
 *  params?: any,
 *  cacheable?: boolean | number,
 *  debug?: boolean,
 * } & Partial<Omit<import('axios').AxiosRequestConfig, 'url' | 'method' | 'params'>>} apiObject
 * @returns {ApiFunction<T>}
 */
function mapApiToRunner(apiObject) {
  return async (axiosOptions = {}, opts = {}) => {
    const { input } = axiosOptions;
    const {
      notification,
      successNotification = notification,
      errorNotification = notification,
      onError,
      formRef,
      cancelToken,
    } = opts;
    const { cacheable, url, method, params, debug, ...rest } = apiObject;
    if (params) {
      axiosOptions.params = defaultsDeep(axiosOptions.params || {}, params);
    }

    /**
     * @type {import('axios').AxiosRequestConfig}
     */
    const finalOpts = {
      ...rest,
      ...axiosOptions,
      url: url(input),
      method,
    };

    if (process.env.NODE_ENV === 'development' && debug) {
      logger.debug('API Options:', finalOpts, opts);
    }

    if (cacheable) {
      const { apiCache } = store.getState();
      const optsHash = getHashedObject(finalOpts);
      if (apiCache && optsHash in apiCache) {
        const res = apiCache[optsHash].response;
        if (typeof cacheable !== 'number') {
          return res;
        }
        if (new Date().getTime() < new Date(apiCache[optsHash].timestamp).getTime() + cacheable) {
          return res;
        }
      }
    }

    if (cancelToken) {
      finalOpts.cancelToken = cancelToken;
    }

    try {
      const response = await axios(finalOpts);

      if (cacheable) {
        store.dispatch(setCacheData(getHashedObject(finalOpts), response));
      }
      if (successNotification) {
        basicSuccessHandler(response, { notification: successNotification });
      }
      return response;
    }
    catch (err) {
      if (onError) {
        onError(err);
      }
      if (formRef) {
        setFormErrors(err, formRef);
      }
      if (errorNotification) {
        basicErrorHandler(err, { notification: errorNotification });
      }
      // TODO: Could turn this off with an option for calls that don't require an error state
      // Rethrow to consume by API callers in Components
      throw err;
    }
  };
}

const API = {
  Dashboard: {
    list: mapApiToRunner({
      method: 'get',
      url: () => '/dashboards',
    }),
    get: mapApiToRunner({
      method: 'get',
      url: ({ id }) => `/dashboards/${id}`,
    }),
    create: mapApiToRunner({
      method: 'post',
      url: () => '/dashboards',
    }),
    update: mapApiToRunner({
      method: 'put',
      url: ({ id }) => `/dashboards/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/dashboards/search',
    }),
  },
  Auth: {
    login: mapApiToRunner({
      method: 'post',
      url: () => '/auth/login',
    }),
    logout: mapApiToRunner({
      method: 'get',
      url: () => '/auth/logout',
    }),
    forgotPassword: mapApiToRunner({
      method: 'post',
      url: () => '/auth/forgot',
    }),
    validateResetToken: mapApiToRunner({
      method: 'get',
      /**
       * @param {{token: string}} param0
       */
      url: ({ token }) => `/auth/resetPassword/${token}`,
    }),
    resetPassword: mapApiToRunner({
      method: 'post',
      /**
       * @param {{token: string}} param0
       */
      url: ({ token }) => `/auth/resetPassword/${token}`,
    }),
    partnerSignup: mapApiToRunner({
      method: 'post',
      url: () => '/partnerSignup',
    }),
    virtualLogin: mapApiToRunner({
      method: 'post',
      url: () => '/auth/virtual-login',
    }),
    virtualLogout: mapApiToRunner({
      method: 'get',
      url: () => '/auth/virtual-logout',
    }),
  },
  Stats: {
    dashboard: mapApiToRunner({
      method: 'get',
      url: () => '/stats/dashboard',
    }),
    listClicks: mapApiToRunner({
      method: 'get',
      url: () => '/stats/clicks',
    }),
    viewClick: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/click/${id}`,
    }),
    listPostbacks: mapApiToRunner({
      method: 'get',
      url: () => '/stats/postbacks',
    }),
    viewPostback: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/postback/${id}`,
    }),
    updatePostback: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/postback/change-validity/${id}`,
    }),
    updateManyPostbacks: mapApiToRunner({
      method: 'post',
      url: () => '/stats/postbacks/change-validity',
    }),
    listPostbackAggregate: mapApiToRunner({
      method: 'get',
      timeout: 90000,
      url: () => '/stats/postbacks-aggregate',
    }),
    listAffiliatePostbacks: mapApiToRunner({
      method: 'get',
      url: () => '/stats/affiliate-postbacks',
    }),
    listCustom: mapApiToRunner({
      method: 'get',
      timeout: 180000,
      url: () => '/stats/custom',
    }),
    cacheCustomStats: mapApiToRunner({
      cacheable: 10 * 60 * 1000,
      method: 'get',
      timeout: 180000,
      url: () => '/stats/custom',
    }),
    topCaps: mapApiToRunner({
      method: 'get',
      url: () => '/stats/caps',
    }),
    listProgrammatic: mapApiToRunner({
      method: 'get',
      timeout: 180000,
      url: () => '/stats/programmatic',
    }),
    listImpressions: mapApiToRunner({
      method: 'get',
      url: () => '/stats/impressions',
    }),
    viewImpression: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/impression/${id}`,
    }),
    getOfferStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/programmatic/offer/${id}`,
    }),
    listCreativeStats: mapApiToRunner({
      method: 'get',
      url: () => `/stats/programmatic/creatives`,
    }),
    getCreativeStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats/programmatic/creative/${id}`,
    }),
    listAppStats: mapApiToRunner({
      method: 'get',
      url: () => `/stats/programmatic/apps`,
    }),
  },
  StatsPreset: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/stats-preset/create',
    }),
    delete: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats-preset/delete/${id}`,
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/stats-preset/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/stats-preset/search',
    }),
  },
  User: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/user/register',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/user/update/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/user/list',
    }),
    profile: mapApiToRunner({
      method: 'get',
      url: () => '/user/profile',
    }),
    refreshKey: mapApiToRunner({
      method: 'post',
      url: () => '/user/refresh-key',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/user/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/user/search',
    }),
    setDefaultDashboard: mapApiToRunner({
      method: 'post',
      url: () => '/user/profile/default-dashboard',
    }),
  },
  Advertiser: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/advertiser/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/advertiser/update/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/advertiser/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/advertiser/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/advertiser/search',
    }),
    ApiIntegration: {
      set: mapApiToRunner({
        method: 'POST',
        /**
         * @param {{id: string}} param0
         */
        url: ({ id }) => `/advertiser/${id}/api-integration`,
      }),
    },
  },
  Partner: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/partner/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/partner/update/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/partner/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/partner/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/partner/search',
    }),
  },
  PendingPartner: {
    list: mapApiToRunner({
      method: 'get',
      url: () => '/pendingPartner/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/pendingPartner/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/pendingPartner/search',
    }),
    approve: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/pendingPartner/approve/${id}`,
    }),
    reject: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/pendingPartner/reject/${id}`,
    }),
  },
  Offer: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/offer/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer/update/${id}`,
    }),
    updateMany: mapApiToRunner({
      method: 'post',
      url: () => '/offer/updatemany',
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/offer/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer/get/${id}`,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/offer/search',
    }),
    connectPartners: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer/connect/${id}`,
    }),
    clickTrack: mapApiToRunner({
      method: 'get',
      url: () => '/offer/tracking-info',
    }),
    createTracking: mapApiToRunner({
      method: 'post',
      url: () => '/offer/tracking/create',
    }),
    updateTracking: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer/tracking/update/${id}`,
    }),
    deleteTracking: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer/tracking/delete/${id}`,
    }),
    listTracking: mapApiToRunner({
      method: 'get',
      url: () => '/offer/tracking/list',
    }),
    caps: mapApiToRunner({
      method: 'get',
      url: () => '/offer/caps',
    }),
  },
  Ticket: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/ticket/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/ticket/update/${id}`,
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/ticket/get/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/ticket/list',
    }),
    close: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `ticket/close/${id}`,
    }),
    approve: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `ticket/approve/${id}`,
    }),
    reject: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `ticket/reject/${id}`,
    }),
  },
  Comment: {
    list: mapApiToRunner({
      method: 'get',
      url: () => '/comment/list',
    }),
    add: mapApiToRunner({
      method: 'post',
      url: () => '/comment/add',
    }),
  },
  Misc: {
    users: mapApiToRunner({
      method: 'get',
      url: () => '/misc/users',
    }),
    constants: mapApiToRunner({
      method: 'get',
      url: () => '/misc/constants',
      cacheable: 1000 * 60 * 15,
    }),
    locations: mapApiToRunner({
      method: 'get',
      url: () => '/misc/locations',
      cacheable: 1000 * 60 * 15,
    }),
    support: mapApiToRunner({
      method: 'post',
      url: () => '/misc/support',
    }),
  },
  Notification: {
    list: mapApiToRunner({
      method: 'get',
      url: () => '/notification/list',
    }),
    unreadCount: mapApiToRunner({
      method: 'get',
      url: () => '/notification/unread-count',
    }),
    markRead: mapApiToRunner({
      method: 'post',
      url: () => '/notification/mark-read',
    }),
  },
  ActionLog: {
    list: mapApiToRunner({
      method: 'get',
      url: () => '/action/list',
    }),
  },
  File: {
    upload: mapApiToRunner({
      method: 'post',
      url: () => '/file/upload',
      timeout: 200000,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/file/list',
    }),
  },
  CrAutomation: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/cr-automation/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/cr-automation/update/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/cr-automation/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/cr-automation/get/${id}`,
    }),
    delete: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/cr-automation/delete/${id}`,
    }),
  },
  AutomationLogs: {
    list: mapApiToRunner({
      method: 'get',
      url: () => `automation-logs`,
    }),
  },
  Affiliate: {
    refreshKey: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/refreshkey`,
    }),
    getPartner: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/get`,
    }),
    /**
     * @private
     * @type {Parameters<mapApiToRunner>['0']}
     */
    // @ts-ignore
    _getOffers: {
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/offers`,
    },
    get allOffers() {
      return mapApiToRunner({
        ...this._getOffers,
        params: { matchType: 'all' },
      });
    },
    get liveOffers() {
      return mapApiToRunner({
        ...this._getOffers,
        params: { matchType: 'live' },
      });
    },
    get previewOffers() {
      return mapApiToRunner({
        ...this._getOffers,
        params: { matchType: 'preview' },
      });
    },
    getOffer: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number, offerId: number}} param0
       */
      url: ({ partnerId, offerId }) => `/affiliate/${partnerId}/offer/${offerId}`,
    }),
    Stats: {
      listCustom: mapApiToRunner({
        method: 'get',
        timeout: 90000,
        /**
         * @param {{partnerId: number}} param0
         */
        url: ({ partnerId }) => `/affiliate/${partnerId}/stats/custom`,
      }),
      listPostbackAggregate: mapApiToRunner({
        method: 'get',
        timeout: 90000,
        /**
         * @param {{partnerId: number}} param0
         */
        url: ({ partnerId }) => `/affiliate/${partnerId}/stats/postbacks`,
      }),
      listPostbacks: mapApiToRunner({
        method: 'get',
        /**
         * @param {{partnerId: number}} param0
         */
        url: ({ partnerId }) => `/affiliate/${partnerId}/postbacks`,
      }),
      viewPostback: mapApiToRunner({
        method: 'get',
        /**
         * @param {{partnerId: number, id: string}} param0
         */
        url: ({ partnerId, id }) => `/affiliate/${partnerId}/postbacks/${id}`,
      }),
    },
    cacheStats: mapApiToRunner({
      cacheable: 10 * 60 * 1000,
      method: 'get',
      timeout: 90000,
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/stats`,
    }),
    countries: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/countries`,
      params: { search: '' },
    }),
    search: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/search/offer`,
    }),
    dashboard: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/dashboard`,
    }),
    listFiles: mapApiToRunner({
      method: 'get',
      /**
       * @param {{partnerId: number}} param0
       */
      url: ({ partnerId }) => `/affiliate/${partnerId}/file/list`,
    }),
    offerRequest: mapApiToRunner({
      method: 'post',
      /**
       * @param {{partnerId: number, offerId: number}} param0
       */
      url: ({ offerId, partnerId }) => `/affiliate/${partnerId}/offer/request/${offerId}`,
    }),
    Ticket: {
      create: mapApiToRunner({
        method: 'post',
        url: ({ partnerId }) => `/affiliate/${partnerId}/ticket/create`,
      }),
      get: mapApiToRunner({
        method: 'get',
        /**
         * @param {{partnerId: number, ticketId: string}} param0
         */
        url: ({ partnerId, ticketId }) => `/affiliate/${partnerId}/ticket/get/${ticketId}`,
      }),
      list: mapApiToRunner({
        method: 'get',
        url: ({ partnerId }) => `/affiliate/${partnerId}/ticket/list`,
      }),
      close: mapApiToRunner({
        method: 'post',
        /**
         * @param {{partnerId: number, ticketId: string}} param0
         */
        url: ({ partnerId, ticketId }) => `/affiliate/${partnerId}/ticket/close/${ticketId}`,
      }),
    },
    Comment: {
      list: mapApiToRunner({
        method: 'get',
        url: ({ partnerId }) => `/affiliate/${partnerId}/comment/list`,
      }),
      add: mapApiToRunner({
        method: 'post',
        url: ({ partnerId }) => `/affiliate/${partnerId}/comment/add`,
      }),
    },
  },
  Programmatic: {
    getAdvertiser: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/get`,
    }),
    refreshKey: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/refreshkey`,
    }),
    Stats: {
      listCustom: mapApiToRunner({
        method: 'get',
        timeout: 90000,
        url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/stats/custom`,
      }),
      cacheStats: mapApiToRunner({
        cacheable: 10 * 60 * 1000,
        method: 'get',
        timeout: 90000,
        /**
         * @param {{advertiserId: number}} param0
         */
        url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/stats/custom`,
      }),
    },
    listOfferStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/offers`,
    }),
    getOfferStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string, offerId: string}} param0
       */
      url: ({ advertiserId, offerId }) =>
        `/advertiser/${advertiserId}/programmatic/offer/${offerId}`,
    }),
    searchOffer: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/offer/search`,
    }),
    listCreativeStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/creatives`,
    }),
    getCreativeStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string, creativeId: number}} param0
       */
      url: ({ advertiserId, creativeId }) =>
        `/advertiser/${advertiserId}/programmatic/creative/${creativeId}`,
    }),
    listAppStats: mapApiToRunner({
      method: 'get',
      /**
       * @param {{advertiserId: string}} param0
       */
      url: ({ advertiserId }) => `/advertiser/${advertiserId}/programmatic/apps`,
    }),
    searchFilter: mapApiToRunner({
      method: 'get',
      url: () => `/stats/programmatic/search`,
    }),
  },
  Group: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/group/create',
    }),
    update: mapApiToRunner({
      method: 'post',
      url: ({ id }) => `/group/update/${id}`,
    }),
    delete: mapApiToRunner({
      method: 'post',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/group/delete/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => '/group/list',
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/group/get/${id}`,
    }),
    valid: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: () => '/group/valid',
      cacheable: true,
    }),
    search: mapApiToRunner({
      method: 'get',
      url: () => '/group/search',
    }),
  },
  Team: {
    get: mapApiToRunner({
      method: 'get',
      url: () => '/team/get',
    }),
    update: mapApiToRunner({
      method: 'post',
      url: () => '/team/update',
    }),
  },
  OfferFetcher: {
    create: mapApiToRunner({
      method: 'post',
      url: () => '/offer-fetcher',
    }),
    update: mapApiToRunner({
      method: 'put',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer-fetcher/${id}`,
    }),
    get: mapApiToRunner({
      method: 'get',
      /**
       * @param {{id: string}} param0
       */
      url: ({ id }) => `/offer-fetcher/${id}`,
    }),
    list: mapApiToRunner({
      method: 'get',
      url: () => `/offer-fetcher`,
    }),
  },
};

export default API;

export * from './handlers';
export * from './context';
export { mapApiToRunner };
