import Axios from 'axios';
import { store } from '../store';
import { updateAccessToken, logoutAction } from '../store/actions/simpleAuthActions';
import { Team } from '../utils';

const { protocol } = window.location;
const reactBaseApi = process.env.REACT_APP_BASE_API;

const BASE_API = `${protocol}//${Team}.${reactBaseApi}`;

/**
 * @type {import('axios').AxiosInstance}
 */
let axiosInstance;

function getAxios() {
  if (axiosInstance) return axiosInstance;

  axiosInstance = Axios.create({
    baseURL: `${BASE_API}`,
    timeout: 10000,
    withCredentials: true,
    headers: {
      'X-Requested-With': `Sapphyre Frontend ${process.env.REACT_APP_VERSION}`,
    },
  });

  // FIXME: Find better way to do this try:
  // https://github.com/axios/axios/issues/209
  axiosInstance.interceptors.request.use((config) => {
    if (config.headers.Authorization) {
      return config;
    }
    const { auth } = store.getState();
    if (auth.accessToken) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const { auth } = store.getState();
      if (auth.accessToken && 'access_token' in response.data) {
        store.dispatch(updateAccessToken(response.data.access_token));
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        store.dispatch(logoutAction);
      }
      // Throw err again (may be need for some other catch)
      return Promise.reject(error);
    },
  );
  return axiosInstance;
}

export default getAxios();

export { getAxios };
