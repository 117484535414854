import logger from 'utils/logger';

/**
 * @typedef {Object.<string, string[]>} PermissionsGroup
 *
 * @typedef {{
 *  allow: PermissionsGroup,
 *  deny: PermissionsGroup,
 * }} Permissions
 *
 * @typedef {{
 *  userId: string,
 *  accessToken: string
 *  userType: 'partner' | 'baser',
 *  userName: string,
 *  pid: number,
 *  aid: string,
 *  permissions: Permissions,
 *  valid: PermissionsGroup,
 *  virtualLogin: boolean,
 *  teamFlags: { programmatic: boolean }
 * }} AuthState
 *
 *
 * @param {Partial<AuthState>} state
 * @param {{
 *  type: string
 * } & Partial<AuthState>} action
 * @returns {Partial<AuthState>}
 */
const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      logger.debug('Login Success');

      return {
        ...state,
        userId: action.userId,
        accessToken: action.accessToken,
        userType: action.userType,
        userName: action.userName,
        pid: action.pid,
        aid: action.aid,
        permissions: action.permissions,
        valid: action.valid,
        virtualLogin: !!action.virtualLogin,
        teamFlags: action.teamFlags,
      };

    case 'LOGOUT':
      logger.debug('[LOGOUT]');

      return {
        ...state,
        accessToken: null,
        userId: null,
        userType: null,
        userName: null,
        permissions: null,
        pid: null,
        aid: null,
        virtualLogin: null,
        teamFlags: null,
      };

    case 'UPDATE_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.accessToken,
      };

    default:
      return state;
  }
};

export default authReducer;
