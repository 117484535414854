import React from 'react';
import { startCase, get, mapValues } from 'lodash';
import dayjs from 'dayjs';
import { Row, Col, Form } from 'antd';
import FormDate from 'components/FormDate/FormDate';
import FormSelect from 'components/FormSelect';
import FormSearch from 'components/FormSearch';
import FormCheckbox from 'components/FormCheckbox';
import FormInput from 'components/FormInput';
import { mongoIds, required } from 'utils/rules';
import { timeZonesOptions, getLocalTimezone } from '../../constants/TimeZones';

/**
 * **range**: number,
 * **rangeUnit**: dayjs.OpUnitType,
 * **startOfUnit** / **endOfUnit**: dayjs.OpUnitType
 *
 * **Option Value**: range_rangeUnit_startOfUnit:range_rangeUnit_endOfUnit
 */
const dateRangeMap = {
  Today: '0_day_day:0_day_day',
  Yesterday: '1_day_day:1_day_day',
  'This Week': '0_day_week:0_day_day',
  'Last Week': '1_week_week:1_week_week',
  'This Month': '0_day_month:0_day_day',
  'Last Month': '1_month_month:1_month_month',
  'Last 7 Days': '7_day_day:0_day_day',
  'Last 30 Days': '30_day_day:0_day_day',
};
const dateRangeOptions = Object.entries(dateRangeMap).map(([label, value]) => ({ label, value }));

/**
 * @typedef {[
 *  string,
 *  dayjs.OpUnitType,
 *  dayjs.OpUnitType,
 * ]} DateArr
 */

/**
 * @param {string} range
 */
function parseDateRangeString(range) {
  /** @type {[DateArr, DateArr]} */
  // @ts-ignore
  const [fromArr, toArr] = range.split(':').map((item) => item.split('_'));

  return {
    fromDate: dayjs().subtract(Number(fromArr[0]), fromArr[1]).startOf(fromArr[2]).toDate(),
    toDate: dayjs().subtract(Number(toArr[0]), toArr[1]).endOf(toArr[2]).toDate(),
  };
}

const FormDateRanges = mapValues(dateRangeMap, (v) =>
  Object.values(parseDateRangeString(v)).map(dayjs),
);

/**
 * @typedef {{
 *  name?: string,
 *  label?: string,
 *  defaultVal?: any,
 *  options?: string[],
 *  apiParams?: any,
 *  apiDynamicData?: any,
 *  formRef: import('react').RefObject<import('antd/lib/form').FormInstance>,
 *  key: number,
 *  extra?: any,
 * }} FilterTypeProps
 */

/**
 * @param {string} labelKey
 * @param {{api?: string, tooltip?: string} & Partial<FilterTypeProps>} [opts]
 * @returns {(props: FilterTypeProps) => JSX.Element}
 */
function getIdSearchComponent(labelKey, opts = {}) {
  const defaultName = labelKey.toLowerCase();
  const defaultLabel = startCase(labelKey);
  const {
    api = `${labelKey}.search`,
    tooltip = `Search by ${labelKey} name or ID`,
    ...rest
  } = opts;
  return (props) => {
    const {
      name = defaultName,
      label = defaultLabel,
      defaultVal,
      apiParams,
      apiDynamicData,
      key,
      extra,
    } = { ...rest, ...props };

    return (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSearch
          name={['filters', name]}
          label={`${label} Filter`}
          placeholder={`Select ${label}`}
          tooltip={label === defaultLabel ? tooltip : `Search by ${label} name or ID`}
          initialValues={defaultVal}
          mode="multiple"
          searchApi={api}
          apiParams={typeof apiParams === 'function' ? apiParams() : apiParams}
          apiDynamicData={apiDynamicData}
          {...extra}
        />
      </Col>
    );
  };
}

/**
 * @param {string} defaultName
 * @param {{tooltip?: string, mode?: 'tags' | 'multiple', props?: any}} [opts]
 * @returns {(props: FilterTypeProps) => JSX.Element}
 */
function getTypeSelectComponent(defaultName, opts = {}) {
  const { tooltip, mode, props: formSelectProps } = opts;

  return (props) => {
    const { name = defaultName, label = startCase(name), options, key, extra } = props;

    return (
      <Col xs={24} sm={12} lg={8} key={key}>
        <FormSelect
          name={['filters', name]}
          label={label}
          placeholder={`Select ${label}`}
          options={options}
          tooltip={tooltip}
          mode={mode}
          {...formSelectProps}
          {...extra}
        />
      </Col>
    );
  };
}

/**
 *
 * @param {string} defaultName
 * @param {{tooltip?: string}} opts
 */
function getCheckComponent(defaultName, opts = {}) {
  const { tooltip } = opts;

  return (props) => {
    const { name = defaultName, label = startCase(name), key } = props;

    return (
      <Col xs={24} sm={12} lg={8} key={key} style={{ alignSelf: 'flex-end' }}>
        <FormCheckbox name={['filters', name]} label={label} tooltip={tooltip} />
      </Col>
    );
  };
}

const filterTypes = {
  date: {
    component: ({ key, extra, defaultVal }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormDate
          rules={extra?.required ? [required] : []}
          name="dateRange"
          label="From Date → To Date"
          type="range"
          defaultValue={extra?.required ? defaultVal : []}
          {...(extra?.includeRanges
            ? {
              datePickerProps: {
                ranges: FormDateRanges,
              },
            }
            : {})}
          {...extra}
        />
      </Col>
    ),
  },
  timeRange: {
    component: ({ key, extra }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormDate
          name="dateRange"
          type="range"
          label="From Time → To Time"
          rules={extra?.required ? [required] : undefined}
          formItemProps={{
            getValueFromEvent: ([from, to]) => [from.startOf('hour'), to.startOf('hour')],
          }}
          datePickerProps={{
            format: 'YYYY-MM-DD HH:mm',
            showTime: true,
            onChange: undefined,
            inputReadOnly: true,
            minuteStep: 60,
          }}
        />
      </Col>
    ),
  },
  dateRange: {
    default: '7_day_day:0_day_day', // Last 7 Days
    component: getTypeSelectComponent('dateRange', {
      props: {
        options: dateRangeOptions,
        rules: [required],
        selectProps: { allowClear: false },
      },
    }),
  },
  offer: {
    default: [],
    component: getIdSearchComponent('Offer'),
  },
  offeraffiliate: {
    default: [],
    component: getIdSearchComponent('Offer', {
      api: 'Affiliate.search',
    }),
  },
  offeradvertiser: {
    default: [],
    component: getIdSearchComponent('Offer', {
      api: 'Programmatic.searchOffer',
    }),
  },
  trackingType: {
    default: undefined,
    component: getTypeSelectComponent('trackingType'),
  },
  partner: {
    default: [],
    component: getIdSearchComponent('Partner'),
  },
  advertiser: {
    default: [],
    component: getIdSearchComponent('Advertiser'),
  },
  user: {
    default: [],
    component: getIdSearchComponent('User'),
  },
  dashboard: {
    default: [],
    component: getIdSearchComponent('Dashboard'),
  },
  group: {
    component: getIdSearchComponent('Group'),
  },
  clickId: {
    default: [],
    component: ({ label = 'Click IDs', key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSelect
          name={['filters', 'clickId']}
          label={label}
          tooltip={`Search by ${label}`}
          mode="tags"
          rules={[mongoIds]}
        />
      </Col>
    ),
  },
  impressionId: {
    default: [],
    component: ({ label = 'Impression IDs', key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSelect
          name={['filters', 'imperssionId']}
          label={label}
          tooltip={`Search by ${label}`}
          mode="tags"
          rules={[mongoIds]}
        />
      </Col>
    ),
  },
  userType: {
    default: undefined,
    component: getTypeSelectComponent('userType'),
  },
  pendingPartner: {
    default: [],
    component: getIdSearchComponent('PendingPartner', {
      tooltip: 'Search by Partner name or ID',
    }),
  },
  actionType: {
    default: [],
    component: getTypeSelectComponent('actionType', {
      mode: 'multiple',
      tooltip: 'Can search with the type of the activity of the user',
    }),
  },
  country: {
    default: [],
    component: getTypeSelectComponent('country', { mode: 'multiple' }),
  },
  _id: {
    default: [],
    component: ({ label = 'IDs', key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSelect
          name={['filters', '_id']}
          label={label}
          tooltip={`Search by ${label}`}
          mode="tags"
          rules={[mongoIds]}
        />
      </Col>
    ),
  },
  external_offer_id: {
    default: [],
    component: ({ label = 'External Offer ids', key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSelect
          name={['filters', 'external_offer_id']}
          label={label}
          tooltip={`Search by ${label}`}
          mode="tags"
        />
      </Col>
    ),
  },
  identifier: {
    default: [],
    component: getTypeSelectComponent('identifier', {
      tooltip: 'Search by package name or domain name',
      mode: 'tags',
    }),
  },
  goal: {
    default: [],
    component: getTypeSelectComponent('goal', {
      tooltip: 'Search by goal value',
      mode: 'tags',
    }),
  },
  status: {
    default: undefined,
    component: getTypeSelectComponent('status'),
  },
  privacyLevel: {
    default: undefined,
    component: getTypeSelectComponent('privacyLevel'),
  },
  ticketType: {
    default: undefined,
    component: getTypeSelectComponent('ticketType', { mode: 'multiple' }),
  },
  ticketAction: {
    component: getTypeSelectComponent('ticketAction'),
  },
  priority: {
    default: undefined,
    component: getTypeSelectComponent('priority'),
  },
  /** Programmatic filters */
  dspId: {
    default: [],
    component: getIdSearchComponent('DSPID', {
      name: 'dsp_id',
      label: 'DSP',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'dsp_id',
      },
    }),
  },
  creativeType: {
    default: [],
    component: getIdSearchComponent('CreativeType', {
      name: 'creative_type',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'creative_type',
      },
    }),
  },
  buyingModel: {
    default: [],
    component: getIdSearchComponent('BuyingModel', {
      name: 'buying_model',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'buying_model',
      },
    }),
  },
  exchange: {
    default: [],
    component: getIdSearchComponent('Exchange', {
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'exchange',
      },
    }),
  },
  publisherApp: {
    default: [],
    component: getIdSearchComponent('PublisherApp', {
      name: 'publisher_app',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'publisher_app',
      },
    }),
  },
  publisherAppBundle: {
    default: [],
    component: getIdSearchComponent('PublisherAppBundle', {
      name: 'publisher_app_bundle',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'publisher_app_bundle',
      },
    }),
  },
  creativeName: {
    default: [],
    component: getIdSearchComponent('Creative', {
      name: 'creative_name',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'creative_name',
      },
    }),
  },
  creativeOrientation: {
    default: [],
    component: getIdSearchComponent('CreativeOrientation', {
      name: 'creative_orientation',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'creative_orientation',
      },
    }),
  },
  adFormat: {
    default: [],
    component: getIdSearchComponent('AdFormat', {
      name: 'ad_format',
      api: 'Programmatic.searchFilter',
      apiParams: {
        column: 'ad_format',
      },
    }),
  },
  timezone: {
    default: getLocalTimezone(),
    component: getTypeSelectComponent('timezone', {
      tooltip: 'Timezone in which stats will be shown',
      props: {
        name: 'timezone',
        options: timeZonesOptions,
        capitalizeOptions: false,
        selectProps: { allowClear: false },
      },
    }),
  },
  validClicks: {
    default: undefined,
    component: getTypeSelectComponent('validClicks', {
      props: {
        options: [
          { label: 'Valid', value: true },
          { label: 'Invalid', value: false },
        ],
      },
      tooltip: 'Select valid/invalid clicks',
    }),
  },
  nonZeroConversions: {
    default: undefined,
    component: getCheckComponent('nonZeroConversions', {
      tooltip: 'Check this to show converted clicks',
    }),
  },
  validPostbacks: {
    default: undefined,
    component: getTypeSelectComponent('validPostbacks', {
      props: {
        options: [
          { label: 'Valid', value: true },
          { label: 'Invalid', value: false },
          { label: 'Sampled Conversions', value: 'Sampled' },
        ],
      },
      tooltip: 'Select valid/invalid postbacks',
    }),
  },
  linkedItems: {
    default: undefined,
    component: ({
      key,
      name = 'linkedItems',
      label = 'Linked Items',
      options,
      defaultVal,
      formRef,
    }) => {
      const modelField = ['filters', name, 'model'];
      return (
        <Col xs={24} sm={12} md={16} key={key}>
          <Row align="bottom">
            <Col span={8}>
              <FormSelect
                name={modelField}
                label={label}
                placeholder="Select Item Type"
                options={options}
                selectProps={{
                  onSelect: () =>
                    formRef.current?.setFieldsValue({ filters: { [name]: { id: undefined } } }),
                }}
              />
            </Col>

            <Col span={16}>
              <Form.Item
                noStyle
                shouldUpdate={(prevVal, currVal) =>
                  get(prevVal, modelField) !== get(currVal, modelField)
                }
              >
                {({ getFieldValue }) => {
                  const model = getFieldValue(modelField);
                  const type = ['Offer', 'Partner'].includes(model) ? 'integer' : 'string';
                  if (!model) return null;
                  return (
                    <FormSearch
                      name={['filters', name, 'id']}
                      label=""
                      placeholder={`Select ${model || 'Item'}`}
                      initialValues={defaultVal?.id}
                      searchApi={`${model}.search`}
                      type={type}
                      rules={[required]}
                      formItemProps={{ messageVariables: { label: 'Item' } }}
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      );
    },
  },
  phrase: {
    component: ({ label, key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormInput
          name="phrase"
          label={`${label} Search`}
          tooltip="Search by any phrase"
          placeholder="Search by any phrase"
        />
      </Col>
    ),
  },
  fetcherId: {
    default: [],
    component: ({ label = 'Fetcher Id', key }) => (
      <Col xs={24} sm={12} md={8} key={key}>
        <FormSelect
          name={['filters', 'fetcherId']}
          label={label}
          tooltip={`Search by ${label}`}
          mode="tags"
        />
      </Col>
    ),
  },
};

export default filterTypes;

export { dateRangeMap, parseDateRangeString };
