const apiReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CACHE_DATA':
      return {
        ...state,
        [action.hash]: { response: action.response, timestamp: new Date() },
      };

    default:
      return state;
  }
};

export default apiReducer;
