import React from 'react';
import { mapValues } from 'lodash';
import { LinkProps } from 'react-router-dom';
import {
  encodeQueryParams,
  stringify,
  ArrayParam,
  QueryParamConfigMap,
  DecodedValueMap,
} from 'use-query-params';
import Link from 'components/Link';
import { queryParamsToProps as FiltersQPConfig } from 'components/Filters';
import { queryParamsToProps as DataTableQPConfig } from 'components/DataTable';

const ListingPageQPConfig = {
  ...FiltersQPConfig,
  ...DataTableQPConfig,
};

// Not imported because of circular dependency
const CustomStatsQPConfig = {
  ...ListingPageQPConfig,
  sums: ArrayParam,
  groups: ArrayParam,
};

function convertToQPString<QPCMap extends QueryParamConfigMap>(
  query: Partial<DecodedValueMap<QPCMap>>,
  config: QPCMap,
) {
  const encodedQuery = encodeQueryParams(config, query);
  return `?${stringify(encodedQuery)}`;
}

function convertListingQP<QPCMap extends QueryParamConfigMap>(
  query: Partial<DecodedValueMap<QPCMap & typeof ListingPageQPConfig>>,
  extraQPConfig?: QPCMap,
) {
  return convertToQPString(query, { ...ListingPageQPConfig, ...extraQPConfig });
}

export type ListingQP = Partial<DecodedValueMap<typeof ListingPageQPConfig>>;
export type CustomQP = Partial<DecodedValueMap<typeof CustomStatsQPConfig>>;

const Links = {
  email: ({ email }: { email: string }) => `mailto:${email}`,
  dashboard: () => '/',
  Dashboard: {
    add: () => '/dashboard/add',
    edit: ({ id }: { id: string }) => `/dashboard/edit/${id}`,
    view: ({ id }: { id: string }) => `/dashboard/view/${id}`,
    list: (opts) => `/dashboards${convertListingQP(opts)}`,
  },
  Auth: {
    login: () => '/login',
    forgotPassword: () => '/forgot',
    resetPassword: ({ token }: { token: string }) => `/reset-password/${token}`,
  },
  Errors: {
    pageNotFound: () => '/404',
    serverError: () => '/500',
  },
  User: {
    add: () => '/user/add',
    addPartnerUser: ({ id }: { id: string }) => `/user/add/partner/${id}`,
    addAdvertiserUser: ({ id }: { id: string }) => `/user/add/advertiser/${id}`,
    view: ({ id }: { id: string }) => `/user/view/${id}`,
    edit: ({ id }: { id: string }) => `/user/edit/${id}`,
    profile: () => '/profile',
    editProfile: () => '/profile/edit',
    list: (opts) => `/users${convertListingQP(opts)}`,
  },
  Partner: {
    add: () => '/partner/add',
    view: ({ id }: { id: string }) => `/partner/view/${id}`,
    edit: ({ id }: { id: string }) => `/partner/edit/${id}`,
    list: (opts: ListingQP) => `/partners${convertListingQP(opts)}`,
  },
  PendingPartner: {
    signup: () => '/partner-signup',
    view: ({ id }: { id: string }) => `/partner/pending/${id}`,
    list: (opts: ListingQP) => `/partners/pending${convertListingQP(opts)}`,
  },
  Advertiser: {
    add: () => '/advertiser/add',
    view: ({ id }: { id: string }) => `/advertiser/view/${id}`,
    edit: ({ id }: { id: string }) => `/advertiser/edit/${id}`,
    list: (opts: ListingQP) => `/advertisers${convertListingQP(opts)}`,
  },
  Offer: {
    add: () => '/offer/add',
    clone: ({ id }: { id: string }) => `/offer/add?cloneFrom=${id}`,
    view: ({ id }: { id: string }) => `/offer/view/${id}`,
    edit: ({ id }: { id: string }) => `/offer/edit/${id}`,
    list: (opts: ListingQP) => `/offers${convertListingQP(opts)}`,
    listIdentifier: ({ id }: { id: string }) =>
      `/offers${convertListingQP({
        filters: { identifier: [id] },
      })}`,
    viewTracking: ({ id }: { id: string }) =>
      `/offer/tracking/list/${convertListingQP({ filters: { offer: [id] } })}`,
    managePartners: ({ id }: { id: string }) => `/offer/${id}/affiliate`,
  },
  Ticket: {
    add: () => '/ticket/create',
    view: ({ id }: { id: string }) => `/ticket/view/${id}`,
    edit: ({ id }: { id: string }) => `/ticket/edit/${id}`,
    list: (opts: ListingQP) => `/tickets${convertListingQP(opts)}`,
  },
  CrAutomation: {
    add: () => '/automation/cr/add',
    edit: ({ id }: { id: string }) => `/automation/cr/edit/${id}`,
    list: () => '/automation/cr/list',
    logs: ({ id }: { id: string }) => `/automation/cr/${id}/logs`,
  },
  Affiliate: {
    dashboard: ({ partnerId }: { partnerId: number }) => `/affiliate/${partnerId}/dashboard`,
    profile: ({ partnerId }: { partnerId: number }) => `/affiliate/${partnerId}/profile`,
    listLiveOffers: ({ partnerId, ...opts }: { partnerId: number } & ListingQP) =>
      `/affiliate/${partnerId}/offers/live${convertListingQP(opts)}`,
    offerDetails: ({ partnerId, offerId }: { partnerId: number; offerId: number }) =>
      `/affiliate/${partnerId}/offer/view/${offerId}`,
    pageNotFound: ({ partnerId }: { partnerId: number }) => `/affiliate/${partnerId}/404`,
    viewTicket: ({ partnerId, ticketId }: { partnerId: number; ticketId: string }) =>
      `/affiliate/${partnerId}/ticket/view/${ticketId}`,
    viewPostback: ({ id, partnerId }: { id: string; partnerId: number }) =>
      `/affiliate/${partnerId}/stats/postback/${id}`,
    listStats: ({ partnerId, ...opts }: { partnerId: number } & CustomQP) =>
      `/affiliate/${partnerId}/stats/custom${convertListingQP(opts, CustomStatsQPConfig)}`,
  },
  Programmatic: {
    dashboard: ({ advertiserId }: { advertiserId: string }) => `/advertiser/${advertiserId}/programmatic/dashboard`,
    profile: ({ advertiserId }: { advertiserId: string }) => `/advertiser/${advertiserId}/profile`,
    listStats: ({ advertiserId, ...opts }: { advertiserId: string } & CustomQP) =>
      `/advertiser/${advertiserId}/programmatic/stats/custom${convertListingQP(
        opts,
        CustomStatsQPConfig,
      )}`,
    viewOffer: ({ advertiserId, offerId }: { advertiserId: string; offerId: number }) =>
      `/advertiser/${advertiserId}/programmatic/offer/view/${offerId}`,
    viewCreative: ({ advertiserId, creativeId }: { advertiserId: string; creativeId: number }) =>
      `/advertiser/${advertiserId}/programmatic/creative/view/${creativeId}`,
    pageNotFound: ({ advertiserId }: { advertiserId: string }) => `/advertiser/${advertiserId}/404`,
  },
  Stats: {
    viewClick: ({ id }: { id: string }) => `/stats/click/${id}`,
    listCustom: (opts: CustomQP) => `/stats/custom${convertListingQP(opts, CustomStatsQPConfig)}`,
    listClicks: (opts: ListingQP) => `/stats/click${convertListingQP(opts)}`,
    listPostbacks: (opts: CustomQP) =>
      `/stats/postback${convertListingQP(opts, CustomStatsQPConfig)}`,
    viewImpression: ({ id }: { id: string }) => `/stats/impression/${id}`,
    viewCreative: ({ id }: { id: string }) => `/programmatic/creative/${id}`,
  },
  Postback: {
    view: ({ id }: { id: string }) => `/stats/postback/${id}`,
  },
  Group: {
    add: () => '/group/add',
    view: ({ id }: { id: string }) => `/group/view/${id}`,
    edit: ({ id }: { id: string }) => `/group/edit/${id}`,
    list: (opts: ListingQP) => `/groups${convertListingQP(opts)}`,
  },
  Team: {
    view: () => '/team/view',
    edit: () => '/team/edit',
  },
  OfferFetcher: {
    add: () => `/automation/offer-fetcher/add`,
    view: ({ id }: { id: string }) => `/automation/offer-fetcher/view/${id}`,
    edit: ({ id }: { id: string }) => `/automation/offer-fetcher/edit/${id}`,
    list: (opts: ListingQP) => `/automation/offer-fetcher/list${convertListingQP(opts)}`,
    logs: ({ id }: { id: string }) => `/automation/offer-fetcher/${id}/logs`,
  },
};

type NameType = string | number | React.ReactElement;
type OptionsType = { name?: NameType; state?: Record<string, any> };
type LinkToFn<O> = (input: O, opts?: NameType | OptionsType) => React.ReactElement;

function mapper<T>(fn: (opts: T) => string): LinkToFn<T> {
  return (input: T, opts?: NameType | OptionsType) => {
    const path: string = fn(input);
    let options: OptionsType = {};

    if (opts) {
      if (React.isValidElement(opts) || typeof opts === 'string' || typeof opts === 'number') {
        options = {
          name: opts,
        };
      }
      else {
        options = opts;
      }
    }

    let link: LinkProps['to'];
    if (options.state) {
      link = {
        pathname: path.split('?')[0],
        state: options.state,
        search: path.split('?')[1] || '',
        hash: path.split('#')[1],
      };
    }
    const name = options.name || Object.values(input).join(', ');
    return <Link to={link || path}>{name}</Link>;
  };
}

type NestedMap = {
  [key: string]: ((opts: any) => string) | NestedMap;
};

type Mapper<T> = T extends (opts: infer O) => string
  ? LinkToFn<O>
  : {
      [K in keyof T]: Mapper<T[K]>;
    };

function recurseMap<T extends NestedMap>(map: T): Mapper<T> {
  return mapValues(map, (val) => {
    if (typeof val === 'function') {
      // @ts-ignore
      return mapper(val);
    }
    // @ts-ignore
    return recurseMap(val);
  }) as Mapper<T>;
}

const LinkTo = recurseMap(Links);

function getLinkedItemLink(linkedItem?: { model?: string; item?: any; id: string }) {
  if (!linkedItem) return '---';

  const { model, item, id } = linkedItem;

  if (!model || !LinkTo[model]?.view) {
    return id;
  }
  if (!item) {
    return LinkTo[model].view({ id });
  }

  let label;
  switch (model) {
    case 'Offer':
      label = `${item.name}(${item._id})`;
      break;
    case 'Partner':
      label = `${item.company}(${item._id})`;
      break;
    case 'User':
      label = item.name;
      break;
    default:
      label = item._id;
  }
  return LinkTo[model].view({ id: item._id }, label);
}

export default Links;

export { LinkTo, Links, getLinkedItemLink, convertToQPString };
