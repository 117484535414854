import React from 'react';
import { withQueryParams } from 'use-query-params';

/**
 * @see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-528871868
 *
 * @template {import('use-query-params').QueryParamConfigMap} QPCMap
 * @param {QPCMap} queryParamsToProps
 * @param {typeof React.Component} Component
 */
const withQueryParamsAndRef = (queryParamsToProps, Component) => {
  const WithQueryParams = withQueryParams(queryParamsToProps, ({ forwardRef, ...otherProps }) => (
    <Component ref={forwardRef} {...otherProps} />
  ));
  const WithQueryParamsAndRef = React.forwardRef((props, ref) => (
    <WithQueryParams {...props} forwardRef={ref} />
  ));

  const name = Component.displayName || Component.name;
  WithQueryParamsAndRef.displayName = `withQueryParamsAndRef(${name})`;
  return WithQueryParamsAndRef;
};

export default withQueryParamsAndRef;
