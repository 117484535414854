/**
 * Explaination of Working
 * If filters are absent:
 *    set props apiParams & apiHeaders to state so that their change will be used in fetchData
 * If filters are present:
 *    Change to props apiParams & apiHeaders will not call fetchData. Only form submission will set
 *    form data and props apiParams & apiHeaders to state and then they will be used in fetchData.
 */

// eslint-disable-next-line max-classes-per-file
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { isEqual, isEmpty, merge, filter } from 'lodash';
import { StringParam, NumberParam } from 'use-query-params';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Table } from 'antd';
import { PropTypePresets } from 'utils';
import Filters from 'components/Filters';
import CheckboxPopover from 'components/CheckboxPopover';
import withQueryParamsAndRef from 'components/withQueryParamsAndRef';
import ResizeableTitle from './ResizeableTitle';
import './styles.scoped.less';

/**
 * @typedef {Omit<import('prop-types').InferProps<typeof DataTable.propTypes>, 'columns' | 'filtersMap'> & {
 *    className?: string,
 *    filtersMap?: import('../Filters/Filters').FilterProps['filtersMap'],
 *    columns: (import('antd/lib/table').ColumnType & {visible?: boolean})[],
 *    api: import('api').ApiFunction<any | void>,
 * }} DataTableProps
 *
 * @typedef {DataTableProps & UseQP<typeof queryParamsToProps> & {
 *   isMobile?: boolean,
 * }} Props
 *
 * @extends {Component<Props>}
 */
class DataTable extends Component {
  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  /** @param {Props} props */
  constructor(props) {
    super(props);

    this.filtersRef = createRef();
    this.defaultPageSize = props.query.pageSize || 25;
    this.resetPage = false;

    this.state = {
      loading: false,
      forceReload: false,
      data: [],
      columns: this.transformColumns(props.columns),
      pages: null,
      total: -1,
      selectedRows: [],
      apiParams: {},
      apiHeaders: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // update params & headers from props if filters are missing
    if (
      isEmpty(nextProps.filtersMap) &&
      (!isEqual(nextProps.apiParams, prevState.apiParams) ||
        !isEqual(nextProps.apiHeaders, prevState.apiHeaders))
    ) {
      return {
        apiParams: nextProps.apiParams,
        apiHeaders: nextProps.apiHeaders,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.autoSubmit && isEmpty(this.props.filtersMap)) {
      this.fetchData();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.query, this.props.query) ||
      !isEqual(nextProps.apiParams, this.props.apiParams) ||
      !isEqual(nextProps.apiHeaders, this.props.apiHeaders) ||
      !isEqual(nextProps.filtersMap, this.props.filtersMap) ||
      !isEqual(nextProps.columns, this.props.columns)
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.onLoading && prevState.loading !== this.state.loading) {
      this.props.onLoading(this.state.loading);
    }

    const paramsChanged = !isEqual(prevState.apiParams, this.state.apiParams);

    // Reset page count on params/filters change
    if (
      paramsChanged &&
      this.props.query.pageNum &&
      this.props.query.pageNum !== 1 &&
      this.resetPage
    ) {
      this.handlePageChange(1);
      // Returning so fetchData is not called twice
      return;
    }

    if (prevProps.columns !== this.props.columns) {
      this.resetColumns();
      return;
    }

    if (
      paramsChanged ||
      this.state.forceReload ||
      !isEqual(prevState.apiHeaders, this.state.apiHeaders) ||
      prevProps.query.pageNum !== this.props.query.pageNum ||
      prevProps.query.pageSize !== this.props.query.pageSize ||
      prevProps.query.sortBy !== this.props.query.sortBy ||
      prevProps.query.sortOrder !== this.props.query.sortOrder
    ) {
      this.fetchData();
    }
  };

  get selectedColumns() {
    const { columns } = this.state;
    const { allowColumnSelection } = this.props;
    return allowColumnSelection ? filter(columns, 'visible') : columns;
  }

  handleResize = (index) => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  /**
   * @param {import('antd/lib/table').ColumnType[]} columns
   * @returns {any[]}
   */
  transformColumns = (columns) =>
    columns.map(
      ({ dataIndex, key = dataIndex, width = 100, sorter, align = 'center', ...rest }, index) => ({
        dataIndex,
        key,
        align,
        // FIXME: Not working
        defaultSortOrder:
          this.props.query.sortBy === dataIndex ? this.props.query.sortOrder || 'descend' : null,
        width,
        ellipsis: !(rest.title === 'Actions' || rest.fixed) && { showTitle: false },
        sorter: typeof sorter === 'function' ? sorter : sorter !== false,
        ...rest,
        visible: true,
        onHeaderCell: (column) => {
          column.ellipsis = false;
          return {
            width: column.width,
            onResize: this.handleResize(index),
          };
        },
      }),
    );

  resetColumns = () => {
    this.setState({ columns: this.transformColumns(this.props.columns) });
  };

  fetchData = () => {
    this.resetPage = true;
    this.setState({ loading: true, forceReload: false }, () => {
      const { sortBy: sortField, sortOrder: order, pageNum = 1, pageSize = 25 } = this.props.query;

      const sortOrder = order !== 'ascend' ? 'desc' : 'asc';
      const column = this.state.columns.find((c) => c.dataIndex === sortField);
      const sortBy = (column && column.sortKey) || sortField;
      const skip = (pageNum - 1) * pageSize;

      return this.props
        .api(
          {
            input: this.props.apiInput,
            params: {
              skip,
              limit: pageSize + 1,
              sortOrder,
              sortBy,
              ...this.state.apiParams,
            },
            headers: this.state.apiHeaders,
          },
          { errorNotification: 'Fetching Data Failed', formRef: this.filtersRef.current?.formRef },
        )
        .then(async (res) => {
          this.props.onData?.(res);
          const data = res.data[this.props.dataKey];
          let { total } = res.data;
          const pages = total ?? null;
          // For stats that don't return total
          if (total === undefined) {
            total = skip + data.length;
          }
          const transformedData = await this.props.transformData(data.slice(0, pageSize));
          this.setState({
            // Limit is greater than pageSize
            data: transformedData,
            pages,
            total,
            loading: false,
            selectedRows: [],
          });
          return res;
        })
        .catch((err) => {
          this.props.onError?.(err);
          this.setState({ loading: false });
        });
    });
  };

  /**
   * Only handles sorting
   */
  handleChange = (pagination, filters, sorted) => {
    if (!sorted.order) {
      sorted.field = undefined;
    }

    if (sorted.order === this.props.query.sortOrder && sorted.field === this.props.query.sortBy)
      return;

    const isClearSort = sorted.order === undefined;

    this.props.setQuery(
      {
        sortBy: isClearSort ? undefined : sorted.field,
        sortOrder: sorted.order,
        pageNum: undefined,
      },
      'pushIn',
    );
  };

  /**
   * Called when the page index is changed by the user
   * @param {number} pageNum
   */
  handlePageChange = (pageNum) => {
    if (pageNum === 1) {
      if (!this.props.query.pageNum) return;
      pageNum = undefined;
    }

    this.props.setQuery({ pageNum }, 'pushIn');
  };

  /**
   * Called when the pageSize is changed by the user.
   * The resolve page is also sent to maintain approximate position in the data
   * @param {number} pageNum
   * @param {number} pageSize
   */
  handlePageSizeChange = (pageNum, pageSize) => {
    // Reset page on `pageSize` change
    if (this.props.query.pageSize !== pageSize) pageNum = undefined;
    this.props.setQuery({ pageNum, pageSize }, 'pushIn');
  };

  handleRowSelection = (selectedRows) => this.setState({ selectedRows });

  handleColumnSelection = (selectedColumns) => {
    this.setState((state) => ({
      columns: state.columns.map((col) => ({
        ...col,
        visible: selectedColumns.includes(col.title),
      })),
    }));
  };

  reload = () => this.setState({ forceReload: true });

  handleSubmit = (params, headers) => {
    this.props.onFiltersSubmit?.(params, headers);
    this.setState({
      forceReload: true,
      apiParams: merge({}, this.props.apiParams, params),
      apiHeaders: merge({}, this.props.apiHeaders, headers),
    });
  };

  handleExport = (params, headers) => {
    this.setState({ loading: true }, () => {
      this.props
        .api(
          {
            input: this.props.apiInput,
            params: merge({}, this.props.apiParams, params),
            headers: merge({}, this.props.apiHeaders, headers),
          },
          { notification: true },
        )
        .finally(() => this.setState({ loading: false }));
    });
  };

  getCardExtra = () => {
    const { allowColumnSelection, children } = this.props;

    if (!children && !allowColumnSelection) return null;

    return (
      <>
        {children}
        {allowColumnSelection ? (
          <CheckboxPopover
            items={this.state.columns.map(({ title }) => title)}
            selectedItems={this.selectedColumns.map(({ title }) => title)}
            onChange={this.handleColumnSelection}
          />
        ) : null}
      </>
    );
  };

  render() {
    const { selectedRows } = this.state;
    const { updateManyForm, filtersMap } = this.props;

    return (
      <>
        {filtersMap ? (
          <Filters
            ref={this.filtersRef}
            filtersMap={filtersMap}
            loading={this.state.loading}
            onValidSubmit={this.handleSubmit}
            onValidExport={filtersMap.export ? this.handleExport : undefined}
            onReset={this.props.onFiltersReset}
            autoSubmit={this.props.autoSubmit}
          />
        ) : null}

        <Card
          className={classnames('data-table-wrapper', this.props.className)}
          bodyStyle={{ padding: 0 }}
          title={updateManyForm && selectedRows.length ? updateManyForm : this.props.title}
          extra={this.getCardExtra()}
          {...this.props.cardProps}
        >
          <Table
            className="data-table"
            components={this.components}
            dataSource={this.state.data}
            rowKey={this.props.rowKey}
            columns={this.selectedColumns}
            loading={this.state.loading}
            tableLayout={this.props.isMobile ? 'auto' : 'fixed'}
            bordered
            size="small"
            scroll={{
              x: 'max-content',
              scrollToFirstRowOnChange: true,
            }}
            onChange={this.handleChange}
            pagination={{
              defaultPageSize: this.defaultPageSize,
              pageSizeOptions: ['10', '25', '50', '100'],
              showQuickJumper: this.state.pages !== null,
              showSizeChanger: true,
              onChange: this.handlePageChange,
              onShowSizeChange: this.handlePageSizeChange,
              current: this.props.query.pageNum || 1,
              total: this.state.pages || this.state.total,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            rowSelection={
              updateManyForm && {
                columnWidth: `${this.props.checkboxColumnWidth}px`,
                onChange: this.handleRowSelection,
                selectedRowKeys: selectedRows,
                getCheckboxProps: this.props.getCheckboxProps,
              }
            }
            {...this.props.tableProps}
          />
        </Card>
      </>
    );
  }
}

DataTable.defaultProps = {
  apiParams: {},
  apiHeaders: {},
  apiInput: {},
  transformData: (res) => res,
  dataKey: 'items',
  rowKey: '_id',
  columns: [],
  autoSubmit: true,
  allowColumnSelection: false,
  checkboxColumnWidth: 15,
};

DataTable.propTypes = {
  dataKey: PropTypes.string,
  transformData: PropTypes.func,
  filtersMap: PropTypePresets.filtersMap,
  onFiltersSubmit: PropTypes.func,
  onFiltersReset: PropTypes.func,
  onData: PropTypes.func,
  onError: PropTypes.func,
  onLoading: PropTypes.func,
  apiInput: PropTypes.object,
  apiParams: PropTypes.object,
  apiHeaders: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  autoSubmit: PropTypes.bool,
  updateManyForm: PropTypes.element,
  tableProps: PropTypes.object,
  cardProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  allowColumnSelection: PropTypes.bool,
  getCheckboxProps: PropTypes.func,
  checkboxColumnWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isMobile: state.globals.isMobile,
});

export const queryParamsToProps = {
  sortBy: StringParam,
  sortOrder: StringParam,
  pageSize: NumberParam,
  pageNum: NumberParam,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withQueryParamsAndRef(queryParamsToProps, DataTable),
);
