import { remove } from 'lodash';

const INITIAL_STATE = { helpTextVisible: true, recentSearch: [] };

const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PLATFORM':
      return {
        ...state,
        isMobile: action.isMobile,
      };

    case 'SET_HELP_VISIBILITY':
      return {
        ...state,
        helpTextVisible: action.helpTextVisible,
      };

    case 'UPDATE_VERSION_CHECK_TIME':
      return {
        ...state,
        versionCheckLastTime: Date.now(),
      };

    case 'UPDATE_RECENT_SEARCH': {
      const recentSearch = [...state.recentSearch];
      const { newSearch } = action;

      // remove the existing newSearch if it is there, later it will be added in the beginning
      remove(
        recentSearch,
        (search) => search.value === newSearch.value && search.label === newSearch.label,
      );

      // insert the newSearch in the beginning
      recentSearch.unshift(newSearch);

      // limit recent search count to 10
      if (recentSearch.length > 10) recentSearch.pop();

      return { ...state, recentSearch };
    }

    case 'SET_DEFAULT_DASHBOARD':
      return {
        ...state,
        dashboardId: action.dashboardId,
      };

    default:
      return state;
  }
};

export default globalReducer;
