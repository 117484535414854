import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import './styles.less';

function Loading(props) {
  return (
    <div className="loading-wrapper">
      <Spin className="loading" size={props.size} />
    </div>
  );
}

Loading.defaultProps = {
  size: 'default',
};

Loading.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
};

export default Loading;
