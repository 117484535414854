/* eslint-disable max-len */
import React, { lazy } from 'react';
import dayjs from 'dayjs';
import {
  faTags,
  faUserTie,
  faAd,
  faGem,
  faClipboardList,
  faUsers,
  faUserCog,
  faChartBar,
  faFileExport,
  faTable,
  faTicketAlt,
  faSpider,
  faPlayCircle,
  faAtlas,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import {
  BarsOutlined,
  BarChartOutlined,
  PlusOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import { LinkTo } from 'links';
import { hasPermission } from 'utils';
import { FaIcon, ErrorPage, Button } from 'components';

const ViewDashboard = lazy(() =>
  import(/* webpackChunkName: "main-ViewDashboard" */ 'views/base/Dashboard/ViewDashboard'),
);
const AddDashboard = lazy(() =>
  import(/* webpackChunkName: "main-AddDashboard" */ 'views/base/Dashboard/AddDashboard'),
);
const ListDashboard = lazy(() =>
  import(/* webpackChunkName: "main-ListDashboard" */ 'views/base/Dashboard/ListDashboard'),
);

const AddPartner = lazy(() =>
  import(/* webpackChunkName: "main-AddPartner" */ 'views/base/Partner/AddPartner'),
);
const ViewPartner = lazy(() =>
  import(/* webpackChunkName: "main-ViewPartner" */ 'views/base/Partner/ViewPartner'),
);
const ListPartner = lazy(() =>
  import(/* webpackChunkName: "main-ListPartner" */ 'views/base/Partner/ListPartner'),
);

const PendingPartners = lazy(() =>
  import(
    /* webpackChunkName: "main-ListPendingPartner" */ 'views/base/PendingPartner/ListPendingPartner'
  ),
);
const ViewPendingPartner = lazy(() =>
  import(
    /* webpackChunkName: "main-ViewPendingPartner" */ 'views/base/PendingPartner/ViewPendingPartner'
  ),
);

const AddAdvertiser = lazy(() =>
  import(/* webpackChunkName: "main-AddAdvertiser" */ 'views/base/Advertiser/AddAdvertiser'),
);
const ViewAdvertiser = lazy(() =>
  import(/* webpackChunkName: "main-ViewAdvertiser" */ 'views/base/Advertiser/ViewAdvertiser'),
);
const ListAdvertiser = lazy(() =>
  import(/* webpackChunkName: "main-ListAdvertiser" */ 'views/base/Advertiser/ListAdvertiser'),
);

const AddUser = lazy(() =>
  import(/* webpackChunkName: "main-AddUser" */ 'views/base/User/AddUser'),
);
const ViewUser = lazy(() =>
  import(/* webpackChunkName: "main-ViewUser" */ 'views/base/User/ViewUser'),
);
const ListUser = lazy(() =>
  import(/* webpackChunkName: "main-ListUser" */ 'views/base/User/ListUser'),
);
const UserActivity = lazy(() =>
  import(/* webpackChunkName: "main-UserActivity" */ 'views/base/Team/UserActivity'),
);
const UserProfile = lazy(() =>
  import(/* webpackChunkName: "main-profile" */ 'views/base/User/UserProfile'),
);
const EditProfile = lazy(() =>
  import(/* webpackChunkName: "main-EditProfile" */ 'views/base/User/EditProfile'),
);

const AddGroup = lazy(() =>
  import(/* webpackChunkName: "main-AddGroup" */ 'views/base/Group/AddGroup'),
);
const ViewGroup = lazy(() =>
  import(/* webpackChunkName: "main-ViewGroup" */ 'views/base/Group/ViewGroup'),
);
const ListGroup = lazy(() =>
  import(/* webpackChunkName: "main-ListGroup" */ 'views/base/Group/ListGroup'),
);

const AddOffer = lazy(() =>
  import(/* webpackChunkName: "main-AddOffer" */ 'views/base/Offer/AddOffer'),
);
const ViewOffer = lazy(() =>
  import(/* webpackChunkName: "main-ViewOffer" */ 'views/base/Offer/ViewOffer'),
);
const ListOffer = lazy(() =>
  import(/* webpackChunkName: "main-ListOffer" */ 'views/base/Offer/ListOffer'),
);
const ListTracking = lazy(() =>
  import(/* webpackChunkName: "main-ListTracking" */ 'views/base/OfferTracking/ListTracking'),
);

const ClickStats = lazy(() =>
  import(/* webpackChunkName: "main-ClickStats" */ 'views/base/Stats/ClickStats'),
);
const ImpressionStats = lazy(() =>
  import(/* webpackChunkName: "main-ImpressionStats" */ 'views/base/Stats/ImpressionStats'),
);
const PostbackStats = lazy(() =>
  import(/* webpackChunkName: "main-PostbackStats" */ 'views/base/Stats/PostbackStats'),
);
const AffiliatePostbacks = lazy(() =>
  import(/* webpackChunkName: "main-AffiliatePostbacks" */ 'views/base/Stats/AffiliatePostbacks'),
);
const CustomStats = lazy(() =>
  import(/* webpackChunkName: "main-CustomStats" */ 'views/base/Stats/CustomStats'),
);
const ViewClickStats = lazy(() =>
  import(/* webpackChunkName: "main-ViewClickStats" */ 'views/base/Stats/ViewClickStats'),
);
const ViewImpressionStats = lazy(() =>
  import(/* webpackChunkName: "main-ViewImpressionStats" */ 'views/base/Stats/ViewImpressionStats'),
);
const ViewPostbackStats = lazy(() =>
  import(/* webpackChunkName: "main-ViewPostbackStats" */ 'views/base/Stats/ViewPostbackStats'),
);

const ProgrammaticStats = lazy(() =>
  import(
    /* webpackChunkName: "main-ProgrammaticStats" */ 'views/base/Programmatic/ProgrammaticStats'
  ),
);

const ProgrammaticClickStats = lazy(() =>
  import(
    /* webpackChunkName: "main-ProgrammaticClickStats" */ 'views/base/Programmatic/ProgrammaticClickStats'
  ),
);

const ProgrammaticImpressionStats = lazy(() =>
  import(
    /* webpackChunkName: "main-ProgrammaticImpressionStats" */ 'views/base/Programmatic/ProgrammaticImpressionStats'
  ),
);

const ViewCreativeStats = lazy(() =>
  import(
    /* webpackChunkName: "main-ViewCreativeStats" */ 'views/base/Programmatic/ViewCreativeStats'
  ),
);

const ViewTeam = lazy(() =>
  import(/* webpackChunkName: "main-ViewTeam" */ 'views/base/Team/ViewTeam'),
);
const EditTeam = lazy(() =>
  import(/* webpackChunkName: "main-EditTeam" */ 'views/base/Team/EditTeam'),
);

const CreateTicket = lazy(() =>
  import(/* webpackChunkName: "main-CreateTicket" */ 'views/base/Ticket/CreateTicket'),
);
const ListTicket = lazy(() =>
  import(/* webpackChunkName: "main-ListTicket" */ 'views/base/Ticket/ListTicket'),
);
const ViewTicket = lazy(() =>
  import(/* webpackChunkName: "main-ViewTicket" */ 'views/base/Ticket/ViewTicket'),
);

const AddCrAutomation = lazy(() =>
  import(/* webpackChunkName: "main-AddCrAutomation" */ 'views/base/CrAutomation/AddCrAutomation'),
);
const ListCrAutomation = lazy(() =>
  import(
    /* webpackChunkName: "main-ListCrAutomation" */ 'views/base/CrAutomation/ListCrAutomation'
  ),
);
const CrAutomationLogs = lazy(() =>
  import(
    /* webpackChunkName: "main-CrAutomationLogs" */ 'views/base/CrAutomation/CrAutomationLogs'
  ),
);

const Export = lazy(() => import(/* webpackChunkName: "main-Export" */ 'views/base/Export/Export'));

const AddOfferFetcher = lazy(() =>
  import(/* webpackChunkName: "main-AddOfferFetcher" */ 'views/base/OfferFetcher/AddOfferFetcher'),
);
const ListOfferFetcher = lazy(() =>
  import(/* webpackChunkName: "main-AddOfferFetcher" */ 'views/base/OfferFetcher/ListOfferFetcher'),
);
const ViewOfferFetcher = lazy(() =>
  import(
    /* webpackChunkName: "main-ViewOfferFetcher" */ 'views/base/OfferFetcher/ViewOfferFetcher'
  ),
);
const OfferFetcherLogs = lazy(() =>
  import(
    /* webpackChunkName: "main-OfferFetcherLogs" */ 'views/base/OfferFetcher/OfferFetcherLogs'
  ),
);

const programmaticRoutes = {
  name: 'Programmatic',
  icon: <FaIcon icon={faTable} />,
  base: '/programmatic',
  views: [
    {
      name: 'Click Logs',
      component: ProgrammaticClickStats,
      path: '/click',
      permissions: ['stats.read'],
      exact: true,
    },
    {
      name: 'Impression Logs',
      component: ProgrammaticImpressionStats,
      path: '/impression',
      permissions: ['stats.read'],
      exact: true,
    },
    {
      name: 'Programmatic Stats',
      component: ProgrammaticStats,
      permissions: ['stats.read'],
      path: '/stats',
    },
    {
      name: 'Creative Stats',
      component: ViewCreativeStats,
      permissions: ['stats.read'],
      path: '/creative/:id',
      invisible: true,
    },
  ],
};

/**
 * @returns {import('./index').RouteItem[]}
 */
function getBaseRoutes(programmatic = false) {
  return [
    {
      name: 'Home',
      component: ViewDashboard,
      base: '',
      path: '/',
      icon: <FaIcon icon={faHome} />,
      exact: true,
      showTitle: false,
    },
    {
      name: 'Offers',
      icon: <FaIcon icon={faTags} />,
      base: '/offer',
      views: [
        {
          name: 'Add Offer',
          component: AddOffer,
          path: '/add',
          permissions: ['offer.create'],
          help: {
            text: 'Adding an Offer',
            link: '/docs/usage/offers/adding-an-offer',
          },
        },
        {
          name: 'Edit Offer',
          component: AddOffer,
          path: '/edit/:id',
          invisible: true,
          exact: true,
          permissions: ['offer.update'],
          buttons: [
            ({ match }) =>
              LinkTo.Offer.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'List Offers',
          component: ListOffer,
          path: 's',
          permissions: ['offer.read'],
          buttons: [
            () =>
              hasPermission('offer.create')
                ? LinkTo.Offer.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'View Offer',
          component: ViewOffer,
          path: '/view/:id',
          invisible: true,
          permissions: ['offer.read'],
          help: {
            text: 'What is an Offer?',
            link: '/docs/usage/offers/what-is-an-offer',
          },
          buttons: [
            ({ match }) =>
              LinkTo.Stats.listPostbacks(
                {
                  filters: { offer: [Number(match.params.id)] },
                  fromDate: dayjs().subtract(7, 'day').toDate(),
                  groups: ['partner', 'country', 'state', 'city', 'os', 'adSubid', 'subpid'],
                  sums: ['revenue', 'valid_postbacks'],
                },
                {
                  name: <Button.View icon={<BarChartOutlined />}>Postback Stats</Button.View>,
                  state: { autoSubmit: true },
                },
              ),
          ],
        },
        {
          name: 'Offer Postback',
          component: ListTracking,
          path: '/tracking/list',
          permissions: ['tracking.read'],
        },
      ],
    },
    {
      name: 'Stats',
      icon: <FaIcon icon={faTable} />,
      base: '/stats',
      views: [
        {
          name: 'Click Logs',
          component: ClickStats,
          path: '/click',
          permissions: ['stats.read'],
          exact: true,
        },
        {
          name: 'Impression Logs',
          component: ImpressionStats,
          path: '/impression',
          permissions: ['stats.read'],
          exact: true,
        },
        {
          name: 'Conversion Stats',
          component: PostbackStats,
          path: '/postback',
          permissions: ['stats.read'],
          exact: true,
        },
        {
          name: 'Affiliate Postbacks',
          component: AffiliatePostbacks,
          path: '/affiliate-postbacks',
          permissions: ['stats.read'],
          exact: true,
        },
        {
          name: 'Custom Stats',
          component: CustomStats,
          permissions: ['stats.read'],
          path: '/custom',
        },
        {
          name: 'View Click',
          component: ViewClickStats,
          path: '/click/:id',
          permissions: ['stats.read'],
          invisible: true,
        },
        {
          name: 'View Impression',
          component: ViewImpressionStats,
          path: '/impression/:id',
          permissions: ['stats.read'],
          invisible: true,
        },
        {
          name: 'View Postback',
          component: ViewPostbackStats,
          permissions: ['stats.read'],
          path: '/postback/:id',
          invisible: true,
        },
      ],
    },
    ...(programmatic ? [programmaticRoutes] : []),
    {
      name: 'Advertisers',
      icon: <FaIcon icon={faAd} />,
      base: '/advertiser',
      views: [
        {
          name: 'Add Advertiser',
          component: AddAdvertiser,
          path: '/add',
          permissions: ['advertiser.create'],
          help: {
            text: 'Adding an Advertiser',
            link: '/docs/integrations/advertiser/adding-an-advertiser',
          },
        },
        {
          name: 'Edit Advertiser',
          component: AddAdvertiser,
          path: '/edit/:id',
          permissions: ['advertiser.update'],
          invisible: true,
          exact: true,
          buttons: [
            ({ match }) =>
              LinkTo.Advertiser.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'List Advertisers',
          component: ListAdvertiser,
          path: 's',
          buttons: [
            () =>
              hasPermission('advertiser.create')
                ? LinkTo.Advertiser.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
          permissions: ['advertiser.read'],
        },
        {
          name: 'View Advertiser',
          component: ViewAdvertiser,
          path: '/view/:id',
          permissions: ['advertiser.read'],
          invisible: true,
          buttons: [
            ({ match }) =>
              hasPermission('offer.read')
                ? LinkTo.Offer.list(
                  { filters: { advertiser: [match.params.id] } },
                  <Button.View icon={<FaIcon icon={faTags} />}>Offers</Button.View>,
                )
                : null,
          ],
        },
      ],
    },
    {
      name: 'Partners',
      icon: <FaIcon icon={faUserTie} />,
      base: '/partner',
      views: [
        {
          name: 'Add Partner',
          component: AddPartner,
          path: '/add',
          permissions: ['partner.create'],
          help: {
            text: 'Adding a Partner',
            link: '/docs/integrations/partner/adding-a-partner',
          },
        },
        {
          name: 'Edit Partner',
          component: AddPartner,
          path: '/edit/:id',
          invisible: true,
          exact: true,
          permissions: ['partner.update'],
          buttons: [
            ({ match }) =>
              LinkTo.Partner.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'List Partners',
          component: ListPartner,
          path: 's',
          exact: true,
          permissions: ['partner.read'],
          buttons: [
            () =>
              hasPermission('partner.create')
                ? LinkTo.Partner.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'View Partner',
          component: ViewPartner,
          path: '/view/:id',
          invisible: true,
          permissions: ['partner.read'],
        },
        {
          name: 'Pending Partners',
          component: PendingPartners,
          path: 's/pending',
          exact: true,
          permissions: ['pendingPartner.read'],
        },
        {
          name: 'View Pending Partner',
          component: ViewPendingPartner,
          path: '/pending/:id',
          permissions: ['pendingPartner.read'],
          invisible: true,
        },
      ],
    },
    {
      name: 'Automation',
      icon: <FaIcon icon={faPlayCircle} />,
      base: '/automation',
      views: [
        {
          name: 'CR Automation',
          component: ListCrAutomation,
          path: '/cr/list',
          permissions: ['automation.read'],
          exact: true,
          icon: <PercentageOutlined />,
          help: {
            link: '/docs/usage/automation/cr-automation',
            text: 'Conversion Rate (CR) Automation',
          },
          buttons: [
            () =>
              hasPermission('automation.create')
                ? LinkTo.CrAutomation.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'Add CR Automation',
          component: AddCrAutomation,
          path: '/cr/add',
          permissions: ['automation.create'],
          invisible: true,
        },
        {
          name: 'Edit CR Automation',
          component: AddCrAutomation,
          path: '/cr/edit/:id',
          permissions: ['automation.update'],
          invisible: true,
        },
        {
          name: 'CR Automation Logs',
          component: CrAutomationLogs,
          path: '/cr/:id/logs',
          permissions: ['automation.read'],
          invisible: true,
          exact: true,
        },
        {
          name: 'Offer Fetcher',
          component: ListOfferFetcher,
          icon: <FaIcon icon={faSpider} />,
          path: '/offer-fetcher/list',
          permissions: ['offerFetcher.read'],
          buttons: [
            () =>
              hasPermission('offerFetcher.create')
                ? LinkTo.OfferFetcher.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'Add Offer Fetcher',
          component: AddOfferFetcher,
          path: '/offer-fetcher/add',
          invisible: true,
          permissions: ['offerFetcher.create'],
        },
        {
          name: 'Edit Offer Fetcher',
          component: AddOfferFetcher,
          path: '/offer-fetcher/edit/:id',
          permissions: ['offerFetcher.update'],
          invisible: true,
          exact: true,
          buttons: [
            ({ match }) =>
              LinkTo.OfferFetcher.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'View Offer Fetcher',
          component: ViewOfferFetcher,
          path: '/offer-fetcher/view/:id',
          permissions: ['offerFetcher.read'],
          invisible: true,
          exact: true,
          buttons: [
            ({ match }) =>
              LinkTo.OfferFetcher.logs(
                { id: match.params.id },
                <Button.View icon={<BarsOutlined />}>Logs</Button.View>,
              ),
            ({ match }) =>
              LinkTo.Offer.list(
                { filters: { fetcherId: [match.params.id] } },
                <Button.View icon={<FaIcon icon={faTags} />}>Offers</Button.View>,
              ),
          ],
        },
        {
          name: 'Offer Fetcher Logs',
          component: OfferFetcherLogs,
          path: '/offer-fetcher/:id/logs',
          permissions: ['offerFetcher.read'],
          invisible: true,
          exact: true,
        },
      ],
    },
    {
      name: 'Dashboards',
      icon: <FaIcon icon={faChartBar} />,
      base: '/dashboard',
      views: [
        {
          name: 'Add Dashboard',
          component: AddDashboard,
          path: '/add',
          permissions: ['dashboard.create'],
          exact: true,
          showTitle: false,
          help: {
            text: 'Adding a Dashboard',
            link: '/docs/usage/stats/dashboard',
          },
        },
        {
          name: 'Edit Dashboard',
          component: AddDashboard,
          path: '/edit/:id',
          exact: true,
          invisible: true,
          showTitle: false,
        },
        {
          name: 'List Dashboards',
          component: ListDashboard,
          path: 's',
          buttons: [
            () =>
              hasPermission('dashboard.create')
                ? LinkTo.Dashboard.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'View Dashboard',
          component: ViewDashboard,
          path: '/view/:id',
          exact: true,
          invisible: true,
          showTitle: false,
        },
      ],
    },
    {
      name: 'Ticketing',
      icon: <FaIcon icon={faTicketAlt} />,
      base: '/ticket',
      views: [
        {
          name: 'Create Ticket',
          component: CreateTicket,
          path: '/create',
          permissions: ['ticket.create'],
          help: {
            link: '/docs/usage/tickets/creating-a-ticket',
            text: 'Creating a ticket',
          },
        },
        {
          name: 'List Ticket',
          component: ListTicket,
          path: 's',
          permissions: ['ticket.read'],
          exact: true,
          buttons: [
            () =>
              hasPermission('ticket.create')
                ? LinkTo.Ticket.add(
                  {},
                  <Button.View icon={<PlusOutlined />}>Create New</Button.View>,
                )
                : null,
          ],
        },
        {
          name: 'Edit Ticket',
          component: CreateTicket,
          path: '/edit/:id',
          permissions: ['ticket.update'],
          invisible: true,
          exact: true,
          buttons: [
            ({ match }) =>
              LinkTo.Ticket.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'View Ticket',
          component: ViewTicket,
          path: '/view/:id',
          permissions: ['ticket.read'],
          invisible: true,
        },
      ],
    },
    {
      name: 'Users',
      icon: <FaIcon icon={faUsers} />,
      base: '/user',
      views: [
        {
          name: 'Add User',
          component: AddUser,
          path: '/add',
          permissions: ['user.create', 'user.create-partner', 'user.create-advertiser'],
          exact: true,
        },
        {
          name: 'Add Partner User',
          component: AddUser,
          path: '/add/partner/:pid',
          permissions: ['user.create-partner'],
          invisible: true,
        },
        {
          name: 'Add Advertiser User',
          component: AddUser,
          path: '/add/advertiser/:aid',
          permissions: ['user.create-advertiser'],
          invisible: true,
        },
        {
          name: 'Edit User',
          component: AddUser,
          path: '/edit/:id',
          permissions: ['user.update'],
          invisible: true,
          buttons: [
            ({ match }) =>
              LinkTo.User.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'List Users',
          component: ListUser,
          path: 's',
          permissions: ['user.read'],
          exact: true,
          buttons: [
            () =>
              hasPermission('user.create')
                ? LinkTo.User.add({}, <Button.View icon={<PlusOutlined />}>Create New</Button.View>)
                : null,
          ],
        },
        {
          name: 'View User',
          component: ViewUser,
          path: '/view/:id',
          permissions: ['user.read'],
          invisible: true,
        },
      ],
    },
    {
      name: 'Profile',
      component: UserProfile,
      base: '',
      path: '/profile',
      invisible: true,
      exact: true,
    },
    {
      name: 'Edit Profile',
      component: EditProfile,
      base: '',
      path: '/profile/edit',
      invisible: true,
      exact: true,
      buttons: [() => LinkTo.User.profile({}, <Button.View>View</Button.View>)],
    },
    {
      name: 'Administration',
      icon: <FaIcon icon={faAtlas} />,
      base: '',
      views: [
        {
          name: 'Activity Logs',
          component: UserActivity,
          path: '/users/activity',
          icon: <FaIcon icon={faClipboardList} />,
          permissions: ['activity.read'],
        },
        {
          name: 'Groups',
          component: ListGroup,
          path: '/groups',
          icon: <FaIcon icon={faUserCog} />,
          permissions: ['group.read'],
          exact: true,
          buttons: [
            () =>
              LinkTo.Group.add({}, <Button.View icon={<PlusOutlined />}>Create New</Button.View>),
          ],
        },
        {
          name: 'Add Group',
          component: AddGroup,
          path: '/group/add',
          permissions: ['group.create'],
          exact: true,
          invisible: true,
        },
        {
          name: 'View Group',
          component: ViewGroup,
          path: '/group/view/:id',
          permissions: ['group.read'],
          invisible: true,
        },
        {
          name: 'Edit Group',
          component: AddGroup,
          path: '/group/edit/:id',
          permissions: ['group.update'],
          invisible: true,
          buttons: [
            ({ match }) =>
              LinkTo.Group.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
        {
          name: 'Team Settings',
          component: ViewTeam,
          path: '/team/view',
          icon: <FaIcon icon={faGem} />,
          permissions: ['team.read'],
        },
        {
          name: 'Edit Team Settings',
          component: EditTeam,
          path: '/team/edit',
          permissions: ['team.update'],
          invisible: true,
          buttons: [
            ({ match }) =>
              LinkTo.Team.view({ id: match.params.id }, <Button.View>View</Button.View>),
          ],
        },
      ],
    },
    {
      name: 'Export',
      component: Export,
      base: '',
      path: '/export',
      icon: <FaIcon icon={faFileExport} />,
      exact: true,
    },
    {
      name: 'Page Not Found',
      component: ErrorPage.NotFound,
      base: '',
      path: '/404',
      invisible: true,
    },
  ];
}

export default getBaseRoutes;
export { programmaticRoutes };
