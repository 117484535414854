import React, { Component } from 'react';
import { Form, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { flatMap, debounce, get, castArray, isEqual, uniqBy, findIndex, isNil } from 'lodash';
import API from 'api';
import { PropTypePresets, logger } from 'utils';
import { getSearchRule, transformRules } from 'utils/rules';
import InfoTooltip from 'components/InfoTooltip';
import Help from 'components/Help';

/**
 * @typedef {import('prop-types').InferProps<typeof FormSearch.propTypes>} Props
 *
 * @extends {Component<Props>}
 */
class FormSearch extends Component {
  pendingPromise = Promise.resolve([]);

  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      options: [],
      selectedOptions: [],
      searchApi: props.searchApi,
      apiParams: props.apiParams, // storing this to reset options on params change
    };
    const { mode, type, rules } = this.props;

    this.rules = transformRules(
      [
        ...rules,
        getSearchRule(async () => {
          const opts = await this.pendingPromise;
          return [
            ...this.getOptions(),
            ...opts,
            ...(mode === 'default' ? [{ value: null, label: 'NULL' }] : []),
          ];
        }),
      ],
      {
        type: mode === 'multiple' ? 'array' : type,
      },
    );
    this.lastFetchId = 0;
    this.addOptions = debounce(this._addOptions, 500);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.apiParams, prevState.apiParams)) {
      return {
        apiParams: nextProps.apiParams,
        options: [],
      };
    }
    if (nextProps.searchApi !== prevState.searchApi) {
      return {
        searchApi: nextProps.searchApi,
        options: [],
        selectedOptions: [],
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.pendingPromise = this.getInitialValues().catch((err) => {
      logger.error({ err }, 'Error while init');
      return [];
    });
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
      this.getInitialValues();
    }
  };

  /**
   * Edit case
   */
  getInitialValues = async () => {
    const { initialValues, searchKey } = this.props;
    const init = castArray(initialValues);
    if (isNil(initialValues) || !init.length) return [];

    const result = await this.fetchData({ [searchKey]: init });
    const selectedOptions = result.filter((item) => init.includes(item.value));
    this.setState((state) => ({ selectedOptions: [...selectedOptions, ...state.selectedOptions] }));
    return selectedOptions;
  };

  /**
   * @param {string | Record<string, any>} [val]
   * @returns {Promise<{label: string, value: any}[]>}
   */
  fetchData = (val = '') => {
    const { searchApi, apiDynamicData, apiParams, allOption, searchKey } = this.props;
    const api = get(API, searchApi);

    let params = { ...apiParams };
    if (typeof val === 'string') params.phrase = val;
    else params = { ...val, ...params };

    return new Promise((resolve, reject) => {
      this.setState({ fetching: true }, () =>
        api({
          input: apiDynamicData,
          params,
        })
          .then(({ data }) => {
            let result = data?.result ?? [];
            if (
              allOption !== undefined &&
              ((typeof val === 'object' && val[searchKey].includes(allOption)) ||
                (val === '' && result.length) ||
                (typeof val === 'string' && new RegExp(val.toLowerCase()).test('all')))
            ) {
              result = [{ label: 'All/Rest', value: allOption }].concat(result);
            }
            resolve(result);
          })
          .catch(reject)
          .finally(() => {
            this.setState({ fetching: false });
          }),
      );
    });
  };

  _addOptions = async (phrase) => {
    try {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      const res = await this.fetchData(phrase);
      if (fetchId !== this.lastFetchId) {
        // If older API call finishes later then no need to set result
        return;
      }
      this.setState({ options: res });
    }
    catch (err) {
      logger.error({ err }, 'Form Search Error');
    }
  };

  getOptions = () => {
    const options = uniqBy(
      [...this.state.selectedOptions, ...this.state.options].filter(Boolean),
      (option) => option.value,
    );
    return options;
  };

  // initialize options on focus
  onFocus = () => {
    if (!this.state.options.length) this.addOptions('');
  };

  onSelect = (val, ...rest) => {
    this.props.onSelectChange?.(val, ...rest);
    const options = this.getOptions();

    this.setState((state) => {
      let { selectedOptions } = state;
      const optionToAdd = options.find((option) => option.value === val);
      if (this.props.mode === 'multiple') selectedOptions.push(optionToAdd);
      else selectedOptions = [optionToAdd];
      return { selectedOptions };
    });
  };

  onDeselect = (val, ...rest) => {
    this.props.onSelectChange?.(val, ...rest);
    this.setState((state) => {
      let { selectedOptions } = state;
      selectedOptions = selectedOptions.filter((option) => option.value !== val);
      return { selectedOptions };
    });
  };

  /**
   * For resetting from outside (In GeosFilter)
   */
  onReset = ({ resetOptions = false } = {}) => {
    if (!this.state.selectedOptions.length && (!resetOptions || !this.state.options.length)) return;
    this.setState({ selectedOptions: [], ...(resetOptions && { options: [] }) });
  };

  onClear = () => {
    if (!this.state.selectedOptions.length) return;
    this.setState({ selectedOptions: [] });
  };

  getPopupContainer = (node) => node.closest('.ant-card') || node.closest('.ant-form-item');

  render() {
    const {
      label,
      name,
      mode,
      searchProps,
      placeholder,
      disabled,
      formItemProps,
      tooltip,
      help,
      allOption,
    } = this.props;
    const { selectedOptions } = this.state;
    const allSelected = findIndex(selectedOptions, (op) => op.value === allOption) !== -1;

    const { fetching } = this.state;
    const optionsLoading = (
      <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <Spin />
      </div>
    );
    return (
      <Form.Item
        hasFeedback
        name={name}
        label={label}
        rules={this.rules}
        tooltip={InfoTooltip.Config(tooltip)}
        extra={<Help text={help} />}
        validateFirst
        {...formItemProps}
      >
        <Select
          showSearch
          // Disable filter as results are already filtered from backend
          filterOption={false}
          allowClear
          mode={mode}
          tokenSeparators={[',']}
          placeholder={placeholder || label}
          onFocus={this.onFocus}
          onSearch={this.addOptions}
          onSelect={this.onSelect}
          onDeselect={this.onDeselect}
          onClear={this.onClear}
          disabled={disabled}
          notFoundContent={fetching ? optionsLoading : undefined}
          optionFilterProp="label"
          getPopupContainer={this.getPopupContainer}
          {...searchProps}
        >
          {this.getOptions().map((op) => (
            <Select.Option
              key={op.value}
              value={op.value}
              label={op.label}
              disabled={
                selectedOptions.length &&
                (allSelected ? op.value !== allOption : op.value === allOption)
              }
            >
              {op.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}

FormSearch.defaultProps = {
  placeholder: '',
  type: 'string',
  disabled: false,
  rules: [],
  mode: 'default',
  apiDynamicData: {},
  apiParams: {},
  searchKey: 'ids',
  initialValues: [],
  searchProps: {},
  formItemProps: {},
};

FormSearch.propTypes = {
  name: PropTypePresets.path.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypePresets.tooltip,
  type: PropTypes.oneOf(['string', 'integer']),
  help: PropTypePresets.help,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rules: PropTypePresets.rules,
  mode: PropTypes.oneOf(['default', 'multiple']),
  searchApi: PropTypes.oneOf(
    flatMap(API, (actions, app) => Object.keys(actions).map((action) => `${app}.${action}`)),
  ).isRequired,
  apiDynamicData: PropTypes.object,
  apiParams: PropTypes.object,
  searchKey: PropTypes.string,
  onSelectChange: PropTypes.func,
  searchProps: PropTypes.object,
  formItemProps: PropTypes.object,
  initialValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  allOption: PropTypes.any,
};

export default FormSearch;
