import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Typography } from 'antd';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { Sidebar, FaIcon } from 'components';
import { Links } from 'links';
import { setMobilePlatform } from 'store/actions/globalActions';

function Sider(props) {
  const { partnerId, advertiserId, setMobile, isMobile, routes } = props;
  let linkTo = Links.dashboard();

  if (partnerId) linkTo = Links.Affiliate.dashboard({ partnerId });
  else if (advertiserId) linkTo = Links.Programmatic.listStats({ advertiserId });

  return (
    <Layout.Sider
      collapsible
      breakpoint="lg"
      collapsedWidth={isMobile ? '0' : undefined}
      onBreakpoint={(broke) => (broke !== isMobile ? setMobile(broke) : null)}
      zeroWidthTriggerStyle={{ top: 10 }}
    >
      <Link to={linkTo} className="sider-menu-logo">
        <FaIcon icon={faGem} size="lg" />
        <Typography.Title level={1}>Sapphyre</Typography.Title>
      </Link>
      <Sidebar routes={routes} partnerId={partnerId} advertiserId={advertiserId} />
    </Layout.Sider>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.globals.isMobile,
  partnerId: state.auth.pid,
  advertiserId: state.auth.aid,
});

const mapDispatchToProps = (dispatch) => ({
  setMobile: (isMobile) => dispatch(setMobilePlatform(isMobile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sider);
