import React, { useCallback, useState } from 'react';
import { Radio } from 'antd';
import { Formatter, hasPermission } from 'utils';
import CustomSelect from './CustomSelect';
import './styles.scoped.less';

const { Group: RadioGroup } = Radio;
const CATEGORIES = Formatter.makeOptions(['Offer', 'User', 'Advertiser', 'Partner', 'Group'], true);

function GlobalSearch() {
  const validCategories = CATEGORIES.filter((c) => hasPermission(`${c.value.toLowerCase()}.read`));
  const [category, setCategory] = useState(validCategories[0]?.value);

  const handleChange = useCallback((e) => setCategory(e.target.value), []);
  const resetCategory = useCallback(() => setCategory(validCategories[0]?.value), [
    validCategories,
  ]);

  if (!validCategories.length) return null;

  return (
    <div className="global-search">
      <CustomSelect
        model={category}
        onClose={resetCategory}
        dropdownRender={(node) => (
          <>
            <div className="ant-select-item ant-select-item-group">I&apos;m looking for...</div>
            <RadioGroup
              options={validCategories}
              onChange={handleChange}
              value={category}
              optionType="button"
              buttonStyle="solid"
            />
            {node}
          </>
        )}
      />
    </div>
  );
}

export default GlobalSearch;
