import React, { Suspense } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { castArray } from 'lodash';
import { Layout, Typography } from 'antd';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { Loading, FaIcon } from 'components';
import { auth, MapRoutes } from 'routes';
import { Analytics } from 'utils';

import './styles.scoped.less';

function getRouteComponent({ route, routeProps }) {
  const { component: Component, name, path } = route;
  Analytics.pageview(castArray(path)[0], null, name);

  return <Component {...routeProps} />;
}

export default function AuthRouter() {
  return (
    <Layout>
      <Layout className="main">
        <Layout.Header className="navbar">
          <Link to="/">
            <Typography.Title level={2}>
              <FaIcon icon={faGem} size="sm" />
              &nbsp; Sapphyre
            </Typography.Title>
          </Link>
        </Layout.Header>

        <Suspense fallback={<Loading size="large" />}>
          <MapRoutes
            routes={auth}
            render={getRouteComponent}
            default={({ location, history }) => {
              let to = '/login';
              // FIXME: Misses some cases, handle by making a logout route
              if (history.length === 1) {
                const next = `${location.pathname}${location.search}`;
                if (next.length > 1) {
                  to = `${to}?next=${encodeURIComponent(next)}`;
                }
              }
              return <Redirect to={to} />;
            }}
          />
        </Suspense>
      </Layout>
    </Layout>
  );
}
